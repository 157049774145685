/* global X2JS */
(function(){

'use strict';

/* Model */

var module = angular.module('kohapac.model', []);

module.factory('kwApi', ["$resource", "$http", "$cacheFactory", "configService", "bvItemSvc", "bvMfhdSvc", function($resource, $http, $cacheFactory, configService, bvItemSvc, bvMfhdSvc ){

    function transformApiQuery (mapping) {
        return function(jsondata) {
            try {
                var raw = JSON.parse(jsondata);
                var items = raw.map(function(item) {
                    if (mapping) item = mapping(item);
                    return item;
                });
                //console.dir(items);
                return items;
            }
            catch (e) {
                console.warn(e);
                return jsondata;
            }
        };
    }

    function transformApiGet (mapping) {
        return function(jsondata) {
            try {
                var item = JSON.parse(jsondata);
                if (mapping) item = mapping(item);
                return item;
            }
            catch (e) {
                return jsondata;
            }
        };
    }

    function expandMarc (srcKey, targetKey) {
        return function(jsonOrObj) {
            var rsp = (angular.isString(jsonOrObj)) ? angular.fromJson(jsonOrObj) : jsonOrObj;
            if(!srcKey) srcKey = 'marc';
            if(rsp[srcKey])
                rsp[targetKey || srcKey] = new MarcRecord(rsp[srcKey]);
            return rsp;
        };
    }

    var addTransform = function(which, transform) {
        var defaults = $http.defaults[which];
        defaults = angular.isArray(defaults) ? defaults : [defaults];
        return (which=='transformRequest') ? [transform].concat(defaults) : defaults.concat(transform);
    };
    var itemfieldDefs = {};
    configService.ItemFields.forEach(function(df){ itemfieldDefs[df.code] = df; });


    var svc = {
        Work : $resource('/api/work/:id',{}, {
            get: {
                method: 'GET',
                transformResponse: addTransform('transformResponse', expandMarc() )
            }
        }),
        Mfhd : $resource('/api/mfhd/:id', { id: "@id" }, {
                workMfhds: {
                    method: 'GET',
                    url: '/api/work/:id/mfhds',
                    isArray: true,
                    // XXX TODO move this
                    transformResponse: function(jsondata){
                        var data = angular.fromJson(jsondata);
                        return data.map(function(mfhdAndUri){
                            mfhdAndUri.mfhd.marc_record = new MarcRecord(mfhdAndUri.mfhd.marc);
                            return bvMfhdSvc.make(mfhdAndUri.mfhd);
                        });
                    },
                }
        }),
        Prefilter: $resource('/api/opac/:query/facets', { query: "*:*" },
                { get : {  method : 'GET',
                    cache : $cacheFactory('mhprefilter', { number : 100 }),
                    isArray : true
                } } ),

        Item : $resource('/api/item/:id', { id: "@itemnumber" } , {
                get : {
                    method: 'GET',
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e); }),
                },
                getCnBrowse : {
                    method: 'GET',
                    url: '/api/item',
                    params: {
                        view: 'cnb',
                        branch: null,
                        ccode: null
                    },
                    isArray: true
                },
                workItems : {
                    method: 'GET',
                    url: '/api/work/:id/items',
                    isArray: true,
                    transformResponse: transformApiQuery(
                            function(e) { return bvItemSvc.make(e.item);}),
                },
                getByBarcode : {
                    method: 'GET',
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e._embed.item);}),
                },
                nextBarcode : {
                    method: 'POST',
                    url: '/api/item',
                    params: {
                        op: 'available_barcode',
                        branch: null
                    }
                },
                relinkMfhd : {  // returns 204
                    method: 'POST',
                    params: {   op: 'relink_mfhd',
                                target_mfhd_id: null
                            }
                },
                create : {
                    method: 'POST',
                    transformRequest: addTransform('transformRequest', bvItemSvc.transformApiSave ),
                    params: {
                        op: 'create'
                    },
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e); })
                },
                put : {
                    method: 'PUT',
                    transformRequest: addTransform('transformRequest', bvItemSvc.transformApiSave ),
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e); })
                },
                delete : {
                    method: 'DELETE'
                },
                cancelTransfer : {
                    method: 'POST',
                    params: {
                        op: 'cancel_transfer'
                    },
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e); })
                },
                checkin : {
                    method: 'POST',
                    params: {
                        op: 'checkin'
                    },
                    transformResponse: addTransform('transformResponse',
                        function( data, h, status ){
                            // FIXME: we get lost item data on conflict rather than item.
                            return (status=='409' || status=='403' || status=='404') ? data : bvItemSvc.make(data);
                        })
                },
                setStatus : {
                    method: 'POST',
                    params: {
                        op: 'set_status'
                    },
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e); })
                },
                clearStatus : {
                    method: 'POST',
                    params: {
                        op: 'clear_status'
                    },
                    transformResponse: transformApiGet(
                            function(e) { return bvItemSvc.make(e); })
                },
                offlineCheckout : {
                    method: 'POST',
                    params: {
                        op: 'offline_checkout'
                    },
                    // transformResponse: function(jsondata){
                    //     try{
                    //         var data = angular.fromJson(jsondata);
                    //         return xformItemLoad(data._embed.item);
                    //     } catch (e) {
                    //         return jsondata;
                    //     }
                    // }
                },
                testRecall: {
                    method: 'POST',
                    params: {
                        op: 'test_recall'
                    }
                },
                recall: {
                    method: 'POST',
                    params: {
                        op: 'recall'
                    }
                }
        }),
        Hold : $resource('/api/hold/:id', { id: '@reservenumber' }, {

            confirm_fill: {
                method: 'POST',
                params: {
                    op: 'confirm_fill'
                }
            },
            print_slip: {
                method: 'GET',
                params: {
                    op: 'print_slip',
                    send_to: null
                }
            },
            getForPatron: {
                method: 'GET',
                isArray: true,
                url: '/api/patron/:id/holds',
                transformResponse: function(jsondata){
                        var data = angular.fromJson(jsondata);
                        return data.map(function(holdAndUri){
                            return holdAndUri.hold;
                        });
                },
            },
            testPlace: {
                method: 'POST',
                url: '/api/work/:bibid/holds',
                params: {
                    op: 'check_place'
                }
            },
            create: {
                method: 'POST',
                url: '/api/work/:bibid/holds',
                params: {
                    op: 'place'
                }
            },
            cancel: {
                method: 'DELETE',
            },
            requeue: {
                method: 'POST',
                params: {
                    op: 'requeue'
                }
            },
            resume: {
                method: 'POST',
                params: {
                    op: 'resume'
                }
            },
            suspend: {
                method: 'POST',
                params: {
                    op: 'suspend'
                }
            }

        }),
        ItemCheckinNote : $resource('/api/item/:item_id/checkin-note', { item_id: '@item_id' }, {
            update: {
                method: 'PUT'
            },
            getForBib: {
                method: 'GET',
                url: '/api/work/:bibid/item-checkin-notes',
                isArray: true
            }
        }),
        ItemStatus : $resource('/api/itemstatus/:id', { }, {
            getAll: {
                method: 'GET',
                isArray: true
            },
            update: {
                method: 'PUT'
            }
        }),
        Issue : $resource('/api/issue/:id', { id: '@id' }, {
            get : {
                method: 'GET',
                transformResponse: transformApiGet(
                        function(e) { e.itemSummary = e._embed.item_summary;  return e; }),
            },
            itemIssues : {
                method: 'GET',
                isArray: true,
                url: '/api/item/:id/issues',
                transformResponse: transformApiQuery( function(issueAndUri){
                    var issue = issueAndUri.issue;
                    issue.itemSummary = issue._embed.item_summary;
                    return issue;
                })
            },
            getForPatron: {
                method: 'GET',
                isArray: true,
                url: '/api/patron/:id/issues',
                transformResponse: transformApiQuery( function(issueAndUri){
                    var issue = issueAndUri.issue;
                    issue.itemSummary = issue._embed.item_summary;
                    return issue;
                })
            },
            testRenew: {
                method: 'POST',
                params: {
                    op: 'test_renew' // non-mutating, so no transformResponse.
                }
            },
            renew: {
                method: 'POST',
                params: {
                    op: 'renew'
                },
                transformResponse: transformApiGet( function(issue){
                    issue.itemSummary = issue._embed.item_summary;

                    // console.log(angular.copy(issue));
                    return issue;
                })
            }
        }),
        LostItem : $resource('/api/lost-item/:id', { id: '@id' }, {
            get: {
                method: 'GET'
            },
            checkin: {
                method: 'POST',
                params: {
                    op: 'checkin',
                    refund: 'LOSTRETURNED',
                    remove: true
                }
            },
            defer: {
                method: 'POST',
                params: {
                    op: 'defer'
                }
            },
            claimReturned: {
                method: 'POST',
                params: {
                    op: 'claimreturned'
                }
            },
            getforPatron: {
                method: 'GET',
                isArray: true,
                url: '/api/patron/:id/lost-items',
                params: {
                    // withfees: true
                },
                transformResponse: function(jsondata){   // FIXME: Add type data so we don't have to do this.
                        var data = angular.fromJson(jsondata);
                        return data.map(function(lostitem){
                            ['biblionumber','id','issue_id','itemnumber'].forEach(function(key){
                                if(lostitem[key]) lostitem[key] = parseInt(lostitem[key]);
                            });
                            return lostitem;
                        });
                },
            }
        }),

        AccountType : $resource('/api/accounttype/:accounttype', { accounttype: '@accounttype'}, {
            getList: {
                method: 'GET',
                isArray: true,
            },
            put: {
                method: 'PUT',
            },
        }),
        PatronAccount : $resource('/api/patron/:id/account-summary', { id: '@id' }, {
            sendAlert: {
                method: 'POST',
                params: {
                    op: 'send_alert',
                }
            },
            emailReceipt: {
                method: 'POST',
                params: {
                    op: 'email_receipt',
                }
            },
            getReceipt: {
                method: 'POST',
                params: {
                    op: 'get_receipt',
                }
            },
            redistributeCredits: {
                method: 'POST',
                params: {
                    op: 'redistribute_credits',
                }
            },
        }),

        WorkAttributes : $resource('/api/work/:id/attributes', {}, {
            get: {
                method: 'GET',
                isArray: true
            }
        }),
        Branch: $resource('/api/branch/:id', {}, {
            getList: {
                method: 'GET',
                url: '/api/branch?view=picker',
                isArray: true,
            },
            getVendorList: {
                method: 'GET',
                url: '/api/branch?view=picker&scope=vendor',
                isArray: true,
            },
            getAcqVendors: {
                method: 'GET',
                url: '/api/branch/:id/acq-vendors',
                isArray: true,
            },
            getAcqVendorAccounts: {
                method: 'GET',
                url: '/api/branch/:id/acq-vendor-accounts',
                isArray: true,
            },
            getAcqVendorCountries: {
                method: 'GET',
                url: '/api/branch/:id/acq-vendor-accounts?view=countries',
                isArray: true,
            },
            deleteAcqVendorAccount: {
                method: 'POST',
                url: '/api/branch/:id/acq-vendor-accounts/?op=delete',
            },
            saveAcqVendorAccount: {
                method: 'POST',
                url: '/api/branch/:id/acq-vendor-accounts',
            },
            getAcqPurchaseOrders: {
                method: 'GET',
                url: '/api/branch/:id/acq-purchase-orders',
                isArray: true,
            },
            getAcqPurchaseOrderLines: {
                method: 'GET',
                url: '/api/branch/:id/acq-purchase-order-lines',
                isArray: true,
            },
            getAcqSubscriptionPOLines: {
                method: 'GET',
                url: '/api/branch/:id/acq-subscription-po-lines',
                isArray: true,
            },
            pairAcqSubscriptionPOLine: {
                method: 'POST',
                url: '/api/branch/:id/acq-subscription-po-lines/?op=pair',
            },
            getSerialInstances: {
                method: 'GET',
                url: '/api/branch/:id/serial-instances',
                isArray: true,
            },
            getSubscriptions: {
                method: 'GET',
                url: '/api/branch/:id/subscriptions',
                isArray: true,
            },
            getNcipAgencies: {
                method: 'GET',
                url: '/api/branch/:id/ncip-agencies',
                isArray: true,
            },
        }),
        Vendor: $resource('/api/vendor/:id', {}, {
            getList: {
                method: 'GET',
                url: '/api/vendor?scope=all',
                isArray: true,
            },
            getLedgers: {
                method: 'GET',
                url: '/api/vendor/:id/ledgers',
                isArray: true,
            },
            getLedger: {
                method: 'GET',
                url: '/api/vendor/:id/ledger/:ledger_id',
            },
            deprecateAll: {
                method: 'POST',
                url: '/api/vendor/:id?op=deprecate_all'
            },
            purgeAll: {
                method: 'POST',
                url: '/api/vendor/:id?op=purge_all'
            },
            put: {
                method: 'PUT',
            },
            getClassificationTable: {
                method: 'GET',
                url: '/api/vendor/:id/classifications',
                isArray: true,
            },
            setClassificationTable: {
                method: 'POST',
                url: '/api/vendor/:id/classifications',
                isArray: true,
            }
        }),

        Ledger: $resource('/api/ledger/:id', {}, {
            getPriceModelConfig: {
                method: 'GET',
                url: '/api/ledger/?view=price-model-config',
                isArray: true,
            },
            deprecateAll: {
                method: 'POST',
                url: '/api/ledger/:id?op=deprecate_all'
            },
            purgeAll: {
                method: 'POST',
                url: '/api/ledger/:id?op=purge_all'
            },
            put: {
                method: 'PUT',
            },
        }),

      PatronGroup: $resource('/api/patron/:id/group/:mid', { id: '@id', mid: '@mid' }, {
            getGroup: {
                method: 'GET',
                url: '/api/patron/:id/group',
                isArray: true,
            },
            rmGroupMember: {
              method: 'DELETE',
              url: '/api/patron/:id/group/:mid',
            },
            addGroupMember: {
              method: 'POST',
              url: '/api/patron/:id/group/:mid',
              params: {rel: '@rel', op: 'add-group-member', force: '@force'},
            },
            updateRelationship: {
              method: 'POST',
              url: '/api/patron/:id/group/:mid',
              params: {rel: '@rel', op: 'update-group-member'},
            },
        }),
        PatronRegistration: $resource('/api/patron-registration/:id', { id: '@id' }, {
            getApps: {
                method: 'GET',
                url: '/api/patron-registration/',
                isArray: true,
            },
            rejectApp: {
                method: 'POST',
                url: '/api/patron-registration/:id',
                params: { op: 'update-app' }
            },
            approveApp: {
                method: 'POST',
                url: '/api/patron-registration/:id',
                params: { op: 'update-app' }
            },
            submitApp: {
                method: 'POST',
                url: '/api/patron-registration/',
                params: { op: 'register-patron' }
            },
        }),
        Patron: $resource('/api/patron/:id', { id: '@id' }, {
            getBranchList: {
                method: 'GET',
                url: '/api/patron/?view=picker&scope=branch&permit=:permit',
                isArray: true,
            },
            getSuEligibleList: {
                method: 'GET',
                url: '/api/patron/?view=picker-extended&category_code=nontemp&subpermissions=1',
                isArray: true,
            },
            getRoleList: {
                method: 'GET',
                url: '/api/patron/?view=picker-extended&category_code=ROLE',
                isArray: true,
            },
            generateSuTicket: {
                method: 'POST',
                url: '/api/patron/:id?op=generate-su-ticket',
            },
            makeTemplatePatron: {
                method: 'POST',
                url: '/api/patron/?op=make-template-patron',
            },
            getSocialMediaAccounts: {
                method: 'GET',
                url: '/api/patron/:id/social-media-accounts',
                isArray: true,
            },
            mergePatron: {
                method: 'POST',
                url: '/api/patron/:id',
                params: { op: 'merge', source_patron_id: '@source_patron_id', target_patron_id: '@target_patron_id', bfields: '@bfields' }
            },
            deletePatron: {
                method: 'DELETE',
                url: '/api/patron/:id',
                params: { test: '@test' }
            },
        }),
        Catalog: $resource('/api/opac/:query', {}, {
            query: {
                method: 'GET',
                url: '/api/opac/:query',
                isArray: false,
            },
            exportSearch: {
                method: 'POST',
                url: '/api/opac/?op=export-search'
            },
        }),
        ImportScript: $resource('/api/import-script/:id', {}, {
            getList: {
                method: 'GET',
                url: '/api/import-script',
                isArray: true,
            },
            execute: {
                method: 'POST',
                url: '/api/import-script/:id?op=execute'
            },
            put: {
                method: 'PUT',
            },
            deleteAll: {
                method: 'POST',
                url: '/api/import-script?op=delete-all'
            },
            deepCopy: {
                method: 'POST',
                url: '/api/import-script/:id?op=deep-copy'
            },
            getParameters: {
                method: 'GET',
                url: '/api/import-script/:id/parameter-sets',
                isArray: true,
            },
            getRuns: {
                method: 'GET',
                url: '/api/import-script/:id/runs',
                isArray: true,
            },
        }),
        ImportScriptParameterSet: $resource('/api/import-script-parameter-set/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),
        ImportScriptlet: $resource('/api/import-scriptlet/:id', {}, {
            getList: {
                method: 'GET',
                url: '/api/import-scriptlet',
                isArray: true,
            },
            put: {
                method: 'PUT',
            },
            deleteAll: {
                method: 'POST',
                url: '/api/import-scriptlet?op=delete-all'
            },
            approveAll: {
                method: 'POST',
                url: '/api/import-scriptlet?op=approve-all'
            },
        }),
        ImportScriptRun: $resource('/api/import-script-run/:id', {}, {
            forAll: {
                method: 'POST',
                url: '/api/import-script-run?op=forall',
            },
        }),
        ActionLog: $resource('/api/action-log/:id', {}, {
            getFieldMap: {
                method: 'GET',
                url: '/api/action-log?view=field_map',
                isArray: true
            },
            getList: {
                method: 'GET',
                url: '/api/action-log',
                isArray: true
            },
            getStats: {
                method: 'GET',
                url: '/api/action-log?view=stats'
            },
            purge: {
                method: 'POST',
                url: '/api/action-log?op=purge'
            },
        }),
        Login: $resource('/api/login', {}, {
            linkSocialInit: {
                method: 'POST',
                url: '/api/login?op=social-bind',
            },
            linkSocialFinalize: {
                method: 'POST',
                url: '/api/login?op=social-bind-finalize',
            },
            loginSocialInit: {
                method: 'POST',
                url: '/api/login?op=social-login',
            },
            loginSocialFinalize: {
                method: 'POST',
                url: '/api/login?op=social-login-finalize',
            },
            lostPass: {
                method: 'POST',
                url: '/api/lostpass',
            },

        }),
        // Serials
        Periodical: $resource('/api/periodical/:id', {}, {
            search: {
                method: 'GET',
                url: '/api/periodical/?view=search',
                isArray: true,
            },
            getForBib: {
                method: 'GET',
                url: '/api/work/:bibid/periodicals',
                isArray: true,
            },
            getSubscriptions: {
                method: 'GET',
                url: '/api/periodical/:id/subscriptions',
                isArray: true,
            },
            getSerialEditions: {
                method: 'GET',
                url: '/api/periodical/:id/serial-editions',
                isArray: true,
            },
            put: {
                method: 'PUT',
            },
            // Non-mutating, but potentially large args
            predict: {
                method: 'POST',
                url: '/api/periodical?op=predict',
                isArray: true,
            },
            generateEdition: {
                method: 'POST',
                url: '/api/periodical/:id?op=generate',
            },
            deleteWithItem: {
                method: 'POST',
                url: '/api/periodical/:id?op=delete-with-item',
            },
        }),
        Subscription: $resource('/api/subscription/:id', {}, {
            getSerialInstances: {
                method: 'GET',
                url: '/api/subscription/:id/serial-instances',
                isArray: true,
            },
            get: {
                method: 'GET',
                transformResponse: addTransform('transformResponse', expandMarc('holdings') )
            },
            put: {
                method: 'PUT',
            },
            deleteWithItem: {
                method: 'POST',
                url: '/api/subscription/:id?op=delete-with-item',
            },
        }),
        SerialEdition: $resource('/api/serial-edition/:id', {}, {
            getSerialInstances: {
                method: 'GET',
                url: '/api/serial-edition/:id/serial-instances',
                isArray: true,
            },
            put: {
                method: 'PUT',
            },
            deleteWithItem: {
                method: 'POST',
                url: '/api/serial-edition/:id?op=delete-with-item',
            },
        }),
        SerialInstance: $resource('/api/serial-instance/:id', {}, {
            saveAll: {
                method: 'POST',
                url: '/api/serial-instance/?op=save-all',
                isArray: true,
            },
            claim: {
                method: 'POST',
                url: '/api/serial-instance/?op=claim',
            },
            deleteWithItem: {
                method: 'POST',
                url: '/api/serial-instance/:id?op=delete-with-item',
            },
        }),
        SerialPatternTemplate: $resource('/api/serial-pattern-template/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),
        SerialChronologyTemplate: $resource('/api/serial-chronology-template/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),
        SerialSequenceTemplate: $resource('/api/serial-sequence-template/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),
        ItemTemplate: $resource('/api/item-defaults/:id', { id: "@id" }, {
            getList: {
                method: 'GET',
                isArray: true
            },
            create: {
                method: 'POST',
                params: { op: 'create' }
            }
        }),
        WorksList: $resource('/api/works-list/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),

        ItemList: $resource('/api/item-list/:id', {}, {
            put: {
                method: 'PUT',
            },
            getEntries: {
                method: 'GET',
                url: '/api/item-list/:id/entries',
                isArray: true,
            },
            saveWithEntries: {
                method: 'POST',
                url: '/api/item-list/:id?op=save-with-entries',
            },
            saveFromSearch: {
                method: 'POST',
                url: '/api/item-list/:id?op=save-from-search',
            },
            saveFromUpload: {
                method: 'POST',
                url: '/api/item-list/:id?op=save-from-upload',
            },
            validateEntries: {
                method: 'POST',
                url: '/api/item-list/:id?op=validate-entries',
                isArray: true,
            },
            saveMetadata: {
                method: 'POST',
                url: '/api/item-list/:id?op=save-metadata',
            },
            setName: {
                method: 'POST',
                url: '/api/item-list/:id?op=set-name',
            },
            modifyItems: {
                method: 'POST',
                url: '/api/item-list/:id?op=modify-items',
            },
            deleteItems: {
                method: 'POST',
                url: '/api/item-list/:id?op=delete-items',
            },

        }),
        ItemListEntry: $resource('/api/item-list-entry/:id', {}, {
            forAll: {
                method: 'POST',
                url: '/api/item-list-entry?op=forall',
            },
        }),
        Message: $resource('/api/message/:id', {}, {
            put: {
                method: 'PUT',
            },
            send: {
                method: 'POST',
                url: '/api/message?op=send',
            },
        }),
        MessageTemplate: $resource('/api/message-template/:id', {}, {
            tree: {
                method: 'GET',
                url: '/api/message-template?view=tree',
                isArray: true,
            },
            getTemplateCodes: {
                method: 'GET',
                url: '/api/message-template?view=template-codes',
                isArray: true,
            },
            put: {
                method: 'PUT',
            },
            forAll: {
                method: 'POST',
                url: '/api/message-template?op=forall',
            },
            applyTo: {
                method: 'POST',
                url: '/api/message-template?op=apply-to',
            },
        }),
        MessageType: $resource('/api/message-type/:id', {}, {
            getWithParameters: {
                method: 'GET',
                url: '/api/message-type/:id?view=with-parameters',
            },
            setBranchTemplate: {
                method: 'POST',
                url: '/api/message-type/:id?op=set-branch-template',
            },
            replaceTemplateSet: {
                method: 'POST',
                url: '/api/message-type/:id?op=replace-template-set',
            },
        }),
        Fee: $resource('/api/fee/:id', { id: '@id'}, {
            get: {
                method: 'GET'
            },
            getFromLostItem: {
                method: 'GET',
                url: '/api/lost-item/:lostid/fees',
                isArray: true
            },
            waive: {
                method: 'POST',
                hasBody: false,
                params: {
                    op: 'waive'
                }
            },
            getForPatron: {
                method: 'GET',
                isArray: true,
                url: '/api/patron/:patron_id/fees/:type',
                    // FIXME: fees/unallocated returns a non-fee .
            },
            create: {
                method: 'POST',
                params: { op: 'create' },
            }
        }),
        Payment: $resource('/api/payment/:id', { id: '@id' }, {
            get: {
                method: 'GET',
            },
            getForPatron: {
                method: 'GET',
                isArray: true,
                url: '/api/patron/:patron_id/payments',
            },
            create: {
                method: 'POST',
                params: { op: 'create' },
            },
            setReallocate: {
                method: 'POST',
                params: { op: 'set_reallocate' },
            },
            reverse: {
                method: 'POST',
                params: { op: 'reverse' },
            },
            webPay: {
                method: 'POST',  // creates payment from paypal order. // capture if needed.
                params: {
                    op: 'webpay',
                    gateway: 'paypal',
                    // required: order_id
                }
            },
            paypalCustToken: {
                method: 'GET',
                url: '/api/payment-gateway/paypal/client_token'
            },
            createWebpayOrder: {
                method: 'POST',
                url: '/api/payment-gateway/paypal/create_order'
            }
        }),
        Report: $resource('/api/report/:id', {}, {
            getRuns: {
                method: 'GET',
                url: '/api/report/:id/runs',
                isArray: true,
            },
            getParameters: {
                method: 'GET',
                url: '/api/report/:id/parameters',
                isArray: true,
            },
            execute: {
                method: 'POST',
                url: '/api/report/:id?op=execute',
            },
            schedule: {
                method: 'POST',
                url: '/api/report/:id?op=schedule',
            },
            forAll: {
                method: 'POST',
                url: '/api/report?op=forall',
            },
            put: {
                method: 'PUT',
            },
            getRun: {
                method: 'GET',
                url: '/api/report/:id/run/:rid',
            },
            deleteRun: {
                method: 'DELETE',
                url: '/api/report/:id/run/:rid',
            },
            exportRunAsBatch: {
                method: 'POST',
                url: '/api/report/:id/run/:rid?op=export-batch',
            },
            exportRunAsItemList: {
                method: 'POST',
                url: '/api/report/:id/run/:rid?op=export-itemlist',
            },
        }),
        ReportTag: $resource('/api/report-tag', {}, {}),
        CronJob: $resource('/api/cron-job/:id', {}, {
            getRuns: {
                method: 'GET',
                url: '/api/cron-job/:id/runs',
                isArray: true,
            },
            getRun: {
                method: 'GET',
                url: '/api/cron-job/:id/run/:rid',
            },
            deleteRun: {
                method: 'DELETE',
                url: '/api/cron-job/:id/run/:rid',
            },
            put: {
                method: 'PUT',
            },
            execute: {
                method: 'POST',
                url: '/api/cron-job/:id?op=execute',
            },
            schedule: {
                method: 'POST',
                url: '/api/cron-job/:id?op=schedule',
            },
        }),
        CronScript: $resource('/api/cron-script/:id', {}, {}),
        CircControl: $resource('/api/circ-control/:id', {}, {
            put: {
                method: 'PUT',
            },
            getScopes: {
                method: 'GET',
                url: '/api/circ-control/?view=scopes',
                isArray: true,
            },
        }),
        CircPolicy: $resource('/api/circ-policy/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),
        CircRule: $resource('/api/circ-rule/:id', {}, {
            put: {
                method: 'PUT',
            },
            testTuple: {
                method: 'POST',
                url: '/api/circ-rule/?op=test-tuple',
                isArray: true,
            }
        }),
        CircTermset: $resource('/api/circ-termset/:id', {}, {
            put: {
                method: 'PUT',
            },
            getDates: {
                method: 'GET',
                url: '/api/circ-termset/:id/dates',
                isArray: true,
            },
        }),
        CircTermDate: $resource('/api/circ-term-date/:id', {}, {
            put: {
                method: 'PUT',
            },
        }),
        RecallRule: $resource('/api/recall-rule/:id', {}, {
            put: {
                method: 'PUT',
            },
            testTuple: {
                method: 'POST',
                url: '/api/recall-rule/?op=test-tuple',
                isArray: true,
            }
        }),

        MarcValidationRuleset: $resource('/api/marc-validation-ruleset/:id', {}, {
            checkSyntax: {
                method: 'POST',
                url: '/api/marc-validation-ruleset/?op=check-syntax',
            },
            checkAllSyntax: {
                method: 'POST',
                url: '/api/marc-validation-ruleset/:id?op=check-syntax',
                isArray: true,
            },
            put: {
                method: 'PUT',
                url: '/api/marc-validation-ruleset/:id',
            },
        }),
        NcipAgency: $resource('/api/ncip-agency/:id', {}, {
            put: {
                method: 'PUT',
                url: '/api/ncip-agency/:id',
            },
            forAll: {
                method: 'POST',
                url: '/api/report?op=forall',
            },
        }),
        Worldcat: $resource('/api/worldcat', {}, {
            getOpenUrlResolve: {
                method: 'GET',
                url: '/api/worldcat/?op=openurl',
                isArray: true,
            },
            getEntriesSearch: {
                method: 'GET',
                url: '/api/worldcat/?op=entries',
                isArray: false,
            },
            getCollectionsSearch: {
                method: 'GET',
                url: '/api/worldcat/?op=collections',
                isArray: false,
            },
        }),
        SessionApiMetadata: $resource('/api/session-api-metadata/:id', {}, {
            generateError: {
                method: 'POST',
                url: '/api/session-api-metadata?op=throw',
            },
            traceUser: {
                method: 'POST',
                url: '/api/session-api-metadata?op=trace-user',
            },
            options: {
                method: 'GET',
                url: '/api/session-api-metadata?view=options',
                isArray: true,
            },
        }),
        MarcDisplayTmpl: $resource('/api/marcdisplaytmpl/:id', { id : '@id'}, {
            getAll: {
                method: 'GET',
                isArray: true
            },
            getDefaults: {
                method: 'GET',
                isArray: true,
                params: {
                    op: 'get_defaults'
                }
            }
        })
    };

    bvItemSvc.extendModel(svc.Item);
    bvMfhdSvc.extendModel(svc.Mfhd);

    svc.Report.$validateSqlPattern = function(value) {
        var result = (value.length > 0) ? value.replace(/(WHERE|HAVING).*?(;|'$)/ig, "").match(/^(?![\s\S]*\b(UPDATE|DELETE|DROP|INSERT|SHOW|CREATE|ALTER|REPLACE)\b)/i) : true;
        if (result) {
            return true;
        }
        return false;
    };     
    svc.Report.$exportDownloadLink = function(reportId, runId, params) {
        var s = '/api/report/' + reportId + '/run/' + runId+ '?view=export&format=' + encodeURIComponent(params.format);
        if (params.delimiter)
            s = s + '&delimiter=' + encodeURIComponent(params.delimiter);
        return s;
    };

    svc.Issue.prototype.isOverdue = function(){
        var duedate = dayjs(this.duedate);
        var now = (this.loan_type === 'daily' ? dayjs().startOf('day') : dayjs());
        return duedate.isBefore(now);
    };
    svc.Issue.prototype.renewedToday = function(){
        if(! this.lastreneweddate) return false;
        var lrd = new Date(this.lastreneweddate);
        var d = new Date();
        return lrd.toDateString() == d.toDateString();
    };

    svc.Hold.prototype.statusDateSort = function(){
        return ({W:'0',T:'1',S:'3'}[this.found]||'2')+this.reservedate;
    };

    return svc;

}])

.factory('kwOverdriveTitleModel', ["$http", "$q", "userService", "alertService", function( $http, $q, userService, alertService ){

    var odFormats = {
        // these are likely no longer needed, as user now only selects format
        // within OD's interface.  remove when this is certain.
                "ebook-kindle": {
                    label: "Kindle Book",
                    type: "ebook" },
                "ebook-overdrive": {
                    label: "OverDrive Read eBook (browser-based)",
                    type: "ebook" },
                "ebook-epub-adobe": {
                    label: "DRM protected Adobe EPUB eBook",
                    type: "ebook" },
                "ebook-epub-open": {
                    label: "DRM free Open EPUB eBook",
                    type: "ebook" },
                "ebook-pdf-adobe": {
                    label: "DRM protected Adobe PDF eBook",
                    type: "ebook" },
                "ebook-pdf-open": {
                    label: "DRM free Open PDF eBook",
                    type: "ebook" },
                "ebook-mediado": {
                    label: "MediaDo Reader eBook (browser-based)",
                    type: "ebook" },
                "periodicals-nook": {
                    label: "NOOK periodicals",
                    type: "ebook" },
                "audiobook-overdrive": {
                    label: "OverDrive Listen audiobook (browser-based)",
                    type: "audio" },
                "audiobook-mp3": {
                    label: "DRM free MP3 audiobook",
                    type: "audio" },
                "video-streaming": {
                    label: "DRM protected streaming video file",
                    type: "video" },
            };
        var describe_format = function(code){
            return {
                code: code,
                label: odFormats[code].label,
                type: odFormats[code].type
            };
        };

        return function(uuid, data){
            this.id = uuid;
            this.available = false;
            this.checkedOutToMe = null;
            this.downloadRedirect = null;
            this.reservedByMe = false;
            this.fetching = true;
            this.error = null;
            this.authRequired = false;
            this.initialized = false;
            this.sampleUri = (data||{}).sampleUri;
            this.toomany = {};  // patron over circ limits.

            var self = this;

            this.canReserve = function(){
                return !this.available && !this.reservedByMe && !this.toomany.holds;
            };

            this.handleError = function(rsp){
                console.warn(rsp);
                var e = rsp.data;
                if(rsp.status==403 || rsp.status==404){
                    self.authRequired = true;
                    if(rsp.statusText == 'Invalid credentials')
                            alertService.add({msg: "Incorrect password.  Please try again.", type: "failure"});

                } else {
                    if(e.errorCode=="PatronHasExceededCheckoutLimit"){
                        self.updateStatus();
                    } else {
                        self.error = (e) ? e.message : rsp.statusText;
                    }
                }
                // FIXME: We should count attempts, and display error
                self.fetching = false;
                return $q.reject( e );

            };
            this.removeHold = function(){
                this.fetching = true;
                return $http.delete('/api/patron/'+ userService.id +'/overdrive/holds/'+ self.id).then(function (response) {
                    userService.overdrive.updatePatron().then( function(){
                        self.reservedByMe = false;
                        self.fetching = false;
                    });
                }, self.handleError);
            };

            this.returnTitle = function(){
                this.fetching = true;
                return $http.delete('/api/patron/'+ userService.id +'/overdrive/issues/'+ self.id).then(function (response) {
                    userService.overdrive.updatePatron().then( function(){
                        self.reload();
                    });
                }, self.handleError);
            };

            // var extractFormats = function(checkout){

            //     var formats = [];
            //     if(checkout.formats){
            //         checkout.formats.forEach(function(fmt){
            //             // these do not require 'lockin'.
            //             formats.push( describe_format(fmt.formatType) );
            //         });
            //     }
            //     if(checkout.actions && checkout.actions.format){
            //         var fmt_types = checkout.actions.format.fields.filter( function(field){ return field.name=="formatType";});
            //         if(fmt_types.length){
            //             fmt_types[0].options.forEach(function(fmt_code){
            //                 var fmt = describe_format(fmt_code);
            //                 fmt.lockinRequired = true;
            //                 formats.push(fmt);
            //             });
            //         }
            //     }
            //     self.formatsAvailable = (formats.length) ? formats : null;
            // };

            this.checkout = function(format){
                this.fetching = true;
                return userService.whenAuthenticatedUser().then(function(){
                    var uri = '/api/patron/'+ userService.id +'/overdrive/issues/'+ self.id ;
                    var params = { op: 'checkout' };
                    if(format) params.format = format;
                    return $http.post(uri, $.param(params),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                            .then(function (response) {
                                if((response.data||{}).errorCode)
                                    return self.handleError(response);
                                // extractFormats(response.data);
                                return userService.overdrive.updatePatron().then( function(){
                                    return self.updateStatus();
                                });
                            }, self.handleError);
                });
            };

            // this.getContentLink = function(fmtcode){

            //     var fmt = this.formatsAvailable.filter(function(f){ return f.code==fmtcode; })[0];

            //     var lockin = (!fmt.lockinRequired || this.checkedOutToMe.isFormatLockedIn) ?
            //                 $q.when(fmtcode) : this.checkout(fmtcode);
            //     return lockin.then(function(){
            //         self.fetching = true;
            //         var apiurl = '/api/patron/'+ userService.id +'/overdrive/issues/'+
            //                     self.id + '/formats/'+ fmtcode + '/downloadlink';
            //         var errorUrl = window.location.origin + '/app/error?code=od-dl-error';
            //         apiurl +=  '?errorurl=' + encodeURIComponent(errorUrl);
            //         return $http.get( apiurl ).then(function(rsp){
            //             if(rsp.errorCode){
            //                 self.handleError(rsp);
            //                 return;
            //             }

            //             self.contentLink = angular.copy(rsp.data.links.contentlink);
            //             self.fetching = false;
            //             return(self.contentLink);
            //         }, self.handleError);
            //     });
            // };
            this.placeHold = function(){

                this.fetching = true;
                return userService.whenAuthenticatedUser().then(function(){
// TODO: OFFER  autoCheckout .

                    return $http.post('/api/patron/'+ userService.id +'/overdrive/holds/'+ self.id).then(function (response) {
                        if(response.errorCode){
                            return self.handleError(response);
                        }
                        self.numberOfHolds++;
                        return userService.overdrive.updatePatron().then( function(){
                            return self.updateStatus();
                        });
                    }, self.handleError);
                });
            };

            this.updateStatus = function(){
                userService.overdrive.patron().then(function(patronData){
                    if(patronData){
                        self.toomany.holds = patronData.holds.totalItems >= patronData.auth.holdLimit;
                        self.toomany.checkouts = patronData.checkouts.totalCheckouts >=
                                                patronData.auth.checkoutLimit;

                        if(patronData.holds.holds){
                            for(var i = 0; i < patronData.holds.holds.length; i++){
                                var hold = patronData.holds.holds[i];
                                if(hold.reserveId == self.id){
                                    self.reservedByMe = true;
                                    self.holdListPosition = hold.holdListPosition;
                                }
                            }
                        }
                        if(patronData.checkouts.checkouts){
                            for(i = 0; i < patronData.checkouts.checkouts.length; i++){
                                var checkout = patronData.checkouts.checkouts[i];
                                if(checkout.reserveId == self.id){
                                    self.checkedOutToMe = true;
                                    self.downloadRedirect = angular.copy(checkout.links.downloadRedirect);
                                    // extractFormats(checkout);
                                }
                            }
                        }
                    }
                    self.fetching = false;
                }, function(f){
                    console.warn(f);
                });
            };

            var init = function( options ){
                var opt = angular.extend( {
                                doAuth : false
                            }, options );

                self.fetching = true;

                userService.whenAnyUserDetails().then(function(u){
                    userService.overdrive.session( { prompt : opt.doAuth }).then(function(valid_session){
                        userService.overdrive.patron().finally(function(odpatron){
                            var params = { collection: userService.overdrive.collectionToken() };
                                $http.get('/api/overdrive/holdings/'+ self.id, {params: params}).then(
                                    function (response) {
                                        var stat = response.data;
                                        if(!stat.errorCode){
                                            self.copiesAvailable = stat.copiesAvailable;
                                            self.copiesOwned = stat.copiesOwned;
                                            self.numberOfHolds = stat.numberOfHolds;
                                            self.available = stat.available;
                                            self.error = null;
                                        }else{
                                            self.available = false;
                                            self.error = "Overdrive Resource Missing " + stat.errorCode;
                                        }
                                        self.authRequired = false;
                                        self.updateStatus();
                                    }, function(rsp){
                                        console.log(rsp);
                                        return self.handleError(rsp);
                                });

                        });

                    }).catch( function(failedsession){
                        if(self.initialized) // don't error out on first load. allow user to try auth.
                            self.error = 'Invalid session';
                        self.authRequired = true;
                    }).finally(function(){
                        self.fetching = false;
                        self.initialized = true;
                    });
                });
            };
            init();
            this.reload = init;
            this.tryAuth = function(){
                if(userService.loggedin){
                    return init({doAuth: true});
                } else {
                    userService.whenAuthenticatedUser().then(function(){
                        return init();
                    });
                }
            };
        };
    }])

    .factory('kwCloudLibraryTitleModel', ["$http", "$q", "$rootScope", "userService", function ($http, $q, $rootScope, userService) {
        return function (uuid) {
            this.id          = uuid;
            this.available   = false;
            this.checkedOut  = null;
            this.contentLink = null;
            this.reserved    = false;
            this.fetching    = true;

            this.handleError = function(e){
                console.warn(e);
                self.error = (e.message)? e.message : e;
                self.fetching = false;
            };

            var self = this;

            this.checkout = function () {
                this.fetching = true;

                return userService.whenAuthenticatedUser().then( function () {
                    var uri = '/api/patron/'+ userService.id +'/cloudlibrary/issues/'+ self.id ;

                    return $http.put(uri, {}).then( function (response) {
                            if (response.errorCode) {
                                self.handleError(response);
                                return;
                            }

                            return userService.cloudlibrary.update().then( function() {
                                return self.updateStatus();
                            });
                        }, self.handleError);
                });
            };

            this.returnTitle = function () {
                this.fetching = true;
                return $http.post('/api/patron/' + userService.id + '/cloudlibrary/issues/' + self.id, {}).then( function (response) {
                    userService.cloudlibrary.update().then( function () {
                        self.checkedOut = null;
                        self.fetching = false;
                        self.updateStatus();
                    });
                }, self.handleError);
            };

            this.placeHold = function () {
                this.fetching = true;
                userService.whenAuthenticatedUser().then( function () {
                    return $http.put('/api/patron/'+ userService.id +'/cloudlibrary/holds/'+ self.id, '{}').then( function (response) {
                        if(response.errorCode){
                            self.handleError(response);
                            return;
                        }

                        return userService.cloudlibrary.update().then( function() {
                            self.updateStatus();
                        });
                    }, self.handleError);
                });
            };

            this.removeHold = function () {
                this.fetching = true;

                return $http.post('/api/patron/' + userService.id + '/cloudlibrary/holds/' + self.id, '{}').then( function (response) {
                    userService.cloudlibrary.update().then( function () {
                        self.reserved = false;
                        self.fetching = false;
                        self.updateStatus();
                    });
                }, self.handleError);
            };

            this.updateStatus = function () {
                return userService.cloudlibrary.promise().then( function (patronData) {
                    if (patronData) {
                        if (patronData.PatronCirculation.Holds) {
                            var patronHolds = patronData.PatronCirculation.Holds;

                            if (angular.isArray(patronHolds.Item)) {
                                patronHolds.Item.forEach( function (hold) {
                                    if (hold.ItemId == self.id) {
                                        self.reserved = true;
                                        self.holdListPosition = hold.Position;
                                    }
                                });
                            }
                            else {
                                if (patronHolds.Item.ItemId == self.id) {
                                    self.reserved = true;
                                    self.holdListPosition = patronHolds.Item.Position;
                                }
                            }
                        }

                        if (patronData.PatronCirculation.Checkouts) {
                            var patronCheckouts = patronData.PatronCirculation.Checkouts;

                            if (angular.isArray(patronCheckouts.Item)) {
                                patronCheckouts.Item.forEach( function (checkout) {
                                    if (checkout.ItemId == self.id) {
                                        self.checkedOut = true;
                                    }
                                });
                            }
                            else {
                                if (patronCheckouts.Item.ItemId == self.id) {
                                    self.checkedOut = true;
                                }
                            }
                        }
                    }

                    self.fetching = false;
                    return $q.when(patronData);
                });
            };

            var _login_listener_dereg;

            var init = function () {
                if (_login_listener_dereg) {
                    _login_listener_dereg();
                }

                if ( userService.loggedin || userService.cloudlibrary.noauth_ok() ) {
                    $http.get('/api/cloudlibrary/holdings/'+ self.id).then( function (response) {
                        var x2js = new X2JS();
                        var json_data = x2js.xml_str2json(response.data);
                        var stat = json_data.ArrayOfLibraryDocumentSummary.LibraryDocumentSummary.ArrayLibraryDocumentDetail.LibraryDocumentDetail;

                        if ( response.status == '200' && stat.ownedCopies ) {
                            self.copiesAvailable = stat.ownedCopies - stat.loanCopies;
                            self.copiesOwned     = stat.ownedCopies;
                            self.numberOfHolds   = stat.holdCopies;
                            self.available       = stat.ownedCopies - stat.loanCopies;
                            self.contentLink = 'https://ebook.yourcloudlibrary.com/library/testlibrary-document_id-' + self.id; // TODO: Get link from vendor.
                        }
                        else {
                            self.available = false;
                            self.error = "cloudLibrary Resource Missing";
                        }

                        self.loginRequired = false;
                        self.updateStatus();
                    }, function (e) {
                        console.log(e);

                        if ( e.Message == 'Authorization has been denied for this request.' ) {
                            self.loginRequired = true;
                            _login_listener_dereg = $rootScope.$on('loggedin', function () {
                                init();
                            });
                        }
                        else {
                            self.error = e;
                        }

                        self.fetching = false;
                    });
                }
                else {
                    self.loginRequired = true;
                    _login_listener_dereg = $rootScope.$on('loggedin', function () {
                        init();
                    });
                }
            };

            init();
        };
    }])


// New item / mfhd / holdings factories
.factory('bvItemSvc', ["configService", "$filter", function(configService, $filter) {
    var svc = {
        fieldDefs: [],
        fieldDef: {},
        customFields: [],
    };

    var customFields = [];
    configService.ItemFields.forEach(function(rec) {
        var fd = angular.extend({
            _interfaceVisibility: {'results': false, 'public': false, 'staff': false, 'edit': false},
        }, rec);
        if (fd.visibility == "results") {
            fd._interfaceVisibility.results = true;
            fd._interfaceVisibility['public'] = true;
            fd._interfaceVisibility.staff = true;
            fd._interfaceVisibility.edit = true;
        }
        else if (fd.visibility == "public") {
            fd._interfaceVisibility['public'] = true;
            fd._interfaceVisibility.staff = true;
            fd._interfaceVisibility.edit = true;
        }
        else if (fd.visibility == "staff") {
            fd._interfaceVisibility.staff = true;
            fd._interfaceVisibility.edit = true;
        }
        else if (fd.visibility == "edit") {
            fd._interfaceVisibility.edit = true;
        }

        svc.fieldDefs.push(fd);
        svc.fieldDef[fd.code] = fd;
    });


    // Move these into the item class itself??
    
    svc.updateDisplay = function(item) {
        if (!item._display)
            item._display = {};

        // Conversion to display values
        svc.fieldDefs.forEach(function(fd) {
            var field = fd.code;
            var val = item[field];

            if (fd.authval) {
                if (val !== undefined && val !== null) {
                    val = configService.display(val, fd.type);
                }
            }
            else if (field == 'homebranch' || field == 'holdingbranch')
                val = configService.display(val, 'branch');
            else if (field == 'itemtype')
                val = configService.display(val, 'itemtype');
            else if (fd.type == 'date')
                val = $filter('kohaDate')(val);
            else if (fd.type == 'percent')
                val = $filter('percent')(val, fd.filteropts);
            else if (fd.type == 'money')
                val = $filter('kohaMoney')(val, fd.filteropts);
            else if (fd.type == 'moneystr') 
                val = $filter('kohaMoneyOrStr')(val, fd.filteropts);

            item._display[field] = val;
        });


        if (configService.search.sortOwnBranchFirst) {
            if (configService.search_group.branch_map[item.homebranch])
                item._sorthomebranch = '0 ' + item._display.homebranch;
            else 
                item._sorthomebranch = '1 ' + item._display.homebranch;

            if (configService.search_group.branch_map[item.holdingbranch])
                item._sortholdingbranch = '0 ' + item._display.holdingbranch;
            else 
                item._sortholdingbranch = '1 ' + item._display.holdingbranch;
        }
        else {
            item._sorthomebranch = item._display.homebranch;
            item._sortholdingbranch = item._display.holdingbranch;
        }
    };

    svc.itemIsAvailable = function(item) {
        return !item.onloan &&
            !parseInt(item.notforloan,10) &&
            !parseInt(item.damaged,10) &&
            !parseInt(item.wthdrawn,10) &&
            !item.on_hold &&
            !item.itemlost &&
            !item.in_transit &&
            !item.shelving &&
            !item.circblocked_status &&
            !item.recalled;
    };


    svc.make = function(rawItem) {
        var item = angular.copy(rawItem);
        item.id = item.itemnumber;
        item.$hasSecondary = {staff: false, 'public': false, edit: false, results: false};
        svc.fieldDefs.forEach(function(fd) {
            // Flatten custom item fields
            if (fd.custom) {
                if (!(fd.code in item) && (fd.code in item.fields)) {
                    item[fd.code] = item.fields[fd.code];
                }
            }
            // Convert dates
            if (fd.type == 'date' && item[fd.code])
                item[fd.code] = dayjs(item[fd.code]).toDate();

            if ((fd.code == '_availability') || item[fd.code] || (item[fd.code] === '0')) {
                if (fd.secondary == 'staff') {
                    item.$hasSecondary.staff = true;
                    item.$hasSecondary['public'] = true;
                }
                else if (fd.secondary == 'public') {
                    item.$hasSecondary['public'] = true;
                }
            }
        });

        svc.updateDisplay(item);
        item.$is_available = svc.itemIsAvailable(item);

        return item;
    };

    svc.transformApiSave = function(data) {
        //# ensure non-null fields are set and fix dates.

        var item = angular.copy(data);
        ['dateaccessioned','replacementpricedate'].forEach(function(col){
            if(item[col]) item[col] = dayjs(item[col]).format('YYYY-MM-DD');
        });
        ['price','replacementprice'].forEach(function(col) {
            if (item[col] === '') item[col] = null;
        });
        angular.forEach(item, function(val, field){
            var fd = svc.fieldDef[field];
            if (!fd) return;
            if (fd.type == 'string' && !val) {
                item[field] = null;
            }
            if (fd.custom) {
                if(!item.fields) item.fields = {};
                item.fields[field] = item[field];
            }
        });
        return item;
    };

    // Item object management
    // Namespace conventions:
    //  item fields are flattened on the frontend (this could be moved to OX)
    //  item.foo is provided by the backend
    //  item._foo is a frontend-derived field
    //  item.$foo is a $resource or class method
    //
    //  JS usually does camelCaps
    //
    svc.extendModel = function(itemClass) {
        itemClass.prototype.$hasFieldValue = function(field) {
            if (['itemlost','notforloan','damaged','wthdrawn'].indexOf(field)>=0) {
                return (this[field] && this[field] != "0") ? true : false;
            }
            else if (field == '_availability') {    // Not really a field, always defined
                return true;
            }
            else {
                return this[field] ? true : false;
            }
        };

        itemClass.prototype.$applyDefaults = function(defaults) {
            //TODO review this
            svc.fieldDefs.forEach(function(fieldDef) {
                var defaultVal = null;
                if( (defaults||{})[fieldDef.code] ) defaultVal = defaults[fieldDef.code];
                if(fieldDef.visibility){
                    if(fieldDef.authval && !defaults[fieldDef.code]){
                        if(['wthdrawn','notforloan','damaged'].indexOf(fieldDef.code)>=0){
                            this[fieldDef.code] = 0;
                        }
                    } else if(defaultVal && fieldDef.type == 'date'){
                        this[fieldDef.code] = dayjs(defaultVal).toDate();
                    } else {
                        this[fieldDef.code] = defaultVal;
                    }
                }
                // Allow add, but not mod of bibid.
                if(!this.biblionumber && defaults.biblionumber) this.biblionumber = defaults.biblionumber;
            }, this);
        };

        itemClass.prototype.$getSortValue = function(field){
            if(field=='enumchron'){
                return (this.serial||{}).publication_date || this.enumchron;
            } else {
                return this[field];
            }
        };
        itemClass.prototype.$copyFrom = function( srcItem ){
            angular.forEach( svc.transformApiSave( srcItem ) , function(val, field){
                this[field] = val;
            }, this);
            return this;
        }
    };


    svc.setHasFieldValue = function(items, field, testFn) {
        for (var i=0; i<items.length; i++) {
            if ( items[i].$hasFieldValue(field) && (!testFn || testFn(field, items[i][field])) )
                return true;
        }
        return false;
    };

    // naturalSorter is not stable and causes infinite digest loops
    /*var naturalSorter = function(a, b){
        if (!a && !b) return 0;
        if (!a) { return -1 }
        if (!b) { return 1 }
        a = a.toLowerCase();
        b = b.toLowerCase();
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        var aA = a.replace(reA, "");
        var bA = b.replace(reA, "");
        if(aA === bA) {
            var aN = parseInt(a.replace(reN, ""), 10);
            var bN = parseInt(b.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    };*/

    var textSorter = function(a,b) {
        if (!a && !b) return 0;
        else if (!a) return -1;
        else if (!b) return 1;

        a = a.toLowerCase();
        b = b.toLowerCase();
        return (a<b ? -1 : a>b ? 1 : 0);
    };

    var numberSorter = function(a,b) {
        return (a<b ? -1 : a>b ? 1 : 0);
    };

    svc.sort = function(items, sortField, reverse) {
        if (!items) return items;
        var sortFunc = textSorter;
        var useDisplay = true;

        if (configService.search.sortOwnBranchFirst) {
            if (sortField == 'homebranch') {
                sortField = '_sorthomebranch';
            }
            else if (sortField == 'holdingbranch') {
                sortField = '_sortholdingbranch';
            }
        }

        var fd = svc.fieldDef[sortField];
        if (sortField == '_sorthomebranch' || sortField == '_sortholdingbranch') {
            sortFunc = textSorter;
            useDisplay = false;
        }
        else if (sortField == 'enumchron'){
            useDisplay = false;
        }
        else if (!fd) {
            sortFunc = textSorter;
        }
        else if (fd.type == 'int' || fd.type == 'decimal') {
            sortFunc = numberSorter;
        }
        else if (fd.type == 'date') {
            sortFunc = numberSorter;
            useDisplay = false;
        }

        // Note, this mutates the original array
        // could use slice.call(items).sort(function) instead
        if (useDisplay) {
            if (reverse)
                return items.sort(function(b,a) { return sortFunc(a._display[sortField],b._display[sortField]) });
            else
                return items.sort(function(a,b) { return sortFunc(a._display[sortField],b._display[sortField]) });
        }
        else {
            if (reverse)
                return items.sort(function(b,a) {
                    return sortFunc(a.$getSortValue(sortField),b.$getSortValue(sortField));
                });
            else
                return items.sort(function(a,b) {
                    return sortFunc(a.$getSortValue(sortField),b.$getSortValue(sortField));
                });
        }
    };

    return svc;
}])


//////////////////////////////////////////////////////////////////////////////////////////////////


.factory('bvMfhdSvc', ["bvItemSvc", "configService", "$filter", function(bvItemSvc, configService, $filter) {
    var svc = {
        fieldDefs: [],
        fieldDef: {},
    };

    ['homebranch','ccode','itemtype','itemcallnumber','location','copynumber'].forEach(function(mfhdField) {
        svc.fieldDefs.push(
            svc.fieldDef[mfhdField] = bvItemSvc.fieldDef[mfhdField]
        );
    });

    svc.make = function(rawMfhd) {

        var mfhd = angular.extend({
                textual_holdings: [],
                items: [],
                visibleItemCols: {results: [], 'public': [], staff: [], edit: []},
                location: {},
                suppressed: false
            }, angular.copy(rawMfhd));

        if(!mfhd.id)
            mfhd._dummy = mfhd.location.homebranch||true;

        mfhd.$hasFieldValue = function(field) {
            if (angular.isArray(mfhd[field]))
                return (mfhd[field].length > 0) ? true : false;
            else
                return (mfhd.location[field] ? true : false);
        };

        svc.updateDisplay(mfhd);

        mfhd._textual_holdings = {
            has: (mfhd.marc_record ? mfhd.marc_record.has('86.') : false),
            expand: false,
        };


        return mfhd;
    };

    svc.updateDisplay = function(mfhd) {
        if (!mfhd._display)
            mfhd._display = {};

        // Conversion to display values
        svc.fieldDefs.forEach(function(fd) {
            var field = fd.code;
            var val = mfhd.location[field];

            // TODO skip if never displayed

            if (fd.authval)
                val = configService.display(val, fd.type);
            else if (field == 'homebranch' || field == 'holdingbranch')
                val = configService.display(val, 'branch');
            else if (field == 'itemtype')
                val = configService.display(val, 'itemtype');
            else if (fd.type == 'date')
                val = $filter('kohaDate')(val);
            else if (fd.type == 'percent')
                val = $filter('percent')(val, fd.filteropts);
            else if (fd.type == 'money')
                val = $filter('kohaMoney')(val, fd.filteropts);
            else if (fd.type == 'moneystr') 
                val = $filter('kohaMoneyOrStr')(val, fd.filteropts);

            mfhd._display[field] = val;
        });


        if (configService.search.sortOwnBranchFirst) {
            if (configService.search_group.branch_map[mfhd.location.homebranch])
                mfhd._sorthomebranch = '0 ' + mfhd._display.homebranch;
            else 
                mfhd._sorthomebranch = '1 ' + mfhd._display.homebranch;
        }
        else {
            mfhd._sorthomebranch = mfhd._display.homebranch;
        }
    };
    

    svc.extendModel = function(mfhdClass) {

        // can't apply $hasFieldValue here, since some mfhds are synthesized
        /*mfhdClass.prototype.$hasFieldValue = function(field) {
            if (angular.isArray(this[field]))
                return (this[field].length > 0) ? true : false;
            else
                return (this.location[field] ? true : false);
        };*/
    };

    svc.setHasFieldValue = function(mfhds, field) {
        for (var i=0; i<mfhds.length; i++) {
            if ( mfhds[i].$hasFieldValue(field) )
                return true;
        }
        return false;
    };


    var naturalSorter = function(a, b){
        if (!a) { return -1 }
        if (!b) { return 1 }
        a = a.toLowerCase();
        b = b.toLowerCase();
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        var aA = a.replace(reA, "");
        var bA = b.replace(reA, "");
        if(aA === bA) {
            var aN = parseInt(a.replace(reN, ""), 10);
            var bN = parseInt(b.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    };

    svc.sort = function(mfhds, sortField) {
        if (sortField == '_sorthomebranch')
            return mfhds.sort(function(a,b) { return (a._sorthomebranch < b._sorthomebranch) ? -1 : (a._sorthomebranch > b._sorthomebranch) ? 1 : 0; });
        else
            return mfhds.sort(function(a,b) { return naturalSorter(a._display[sortField],b._display[sortField]) });
    };

    return svc;
}])


//////////////////////////////////////////////////////////////////////////////////////////////////


.factory('bvHoldings', ["$q", "configService", "kwApi", "bvItemSvc", "bvMfhdSvc", function($q, configService, kwApi, bvItemSvc, bvMfhdSvc){

    // container for mfhds and items.

    var Holdings = function(bib, mfhds, items ){
        var svc = this;

        svc.bib = bib;
        svc.selectedItems = {};

        // items and mfhds are arrays of $resource objects.
        // iface controls visibleItemCols.

        // singular is the object, plural is the array
        svc.items = [];    // flattened, not in mfhds
        svc.mfhds = [];

        svc.item = {};
        svc.mfhd = {};

        svc.visibleItemCols = { // flat equivalent of mfhds, only 'public' is currently used
            results: [],
            'public': [],
            staff: [],
            edit: [],
        };

        svc.visibleMfhdCols = [];   // not interface-specific
        svc.loaded = false;

        function addDummyMfhd(item){
            var mfhd = svc.mfhd[item.homebranch] = bvMfhdSvc.make({
                location: { homebranch: item.homebranch, ccode: '', location: ''},
                suppressed: !!item.suppressed});
            svc.mfhds.push( mfhd );
            mfhd.items.push( item );
        }

        function addMfhdItem(item){
            // item should have mfhd_id if linked.
            if (item.mfhd_id) {
                if(!svc.mfhd[item.mfhd_id])
                    throw "Invalid MFHD id: " + item.mfhd_id;
                svc.mfhd[item.mfhd_id].items.push(item);
                if (!item.suppressed)
                    svc.mfhd[item.mfhd_id].suppressed = false;
            } else {
                // create dummy mfhd identified per branch.
                // TODO: Make this configurable.
                if (!svc.mfhd[item.homebranch]) {
                    addDummyMfhd(item);
                } else {
                    svc.mfhd[item.homebranch].items.push(item);
                    if (!item.suppressed)
                        svc.mfhd[item.homebranch].suppressed = false;
                }
            }
        }
        svc.lastMod = 0;
        svc.modified = function(ts){
            // simplifies watch, assuming mods to items call this.
            if(ts && ts != svc.lastMod){
                svc.lastMod = ts;
                svc._updateItemFieldVisibility();
                svc._updateMfhdFieldVisibility();
            }
            return svc.lastMod;
        }
        svc.clearModState = function(){
            // states: [editing|edited|added|deleting]
            angular.forEach(svc.item, function(item){ delete item._saveStatus; });
        }


        // (re)build the holdings object, set column visibility, and perform initial sort
        svc.init = function(mfhds, items){
            svc.items = [];    // flattened, not in mfhds
            svc.mfhds = [];

            svc.item = {};
            svc.mfhd = {};

            items.forEach(function(item) {
                svc.items.push(
                    svc.item[item.id] = angular.copy(item)
                );
            });

            mfhds.forEach(function(mfhd) {
                mfhd.suppressed = !!mfhd.items.length;
                svc.mfhds.push(
                    svc.mfhd[mfhd.id] = angular.copy(mfhd)
                );
                angular.extend(svc.mfhd[mfhd.id], {
                    visibleItemCols: {results: [], 'public': [], staff: [], edit: []},
                    edit_id: 'mfhd:' + mfhd.id,
                    items: [],
                });

                mfhd.items.forEach(function(itemId) {
                    if (svc.item[itemId])
                        svc.item[itemId].mfhd_id = mfhd.id;
                });
            });

            svc.items.forEach(function(item) {
                addMfhdItem(item);
            });

            svc._updateItemFieldVisibility();
            svc._updateMfhdFieldVisibility();

            svc.sortItems();
            svc.sortMfhds();
            svc.loaded = true;
            return svc;

            //console.dir({items: svc.items, mfhds: svc.mfhds});
        };

        // Set item column visibility in each interface, overall and within each mfhd group
        svc._updateItemFieldVisibility = function() {
            // Flat items
            ['results','public','staff','edit'].forEach(function(iface) {
                    svc.visibleItemCols[iface].length = 0; });

            bvItemSvc.fieldDefs.forEach(function(fd) {
                if (!bvItemSvc.setHasFieldValue(svc.items, fd.code, false)) {
                    return;
                }

                ['results','public','staff','edit'].forEach(function(iface) {
                    if (fd._interfaceVisibility[iface]) {
                        svc.visibleItemCols[iface].push(fd.code);
                    }
                });
            });

            // mfhds
            svc.mfhds.forEach(function(mfhd) {
                ['results','public','staff','edit'].forEach(function(iface) {
                    mfhd.visibleItemCols[iface] = [];
                });

                var excludeMfhdLocation = !configService.search.alwaysDisplayLocData && function(f,v) {
                    return (!(f in mfhd.location) || (v != mfhd.location[f]));
                };

                bvItemSvc.fieldDefs.forEach(function(fd) {
                    if (!bvItemSvc.setHasFieldValue(mfhd.items, fd.code, excludeMfhdLocation)) {
                        return;
                    }

                    ['results','public','staff','edit'].forEach(function(iface) {
                        if (fd._interfaceVisibility[iface]) {
                            mfhd.visibleItemCols[iface].push(fd.code);
                        }
                    });
                });
            });
        };

        // Set mfhd column visibility in each interface, overall and within each mfhd group
        svc._updateMfhdFieldVisibility = function() {
            // FIXME: Allow user to turn off automatic branch grouping.
            svc.visibleMfhdCols = ['homebranch'];

            var showMfhdCols = false;
            svc.mfhds.forEach(function(mfhd) {
                if (mfhd.id) showMfhdCols = true;
            });
            if (!showMfhdCols) return;

            bvMfhdSvc.fieldDefs.forEach(function(fd) {
                if (!bvMfhdSvc.setHasFieldValue(svc.mfhds, fd.code)) {
                    return;
                }

                if (fd._interfaceVisibility['public']) {
                    if (fd.code != 'homebranch') {
                        svc.visibleMfhdCols.push(fd.code);
                    }
                }
            });
        };

        // reload and reinitialize
        svc.refresh = function(){
            console.warn('refreshing holdings');
            var bibid = svc.bib.id;
            svc.loaded = false;
            var items = kwApi.Item.workItems({id: bibid });
            var mfhds = kwApi.Mfhd.workMfhds({id: bibid});

            return $q.all([items.$promise, mfhds.$promise]).then(function(p){
                return svc.init(mfhds, items);
            });
        };

        // Removes mfhd from holdings object.  Caller is responsible for $deleting.
        svc.rmMfhd = function(id){
            if(svc.mfhd[id].items.length){
                console.warn("cannot delete mfhd with items");
                return;
            }
            var n = svc.mfhds.indexOf(svc.mfhd[id]);
            delete svc.mfhd[id];
            if (n>=0) svc.mfhds.splice(n,1);


        };

        // Removes item from holdings object.  Caller is responsible for $deleting.
        svc.rmItem = function(id){
            var item = svc.item[id];

            try {
                // Remove from parent mfhd
                var mfhd = (item.mfhd_id) ? svc.mfhd[item.mfhd_id] : svc.mfhd[item.homebranch];
                if( !mfhd ) throw "No MFHD record for item.";

                var mfhdIdx = mfhd.items.indexOf(item);
                if(mfhdIdx>=0)
                    mfhd.items.splice(mfhdIdx,1);
                else
                    throw "Not in mfhd.";

                // Remove from items
                var n = svc.items.indexOf(item);
                if (n>=0) svc.items.splice(n,1);
                else throw "No match for item. ";

                delete svc.item[id];
                svc.modified( item.timestamp + '.0' ); // ensures change in value.
            } catch (e) {
                console.warn(e + "Reloading holdings.");
                svc.refresh();
            }
        };

        svc.addItem = function ( items ){
            if( !angular.isArray(items) ) items = [ items ];
            var ts = ''; // str cmp.
            items.forEach(function(item){
                if(svc.item[item.id]){
                    console.warn( "Item exists: " + item.id);
                    return;
                }
                var itemCopy = angular.copy(item);
                svc.items.push( svc.item[item.id] = itemCopy );
                    // assume item has already been $relinkMfhded
                addMfhdItem( itemCopy );

                if(itemCopy.timestamp > ts) ts = itemCopy.timestamp;
            });
            svc.modified( ts );
        }


        // Sort items (within mfhds and flat) according to sort field.
        svc.sortItems = function(sortField){
            if (!sortField) {
                sortField = svc.bib.isSerial ? 'enumchron' : 'dateaccessioned';
            }

            svc.mfhds.forEach(function(mfhd) {
                bvItemSvc.sort(mfhd.items, sortField);
            });

            bvItemSvc.sort(svc.items, sortField);
        };

        // Sort mfhds items according to sort field.
        svc.sortMfhds = function(sortField) {
            if (!sortField) {
                sortField = '_sorthomebranch';
            }
            bvMfhdSvc.sort(svc.mfhds, sortField);
        };

        // True if mfhd has value in this field (deprecated?)
        svc.hasMfhdData = function(field){
            return bvMfhdSvc.setHasFieldValue(svc.mfhds, field);
        };

        svc.itemCount = function(mfhd_id) {
            if (mfhd_id)
                return svc.mfhd[mfhd_id].items.length;
            else
                return svc.items.length;
        };

        svc.mfhdCount = function() {
            return svc.mfhds.length;
        };

        svc.realMfhdCount = function() {
            return svc.mfhds.filter(function(mfhd) { return mfhd.id }).length;
        };

        svc.unsuppressedMfhdCount = function() {
            return svc.mfhds.filter(function(mfhd) { return !mfhd.suppressed }).length;
        };


        // Hide items that do not match item-level faceting constraints
        svc.applyItemFacetConstraints = function(constraints) {

            svc.someItemsFacetHidden = false;

            svc.items.forEach(function(item) {
                var include_item = true;
                // FIXME: Due to caching, these constraints will often fail.
                // Hide non-matching items if faceted.
                // items are $resource instances, and may be mutated elsewhere.
                Object.keys(constraints).forEach( function(itemField) {
                    if (!bvItemSvc.fieldDef[itemField]) return;
            console.log( constraints[itemField]);
            console.log( itemField + ' -> ' + item[itemField]);
                    if ((constraints[itemField]||[]).indexOf(item[itemField])<0) {
                        include_item = false;
                        console.log('MATCH');
                    }
                });

                if (!include_item) {
                    item._facet_hidden = true;
                    svc.someItemsFacetHidden = true;
                } else {
                   item._facet_hidden = false;
                }
            });

            svc.mfhds.forEach(function(mfhd) {
                mfhd._facet_hidden = false;
                if (mfhd.items.length && mfhd.items.every(function(i){ return i._facet_hidden; })){
                    mfhd._facet_hidden = true;
                }
            });
        };

        // Deselect items that are not compatible with selected items (Infomart; may be deprecated)
        svc.filterItemSelection = function(id) {
            if (!svc.selectedItems[id]) return;

            var item = svc.item[id];
            var selectionProgram = item.order_type + ' ' + item.program;

            angular.forEach(svc.selectedItems, function (val, key) {
                if (!svc.item[key]) return;

                var selectedItem = svc.item[key];
                var selectedSelectionProgram = selectedItem.order_type + ' ' + selectedItem.program;
                if (selectionProgram !== selectedSelectionProgram)
                    svc.selectedItems[key] = false;
            });
        };


        svc.init(mfhds, items);
    };
    return Holdings;
}])

})();
