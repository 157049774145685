(function () { /* Services */
'use strict';

var module = angular.module('kohapac.services', []);
module.config(["$sceDelegateProvider", function($sceDelegateProvider) {
    var wl = $sceDelegateProvider.resourceUrlWhitelist();
    wl.push('http://books.google.com/**', 'https://books.google.com/**');
    $sceDelegateProvider.resourceUrlWhitelist(wl);
}]);

module.factory('indexDataApi', ["$rootScope", "$timeout", function ($rootScope, $timeout) {
    if ( !KOHA.config.altSearch.indexdata || !KOHA.config.altSearch.indexdata.on || !KOHA.config.IndexDataMKWS ) {
        return {ready: false};
    }

    var svc = {};
    svc.ready = false;
    svc.query = null;

    svc.checkSearchReady = function () {
        if (svc.ready) {
            svc.initiateIndexSearch(svc.query);
        }
        else { // FIXME: use deferred timeouts instead of 1 second timeout loop.
            $timeout(svc.checkSearchReady, 1000);
        }
    };

    svc.checkDetailReady = function () {
        if (svc.ready) {
            svc.initiateIndexDetail(svc.recID)
        }
        else {
            $timeout(svc.checkDetailReady, 1000);
        }
    };

    svc.processIndexDataFacets = function (facets) {
        var returnedFacets = [];

        for (var fIndex in facets) {
            var currFacet = facets[fIndex];

            if ( $.isArray(currFacet) ) {
                var returned = { AvailableFacetValues : [] };
                returned.Label = fIndex == 'xtargets' ? 'Sources' : fIndex[0].toUpperCase() + fIndex.slice(1);
                returned.name = fIndex == 'xtargets' ? 'Sources' : fIndex[0].toUpperCase() + fIndex.slice(1);

                for ( var cIndex = 0; cIndex < currFacet.length; cIndex++ ) {
                    var currChild = currFacet[cIndex];
                    returned.AvailableFacetValues.push({Value : currChild.name, Id : currChild.id, Count : currChild.freq});
                }

                returnedFacets.push(returned);
            }
        }

        return returnedFacets;
    };

    svc.initiateIndexSearch = null;
    svc.initiateIndexDetail = null;

    var default_service_proxy_auth = KOHA.config.altSearch.indexdata.service_proxy_auth ? KOHA.config.altSearch.indexdata.service_proxy_auth : location.protocol + '//' + location.hostname + ( location.port ? ':' + location.port: '') + '/service-proxy-auth/';

    var default_service_proxy_auth_domain = KOHA.config.altSearch.indexdata.service_proxy_auth_domain ? KOHA.config.altSearch.indexdata.service_proxy_auth_domain :  'sp-mkws.indexdata.com';

    $('body').append('<div class="mkws-koha"></div>');

    window.mkws_config = {
        log_level: 'error',
        service_proxy_auth: default_service_proxy_auth,
        service_proxy_auth_domain: default_service_proxy_auth_domain
    };

    mkws.registerWidgetType('koha', function() {
        var that = this;

        svc.initiateIndexSearch = function(query){
            that.team.newSearch(query);
        }

        svc.initiateIndexDetail = function(id){
            that.team.showDetails(id);
        }

        this.team.queue('stat').subscribe(function(n) {
            $rootScope.$broadcast('indexDataSearchCount', n);
        });

        this.team.queue('facets').subscribe(function(n) {
            svc.facets = svc.processIndexDataFacets(n);
            $rootScope.$broadcast('indexDataFacetReady', svc.facets);
        });

        this.team.queue('records').subscribe(function(n) {
            svc.records = n;
            $rootScope.$broadcast('indexDataRecordsReady', svc.records);
        });

        this.team.queue('firstrecords').subscribe(function(n) {
            $rootScope.$broadcast('indexDataSearchDone');
        });

        this.team.queue('complete').subscribe(function(n) {
            $rootScope.$broadcast('indexDataSearchDone');
        });

        this.team.queue('ready').subscribe(function(n) {
            svc.ready = true;
        });

        this.team.queue('pager').subscribe(function(n) {
            svc.pager = n;
            $rootScope.$broadcast('indexDataPagerReady', svc.pager);
        });

        this.team.queue('record').subscribe(function(data) {
            if (svc.recID == data.recid) {
                svc.record = data;
                $rootScope.$broadcast('indexDataRecordReady', svc.record);
            }
        });

        this.team.queue('error').subscribe( function (data) {
            $rootScope.$broadcast('indexDataError',data);
        });
    });

    mkws.init();

    svc.runIndexDataSearch  = function (inputQ) {

        if ( inputQ == null || inputQ == '' ) {
            svc.query = '*';
        }
        else {
            svc.query = inputQ;
        }

        svc.checkSearchReady();
    };

    svc.runIndexDataDetail  = function (inputID) {
        svc.recID = inputID;
        svc.checkDetailReady();
    };

    return svc;
}]);

module.factory('ebscoService', function(){
    var svc = {
        xformItem : function(str){
            return str.replace(/<highlight>/g,'<span class="highlight">')
                            .replace(/<\/highlight>/g,'</span>')
                            .replace(/<searchLink fieldCode="(\w+)" term="(\S+)">/g, '<a href="/app/eds-search?query=$1:$2">')
                            .replace(/<\/searchLink>/g,'</a>')
                            .replace(/<relatesTo>.+?<\/relatesTo>/g, '');

        },
        queryStringToHash : function QueryStringToHash (query) {
          var query_string = {};
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            var k = decodeURIComponent(pair[0]);
            var val = decodeURIComponent(pair[1]).replace(/\+/g,' ');
            if (typeof query_string[k] === "undefined") {
              query_string[k] = val;
            } else if (typeof query_string[k] === "string") {
              var arr = [ query_string[k], val ];
              query_string[k] = arr;
            } else {
              query_string[k].push(val);
            }
          }
          return query_string;
        },
        query: {} // most recent query.
    };

    return svc;
});

module.factory('configService', ["$http", "$sce", "$timeout", "$cookies", "$injector", function($http, $sce, $timeout, $cookies, $injector){

    var svc = {};

    svc._load = function() {
        //var svc = jQuery.extend(true, {}, KOHA.sysprefs, KOHA.config);
        angular.extend(svc, KOHA.sysprefs, KOHA.config);
        // KOHA.config is built from a jsonp request before angular boostraps.

        // handle config settings that include html:
        svc.layout.header_html = $sce.trustAsHtml(svc.layout.header_html);
        svc.layout.footer_html = $sce.trustAsHtml(svc.layout.footer_html);
        // svc.layout.maincontent_html = $sce.trustAsHtml(svc.layout.maincontent_html);
        svc.layout.logo_html = $sce.trustAsHtml(svc.layout.logo_html);

        // alias old identifiers ( TODO: clean up remaining uses of non-prefixed ).
        svc._interpolator.branch = svc._interpolator['bv-branch'];
        svc._interpolator.itemtype = svc._interpolator['bv-itemtype'];
        svc._interpolator.language = svc._interpolator['bv-language'];
        svc._interpolator.branchCategory = svc._interpolator['bv-branch-category'];
        svc._interpolator.patronCategory = svc._interpolator['bv-patron-category'];
    };

    svc._load();

    svc.interpolator = function(type){
        if( (type||'').toLowerCase() in svc._interpolator ){
            return svc._interpolator[type.toLowerCase()];
        } else {
            // console.warn( "No interpolator for " + type);
            return svc._interpolator._default;
        }
    };
    svc.addDisplay = function(type, hash) {
        if(svc._interpolator[type]){
            svc._interpolator[type].add(hash);
        } else {
            console.warn('Attempt to set interpolator value for nonextant ' + type);
        }
    };

    var userService;
    var user_is_staff = function(){
        if(!userService) userService = $injector.get('userService');
        return userService.is_staff;
    };

    svc.display = function(code,type){
        return svc.interpolator(type).display(code, (user_is_staff()?'staff':'opac'));
    };

    svc.listCodes = function(type){
        try{
            return svc.interpolator(type).listCodes();
        } catch (e) {
            console.warn( "No interpolator for " + type);
            return [];
        }
    };

    svc.saveConfig = function(config){
        // Save the opacConfig syspref.

        var toSend = { opacConfig: angular.copy(config, {}) };

        // TODO: traverse object and delete any values that match defaults.
        return $http.put('/api/syspref/opacConfig', JSON.stringify(toSend));

    };
    svc.updateConfig = function(config){
        // update settings.
        // coderefs must be handled separately; we assume caller has them taken care of.

        var config_copy = angular.copy(config, {});
        config_copy.layout.logo_html = $sce.trustAsHtml(config.layout.logo_html);
        config_copy.layout.header_html = $sce.trustAsHtml(config.layout.header_html);
        config_copy.layout.footer_html = $sce.trustAsHtml(config.layout.footer_html);
        // config_copy.layout.maincontent_html = $sce.trustAsHtml(config.layout.maincontent_html);

        delete config_copy.userJS;

        jQuery.extend(true, this, config_copy);

    };

    if(!svc.TagsEnabled){
        svc.TagsShowOnList = svc.TagsShowOnDetail = svc.TagsInputOnList = svc.TagsInputOnDetail = false;
    }

    if( svc.ext_svc.eds ){
            // For now, we have a global EDS profile.
            $http.get('/api/eds/info').then(function(rsp){
                svc.altSearch.eds.on = true;
                svc.altSearch.eds.info = rsp.data;
            }).catch(function(rsp){
                svc.altSearch.eds.on = false;
                svc.altSearch.eds.injectNum = svc.altSearch.eds.injectOffset = 0;
                console.warn(rsp);
            });
    } else {
        svc.altSearch.eds.injectNum = svc.altSearch.eds.injectOffset = 0;
    }

    svc.runUserJs = function(){
        if(typeof svc.userJS.page === 'function') $timeout(svc.userJS.page,1);
    };

    svc.isMobile = function(){
        // FIXME: this should probably be a test for visibility with bootstrap classes.
        return $("#app-body").width() <= 768;
    };
    svc.getXSRFHeader = function(){
        // FIXME: Get rid of $.ajax calls.
        // this cookie should  be httpOnly .

        return {
            'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
        };
    };
    
    svc.branchesOverlap = function(b1,b2) {
        if (b1 == b2) return true;
        var inGroup = false;
        Object.keys(svc.branch_circ_groups[b1]||{}).forEach(function(g) {
            if ((svc.branch_circ_groups[b2]||{})[g])
                inGroup = true;
        });
        return inGroup;
    };

    svc.reloadFixtures = function() {
        console.log("Reloading Fixtures");
        $http.get('/api/allconfig').then(function(cf) {
            KOHA.extend_config(cf.data);
            svc._load();
        });
    };

    return svc;

}]);

module.factory('marcBibSpec', ["$http", function( $http ){

    var loadedPromise = $http.get('/marced/lib/marcbibspec.json').then(function(rsp){
        svc.spec = angular.copy(rsp.data);
        return svc.spec;
    });
    var _indicatorLabels = {
        "Display constant controller" : true,
        "Type of relationship": true,  // 7XX
        "Type of title": true,  // 246 only
        "Controlled element": true  // 355 only
    };
    var _labelOverrideMap = {
        "355": function(f){ // instead of "Document". [dlso]
            if( f.indicator(1)=='0' ) return "Digital Objects";
        },
        "510": function(f){
            return {
                "0": "Indexed by",
                "1": "Indexed in its entirety by",
                "2": "Indexed selectively by"
            }[f.indicator(1)] || "References" ;
        },
        "246": function(f){
            if(f.indicator(2)==' '){
                return "Varying form of title";
            }
        }
    };
    function safeMarcspec(f,k){ // tag, subfield or 'iN' for indicator.
        if(!k) return svc.spec[f]||{};
        return k.length==1 ? ((svc.spec[f]||{}).subfields||{})[k]||{} :
                       (svc.spec[f]||{})[k]||{} ;
    }

    var svc = {
        spec: {},
        loaded: loadedPromise,
        hasMarcLabel: function(tag){
            return tag in _labelOverrideMap ||
                    safeMarcspec(tag, 'i1') in _indicatorLabels ||
                    safeMarcspec(tag, 'i2') in _indicatorLabels ;
        },
        getMarcLabel: function(field){
            if(_labelOverrideMap[field.tag]){
                var label = _labelOverrideMap[field.tag](field);
                if(label) return label;
            }
            for (var ind = 1; ind <= 2; ind++) {
                var indSpec = safeMarcspec(field.tag, "i"+ind);
                if( indSpec.label in _indicatorLabels ){
                    var ind_val = field.indicator(ind);
                    var labelFromMarcSpec = (indSpec.values||{})[ind_val];
                    if(labelFromMarcSpec && labelFromMarcSpec == "No display constant generated"){
                        return field.subfield('i');
                    } else {
                        return labelFromMarcSpec;
                    }
                }
            }
        },
        noteIsHidden: function(field){
            for (var ind = 1; ind < 2; ind++) {
                if(safeMarcspec( field.tag, "i"+ind).label=="Note controller"){
                    var ind_val = field.indicator(ind);
                    return (safeMarcspec(field.tag, "i"+ind).values||{})[ind_val] == "Do not display note";
                }
            }
            return false;
        }
    };



    return svc;
}]);

module.factory('userService', ["$injector", "$http", "$q", "$rootScope", "$filter", "$timeout", "configService", "alertService", "SavedSearchManager", "$uibModal", "$location", "$state", "$cookies", "loading", "$log", "modalService",
                function($injector, $http, $q, $rootScope, $filter, $timeout, configService, alertService, SavedSearchManager, $uibModal, $location, $state, $cookies, loading, $log, modalService){

    var user = {
        id: 0,
        loggedin: false,
        is_staff: false,
        is_infomart_vendor: false,
        has_staff_masthead: false,
        can_place_holds: undefined,
        savedSearches: {},
        clientSession: {},
        // FIXME - either block search until user detail loads
        // or provide a default acl_fq to block all caveats
        acl_fq: null,
        
        q_user_id: null,
        q_authenticated_user_id: null,
        q_authenticated_user_details: null,
        q_any_user_details: null,
        lastExecutedSearch: null,
        loginDlgOpened: false,
        observable: new Rx.Subject()
    };

    // User auth is a hot mess.

    // Async-serialized get user ID method
    // user.whenGetUserId.then(function(id) { /* user.id is now safe */ });
    
    var setTicket = function() {
        if (window.BvProxyTicket) {
            console.log("Setting proxy ticket from window.BvProxyTicket");
            $http.defaults.headers.common['X-Proxy-Ticket'] = window.BvProxyTicket;
        }
        else if (window.BvAuthTicket) {
            console.log("Setting auth ticket from window.BvProxyTicket");
            $http.defaults.headers.common['X-Auth-Ticket'] = window.BvAuthTicket;
        }
    };

    user.whenGetUserId = function() {
        //console.log("whenGetUserID enter");
        if (user.q_user_id === null) {
            //console.log("whenGetUserID === null");
            user.q_user_id = $q.defer();
            setTicket();
            $http.get("/api/login", {authRequired : false}).then(function(rsp){
                var id = parseInt(rsp.data.uri.substr(12),10);
                if(id>0){
                    user.id = id;
                    user.loggedin = true;

                    $timeout(function() {
                        delete $http.defaults.headers.common['X-Auth-Ticket'];
                        delete $http.defaults.headers.common['X-Proxy-Ticket'];
                        window.BvProxyTicket = null;
                        window.BvAuthTicket = null;
                    }, 5000);
                } else {
                    user.id = 0;
                    user.loggedin = false;
                }
                user.q_user_id.resolve(user.id);
            }).catch(function(err){
                user.id = 0;
                user.loggedin = false;
                user.q_user_id.reject(err);
            });
        }
        return user.q_user_id.promise;
    };

    user._clearUserId = function() {
        if (user.q_user_id !== null) {
            user.q_user_id.reject();
            user.q_user_id = null;
        }
        user.id = 0;
        user.loggedin = false;
    };

    // Async-serialized authenticated user method
    // Does the user login dialog box if we start with an anon user
    // user.whenAuthenticatedUser(options).then(function(id) { /* user.id is now safe and >0 */ });

    user.whenAuthenticatedUser = function(options) {
        //console.log("whenAuthenticatedUser start");
        if (user.q_authenticated_user_id === null) {
            user.q_authenticated_user_id = $q.defer();

            user.whenGetUserId().then(function(id) {
                //console.log("whenGetUserId response id = " + id);
                if (id > 0) {
                    return $q.when(id);
                }
                else {
                    return user._doDialogAuth(options);
                }
            }).then(function(rv) {
                user.q_authenticated_user_id.resolve(rv);
            }, function(err) {
                // TODO - could use options here
                if (!(options && options.showAuthError === false)) {
                alertService.add({
                    msg: "You must be logged in to view this page",
                    type: "error"
                });
                }
                if (options && options.redirectOnFail) {
                    $timeout(function() {
                        $location.path(options.redirectOnFail);
                    }, 100);
                }
                if (user.q_authenticated_user_id !== null) {
                    user.q_authenticated_user_id.reject(err);
                    user.q_authenticated_user_id = null;        // Allow caller to try again
                }
            });
        }
        return user.q_authenticated_user_id.promise;
    };

    user._clearAuthenticatedUser = function() {
        if (user.q_authenticated_user_id !== null) {
            user.q_authenticated_user_id.reject();
            user.q_authenticated_user_id = null;
        }
        if (user.q_authenticated_user_details !== null) {
            user.q_authenticated_user_details.reject();
            user.q_authenticated_user_details = null;
        }
    }

    user._doDialogAuth = function(options) {
        var deferred = $q.defer();

        // Clear spinner if we're showing it
        if (configService.showSpinnerOnStateChange)
            loading.resolve('state');


        // downgraded Angular service: 
        modalService.login().result.then( function(id){
            deferred.resolve(id);
        }, function(e) {
            deferred.reject(e);
        });

        // $uibModal.open({
        //     backdrop: (configService.authRequired ? 'static' : false),
        //     keyboard: !configService.authRequired,
        //     templateUrl: '/app/static/partials/login-modal.html',
        //     controller: 'LoginDlgCtrl',
        //     size: ((configService.external_auth.saml||{}).iframe ? 'lg' : 'md'),
        //     resolve: {
        //         loginOptions: function() {
        //             return options || {};
        //         }
        //     }
        // }).result.then(function(id) {
        //     // user successfully authenticated
        //     deferred.resolve(id);
        // }, function(e) {
        //     deferred.reject(e);
        // });
        return deferred.promise;
    };


    // Async-serialized authenticated user details method
    // Will require user to login if they haven't already
    // user.whenAuthenticatedUserDetails(options).then(function(details) { /* user.id and user.details now safe */ });

    user.whenAuthenticatedUserDetails = function(options) {
        //console.log("WhenAuthUD ENTER");
        if (user.q_authenticated_user_details === null) {
            //console.log("WhenAuthUD DETAILS");
            user.q_authenticated_user_details = $q.defer();

            user.whenAuthenticatedUser(options).then(function() {
                //console.log("Calling _getAuthUD from whenAuthUD");
                return user._getAuthenticatedUserDetails();
            }).then(function(rv) {
                //console.log("WhenAuthUD RESOLVE");
                user.q_authenticated_user_details.resolve(rv);
            }, function(e) {
                //console.log("WhenAuthUD REJECT/NULL");
                user.q_authenticated_user_details.reject(e);
                user.q_authenticated_user_details = null;
            });
        }
        return user.q_authenticated_user_details.promise;
    };

    function extractUserCirc (httpData) {
        return {
            fines: {
                total: httpData._embed.fines_summary.balance,
                total_accruing: httpData._embed.fines_summary.total_accruing
            },
            issues: {
                total: httpData._embed.issues_summary.total,
                overdue: httpData._embed.issues_summary.overdue
            },
            holds: {
                pending: httpData._embed.holds_summary.pending,
                waiting: httpData._embed.holds_summary.waiting,
                transiting: httpData._embed.holds_summary.transit,
                total:  Number(httpData._embed.holds_summary.pending) + Number(httpData._embed.holds_summary.waiting) + Number(httpData._embed.holds_summary.transit)
            }
        };
    }
    user.updateCircData = function(){
        if(!user.details_data) return;
        return $http.get("/api/patron/"+user.id ).then(function(rsp){
            user.details_data.circdata = extractUserCirc(rsp.data);
            return user.details_data.circdata;
        });
    };

    user._getAuthenticatedUserDetails = function() {
        //console.log("_getAuthUD ENTER");
        var promises = [];

        // User details
        var p_details = $q.defer();
        $http.get("/api/patron/"+user.id, {authRequired: true}).then(function(rsp){
            var data = rsp.data;
            // deal with _embed data
            data.circdata = extractUserCirc(data);
            data.superlibrarian = data.permit.superlibrarian;
            user.notes = data._embed.notes;
            user.login_branch = data._embed.login_branch;
            user.email = data._embed.email;
            user.username = data.userid;
            user.permit = data.permit;
            user.is_staff = user.can({catalogue: {access: '*'}});
            user.is_infomart_vendor = (data.is_infomart_vendor == 1 || data._embed.is_infomart_vendor == '1') ? true : false;
            user.has_staff_masthead = user.can({catalogue: {access: 'masthead'}});
            user.displayname = (user.is_staff && configService.showStaffUserid) ? data.userid : data.firstname;  // FIXME: make configurable.
            user._initializeUserPrefs(data);
            user.can_place_holds = (data.debarred && data.debarred != "0") ? false : true;
            user.acl_fq = data.acl_fq;
            user.origin_userid = data._embed.origin_userid;
            user.can_switch_account = data._embed.can_switch_account;
            // FIXME - this is an awful place to put this but I can't figure out a better one
            var x = $("#logout-xlink-switch");
            if (x) {
                if (user.can_switch_account)
                    x.show();
                else
                    x.hide();
            }

            var myTimeout = user.merged_prefs.application_timeout;
            if (myTimeout && myTimeout > 0) {
                var n = Math.floor(Number(myTimeout));
                // Let n be a positive integer: https://stackoverflow.com/a/10834843
                if ( n !== Infinity && String(n) === myTimeout && n > 0 ) {
                    millis = 1000 * n;
                    startTimer();
                }
                else {
                    console.warn('Warning: timer input invalid: ' + myTimeout);
                }
            }

            delete data._embed;

            user.details_data = data;
            ['debarred', 'gonenoaddress', 'lost'].forEach(function(key){
                user.details_data[key] = KOHA.toBool(user.details_data[key]);

            });

            var missingData = (configService.UserRequiredDetails||'').split(/\W+/)
                .filter(function(datum) {
                    return (datum !== null && datum !== '' && datum !== '')
                }).filter(function(datum) {
                    return (data[datum] === '' || data[datum] === null || data[datum] === undefined) ? datum : false
                });

            if(missingData.length){
                // FIXME: Move this into login-button.component once dlg svc is upgraded.
                $injector.get('kohaDlg').dialog({
                    heading: 'Update Your Details',
                    message: 'Some of your personal details are missing, please take a moment to update them. Thank you. Required:' + missingData.join(', '),
                    buttons: [{val: true, label: 'Update', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}]
                }).result.then(function(rv) {
                    if (rv) {
                     $location.path('/app/me/details');
                    }
                });
            }

            p_details.resolve(data); 
        }).catch(function(e) {
            p_details.reject(e);
        });
        promises.push(p_details.promise);

        // All other user data
        var p_searches = $q.defer();
        $http.get("/api/patron/"+user.id+"/saved-searches", {authRequired: true}).then(function(rsp) {
            var saved_search_data = rsp.data.map(function(searchmeta){ return searchmeta._embed; });
            user.savedSearches = new SavedSearchManager(saved_search_data);
            p_searches.resolve(user.savedSearches);
        }).catch(function(e){
            console.warn(e);
            p_searches.resolve();   // Don't reject, we don't care if the backend fails, just that the GET completes
        });
        promises.push(p_searches.promise);

        var p_all = $q.defer();
        $q.all(promises).then(function(results) {
            //console.log("_getAuthUD RESOLVE");
            user.observable.next(user);
            p_all.resolve(results[0]);
        }, function(err) {
            //console.log("_getAuthUD REJECT");
            p_all.reject(err);
        });

        return p_all.promise;
    };

    user.hasValidSession = function(){
        // FIXME: don't use cookies directly.
        var curSession = $cookies.get('plack_session');
        if(curSession && user.sessionid){
            return curSession == user.sessionid;
        }
        return true; // be permissive.
    };

    // Async-serialized user details method
    // Does not require user to login; will return anon details for user id = 0
    // user.whenAnyUserDetails(options).then(function(details) { /* user.id and user.details now safe */ });

    user.whenAnyUserDetails = function(options) {
        //console.log("whenAnyUD ENTER");

        if (user.q_any_user_details === null) {
            // console.log("whenAnyUD DEFER");
            user.q_any_user_details = $q.defer();

            user.whenGetUserId().then(function(id) {
                // console.log("whenAnyUD ID RESOLVE ID=" + id);
                if (id > 0) {
                    // console.log("Calling _getAuthUD from whenAnyUD id=" + id);
                    return user._getAuthenticatedUserDetails();
                }
                else {
                    return user._getAnonUserDetails();
                }
            }).then(function(rv) {
                //console.log("whenAnyUD RESOLVE");
                user.q_any_user_details.resolve(rv);
                // set sessionid...
                user.sessionid = $cookies.get('plack_session');
            }, function(e) {
                // console.log("whenAnyUD REJECT");
                user.q_any_user_details.reject(e);
                // No retry possible
            });
        }
        return user.q_any_user_details.promise;
    };

    user._getAnonUserDetails = function() {
        var deferred = $q.defer();
        $http.get("/api/patron/-1", {authRequired: false}).then(function(rsp) {
            user.permit = rsp.data.permit;
            user.merged_prefs = rsp.data.merged_prefs;
            user.acl_fq = rsp.data.acl_fq;
            user.is_staff = user.can({catalogue: {access: '*'}});
            user.has_staff_masthead = user.can({catalogue: {access: 'masthead'}});
            delete rsp.data._embed;
            //deferred.resolve(data);
            deferred.resolve({});
            user.observable.next(user);
        }).catch(function(e){
            deferred.reject(e);
        });
        return deferred.promise;
    };

    // Async-serialized authenticated user with permission check
    // user.whenAuthenticatedUserCan(perm,scope).then(function() { /* user.id and user.details now safe */ });

    user.whenAuthenticatedUserCan = function(perm, scope, options) {
        //console.log("WhenAuthUC enter");
        var deferred = $q.defer();
        user.whenAuthenticatedUserDetails(options).then(function(details) {
            if (user.can(perm, scope)) {
                //console.log("WhenAuthUC resolve");
                deferred.resolve(1);
            }
            else {
                // TODO - use options
                alertService.add({
                    msg: "You do not have the required permissions for this action",
                    type: "error"
                });
                if (options && options.redirectOnFail) {
                    $timeout(function() {
                        $location.path(options.redirectOnFail);
                    }, 100);
                }
                //console.log("WhenAuthUC reject");
                deferred.reject();
            }
        }, function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    if(configService.overdrive){

        user.overdrive = (function(){
            var  _sessionQ = null,
            _userPromise = null,
            error = null,
            config = angular.copy(configService.overdrive),
            collectionToken = null,
            _failCount = 0,
            clear = function(){
                _sessionQ = _userPromise = collectionToken = error = null;
                _failCount = 0;
            };

            var doAuth = function(password){
                // Called on login (with password) and after session expiry failures (without pw).
                // only called if password is required.
                if( ! config.require_user_pw ) return $q.reject();

                if(!password){
                    var modalInstance =  $uibModal.open({
                        backdrop: false,
                        templateUrl: '/app/static/partials/components/overdrive-auth.html',
                        controller: ["$scope", "$uibModalInstance", function($scope, $uibModalInstance){
                            $scope.username= user.username;
                            $scope.auth = function(pw){
                                $uibModalInstance.close(pw);
                            };   // todo:  add autofocus.
                            }],
                        windowClass: "modal od-auth",
                        size: 'sm',
                    });

                    password = modalInstance.result;
                }

                return $q.when(password, function(pw){
                            return $http.post('/api/overdrive/session',{password:pw});
                        }, function(no_pw){
                            return $q.reject("password_modal_closed");

                        }).then(function(success){
                            return success;
                        });

            };

            var getSession = function (options) {

                var opt = angular.extend( {
                                prompt : false,
                                password : null
                            }, options );

                var redoAuth = (opt.password) ? true : false;

                if( _sessionQ && ! redoAuth ){
                    // excuse the peeks at internals...
                    var state =  { 1 :'resolved', 2: 'rejected' }[_sessionQ.promise.$$state.status];

                    if(!state){  /// i.e. pending.
                        $log.debug('OD|', 'returning pending promise');
                        return _sessionQ.promise;
                    } else if ( state == 'resolved') {
                        var expires = (_sessionQ.promise.$$state.value||{}).expires;
                        if(new Date(expires) > new Date()){
                            $log.debug('OD|', 'returning resolved promise');
                            return _sessionQ.promise;
                        } else {
                            $log.debug('OD|', 'ODSESSION EXPIRED');
                            // getting this in safari excessively. [rch]
                        }

                    } else {  // i.e. rejected.

                        if(!config.require_user_pw || !(opt.prompt || opt.password)){
                            return _sessionQ.promise;
                        }

                    }
                }

                if(_failCount > 4){
                    console.log('Too many Overdrive authentication failures.  Giving up.');
                    return $q.reject('Failed to establish session.');
                }

                _sessionQ = $q.defer();

                user.whenGetUserId().then(function(userid){

                    if(config.require_user_pw && userid > 0 && ( opt.prompt || opt.password )){
                        // on firstCall, always do $http.get.
                        return doAuth( opt.password );
                    } else {
                        return $http.get('/api/overdrive/session').then(function(success){
                            return success; // i.e. returns expires time.
                        }, function(fail){

                            return $q.reject(fail);
                        });
                    }
                }).then(function(auth_rsp){
                            $q.when(_userPromise).finally(function(){
                                $log.debug('OD|', 'Nullifying overdrive _userPromise ');
                                _userPromise = null;
                            });
                            _sessionQ.resolve( auth_rsp.data );
                            return auth_rsp;
                        }, function(err){
                            _failCount++;
                            _sessionQ.reject({ error: 'badcredentials' });

                        });
                return _sessionQ.promise;

            };

            // getSession on load.
            user.whenGetUserId().then(function(userid){
                getSession()  // won't prompt first time.
                .then(function(){
                    $http.get('/api/overdrive/library').then(function(rsp){
                        // fixme: watch out for race condition with updatePatron, which also updates collectionToken.
                        if(rsp.data.collectionToken){
                            collectionToken = rsp.data.collectionToken;
                        }
                    });
                });
            });

            var updatePatron = function(){

                return $q.when(_userPromise).finally( function(){
                    _userPromise = user.whenGetUserId().then(function(userid){
                        if(userid){
                        // FIXME: If error, we shouldn't bother updating.
                            return user.whenAuthenticatedUser().then(function(){
                                    return getSession().then( function(session){
                                        return $http.get('/api/patron/'+user.id+'/overdrive').then(function(response) {
                                            var _data = response.data;
                                            collectionToken = _data.auth.collectionToken;
                                            return _data;
                                        }, function(e){
                                            console.warn(e);
                                            error = e;
                                            return $q.reject(e);
                                        });
                                    }, function(){
                                        $log.warn('OD|',  "sessionPromise rejected..." );
                                        return $q.reject('No session');
                                    });
                                });

                        } else {
                            return $q.reject( 'Anonymous' );
                        }
                    });

                    return _userPromise;
                });

            };
            return {
                patron : function(){
                    return (_userPromise) ? _userPromise : updatePatron();
                },
                updatePatron : updatePatron,
                loginAuth: function(pw){
                    // invalidate the user and the session immediately.
                    _userPromise = _sessionQ = null; // probably rather fragile.
                    getSession( { password: pw } );
                },  // exposed for login.
                session : getSession,
                clear : clear,
                collectionToken: function(){
                    return collectionToken;
                    // return this.patron().finally( function(p){ return collectionToken; });
                }
            };
        })();
    }

    if (configService.cloudlibrary) {
        user.cloudlibrary = ( function () {
            var _data         = null;
            var _firstPromise = null;
            var error         = null;
            var noauth_ok     = true;

            var update = function () {
                var promise;

                if (user.id) {
                    promise = user.whenAuthenticatedUser().then( function () {
                        return $http.get('/api/patron/'+user.id+'/cloudlibrary').then( function (response) {
                            var x2js = new X2JS();  // eslint-disable-line no-undef
                            _data = x2js.xml_str2json(response.data);
                            return _data;
                        }, function(e){
                            console.error(e);
                            error = e;
                        });
                    });
                }
                else {
                    promise = $q.when(null);
                }

                if (!_firstPromise) {
                    _firstPromise = promise;
                }

                return promise;
            };

            return {
                promise : function() {
                    return (_data) ? $q.when(_data) : (_firstPromise) ? _firstPromise : update();
                },
                update : update,
                clear : function () {
                    _data = _firstPromise = error = null;
                    noauth_ok = true;
                },
                noauth_ok : function(){ return noauth_ok; }
            };
        })();
    }

    var timeout, millis = 60000;

    var startTimer = function () {
        $timeout.cancel(timeout); // Protect against multiple calls on login.
        timeout = $timeout(onExpires, millis);
        $(document).on("mousemove keypress", onActivity);
    }

    var onExpires = function () {
        $(document).off("mousemove keypress", onActivity);
        onIdle();
    }

    var onActivity = function () {
        $timeout.cancel(timeout);
        $(document).off("mousemove keypress", onActivity);
        timeout = $timeout(startTimer, 1000); // Wait a second to avoid hammering the system.
    }

    var onIdle = function () {
        // TODO: may be useful to have a countdown warning before:
        user.logout();
    }

    // ==================================================================================================
    // This hasn't been refactored yet so it's still a bit of a mess
    user.login = function(username, password, branch){
        var params = {login: username, password: password};
        if(branch) params.branch = branch;

// FIXME: Promise below never resolves if $get is authRequired but we're not authenticated.

        var rv_deferred = $q.defer();
        // Wait for any pending auth to complete before changing promises
        //console.log("Calling whenAnyUD from login");
        user.whenAnyUserDetails().then(function() {
            //console.log("whenAnyUD completed");
            //user.q_user_id = $q.defer();
            setTicket();
            $http.post("/api/login", $.param(params),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                .then(function(rsp){
                    var id = parseInt(rsp.data.uri.substr(12), 10);
                    if(id>0){
                        user.id = id;
                        user.loggedin = true;
                    }
                    else {
                        user.id = 0;
                        user.loggedin = false;
                    }

                    if (user.q_user_id !== null) {
                        user.q_user_id.reject();
                        user.q_user_id = null;
                    }
                    if (user.q_any_user_details !== null) {
                        user.q_any_user_details.reject();
                        user.q_any_user_details = null;
                    }

                    //user.q_user_id.resolve(user.id);
                    if(user.overdrive) user.overdrive.loginAuth(password);
                    user.whenAnyUserDetails().then(function() {
                        $rootScope.$broadcast('loggedin');
                        rv_deferred.resolve(user.id);
                        if(user.cloudlibrary) {
                            user.cloudlibrary.update();
                            $state.reload();
                        }
                    }, function(err) {
                        $rootScope.$broadcast('loggedin');
                        rv_deferred.reject(err);
                    });
                }, function(data, status) {
                    user.id = 0;
                    user.loggedin = false;
                    //user.q_user_id.reject(status);
                    rv_deferred.reject(status);
            });
        });
        return rv_deferred.promise;
    };

    user.logout = function(){
        delete $http.defaults.headers.common['X-Auth-Ticket'];
        delete $http.defaults.headers.common['X-Proxy-Ticket'];
        window.BvProxyTicket = null;
        window.BvAuthTicket = null;

        $timeout.cancel(timeout);

        $(document).off("mousemove keypress", onActivity);

        $http.post("/api/logout").catch(function(data, status){
            console.warn("Failed to log out... status "+ status);
        }).finally(function(data,status){
            user.clear();
            $rootScope.$broadcast('loggedout');
            $state.go('home');
        });

    };
    user.clear = function() {
        user._clearUserId();
        user._clearAuthenticatedUser();
        this.id = 0;
        this.username = '';
        this.displayname = '';
        this.loggedin = false;
        this.is_staff = false;
        this.has_staff_masthead = false;
        this.can_place_holds = false;
        this.login_branch = undefined;
        this.permit = null;
        this.q_any_user_details = null;
        var promise = this.whenAnyUserDetails(); // i.e. anon
        // FIXME - either block search until user detail loads
        // or provide a default acl_fq to block all caveats
        this.acl_fq = null;
        if(this.cloudlibrary) this.cloudlibrary.clear();
        if(this.overdrive) this.overdrive.clear();
        $rootScope.$broadcast('clearUserData');  // All other services should clear.

        promise.then(  (v) => {
                this.observable.next(user);
                return v;
        });

        return promise;
    };

    user.externalAuthInit = function() {
        user._clearUserId();
        user._clearAuthenticatedUser();
        this.id = 0;
        this.username = '';
        this.displayname = '';
        this.loggedin = false;
        this.is_staff = false;
        this.has_staff_masthead = false;
        this.can_place_holds = false;
        this.login_branch = undefined;
        this.permit = null;
        this.q_any_user_details = null;
        console.log("CALLING");
        return user.whenAnyUserDetails().then(function(e) {
            $rootScope.$broadcast('loggedin');
            if(user.cloudlibrary) {
                user.cloudlibrary.update();
                $state.reload();
            }
        });
    };

    user.changePass = function(password,new_password){
        var self = this;
        // FIXME: use ssl

        return $http.post("/api/patron/"+self.id, $.param({op: 'chpass', new_password: new_password, current_password: password}),
            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
                .then(function(rsp){
                    self.id = rsp.data.id;
                    self.loggedin = true;
                });
    };
    // "setPrefs" is semantically confusing, 
    user.setAllPrefs = function(new_prefs) {
        var self = this;
        var enc = JSON.stringify(new_prefs);
        return $http.post("/api/patron/" + self.id, $.param({op: 'set-prefs', prefs: enc}),
            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
                .then(function(rsp) {
                    self._initializeUserPrefs(rsp.data);
                });
    };
    user.addPrefs = function(add_prefs) {
        var self = this;
        var old_prefs = angular.copy(self.prefs);
        angular.forEach(add_prefs, function(val, key) {
            old_prefs[key] = val;
        });
        return self.setAllPrefs(old_prefs);
    };


    user.getPref = function(key) {
        return this.merged_prefs[key];
    };

    user.setPref = function(key, val) {
        var self = this;
        if (!self.loggedin && self.prefs)
            return;

        self.prefs[key] = val;
        self.merged_prefs[key] = val;
        var enc = JSON.stringify(self.prefs);
        return $http.post("/api/patron/" + self.id, $.param({op: 'set-prefs', prefs: enc}),
            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
                .then(function(rsp) {
                    self._initializeUserPrefs(rsp.data);
                });
    };

    // prefs is the user's own userprefs, merged_prefs is merged with category and role prefs
    user._initializeUserPrefs = function(data) {
        var self = this;
        self.prefs = data.prefs;
        self.role_prefs = data.role_prefs;
        self.merged_prefs = data.merged_prefs;
        
        // I don't really want to clutter up alertService with circular dependencies, so keeping this here for now
        if ('message_popup_duration' in self.merged_prefs) {
            alertService.setTimeout(self.merged_prefs.message_popup_duration);
        }
        else {
            self.merged_prefs.message_popup_duration = alertService.getTimeout();
        }
    };

    // user.can(foo) if user has foo
    // user.can({foo: 'bar'}) if user has foo.bar or foo
    // user.can({foo: {bar: 'rag'}})  if user has foo.bar.rag, foo.bar, or foo
    // user.can({foo: {bar: *}})  if user has foo.bar.<any>, foo.bar, or foo
    // user.can({foo: {bar: 'baz'}}, 'catsource=quux') if user has foo.bar.baz for catsource=quux (or unscoped)
    // user.can({foo: {bar: 'baz'}}, '*') if user has foo.bar.baz for ANY scope OR unscoped

    user.can = function(perm, scope) {
        var self = this;
        if (!self.permit || perm === null)
            return false;

        // Note, don't explicitly check for superlibrarian
        // The backend will fill in all level-1 permissions in the closure
        // and we shouldn't be returning true for nonexistent permissions
        var permitNode = 0;
        if(typeof(perm)=='string' && perm.indexOf('.')>-1){
            perm = perm.split('.').reduceRight(function(acc,cv){
                // return { [cv] : acc };
                var o={};  o[cv] = acc;  return o;
            });
        }
        while (perm) {

            if (typeof(perm) == 'object') {
                var key = Object.keys(perm)[0];
                if (permitNode === 0)
                    permitNode = self.permit[key];
                else
                    permitNode = permitNode[key];

                if (permitNode === true)
                    return true;
                else if (!permitNode)
                    return false;

                perm = perm[key];
            }
            else if (perm === '*') {
                return (typeof(permitNode) === 'object' ? true : false);
            }
            else {
                if (permitNode === 0)
                    permitNode = self.permit[perm];
                else
                    permitNode = permitNode[perm];

                if(permitNode === true)
                    return true;
                else if (typeof(permitNode) == 'undefined')
                    return false;
                else
                    perm = permitNode[perm];
            }
        }

        if (scope && permitNode) {
            if (scope === '*') {
                return true;
            }
            else {
                return (permitNode[scope] === true ? true : false);
            }
        }
        return false;
    };

    user.canInBranch = function(perm, branch) {
        return user.can(perm, "branch="+branch)
            || (branch == user.login_branch && user.can(perm, "branch=my_own_branch"))
            || (user.can(perm, "branch=my_branch_group") && configService.branchesOverlap(user.login_branch, branch));
    };

    user.updateDetails = function(userData){
        var toSend = {};
        angular.copy(userData, toSend);
        if(toSend.dateofbirth){
            toSend.dateofbirth = $filter('kohaDate')(toSend.dateofbirth);
        }
        return $http.put('/api/patron/'+this.id, JSON.stringify(toSend), {authRequired: true});
    };
    user.getProxyRelations = function(){
        // returns promise of relations data.
        var deferred = $q.defer();
        $http.get("/api/patron/"+this.id+"/relations", {authRequired: true})
             .then(function(rsp){
                rsp.data.reverse_relations =
                    rsp.data.reverse_relations.filter(function(rel){ return eval(rel.active); });
                rsp.data.reverse_relations = $filter('orderByDisplay')(rsp.data.reverse_relations,'proxy_surname');
                deferred.resolve(rsp.data);
             }, function(data){
                deferred.reject(data);
             });
        return deferred.promise;
    };

    user.setLoginBranch = function(branch){
        var self = this;
        return $http.post('/api/patron/'+this.id, $.param({op: 'set-branch', branch: branch}),
            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true}).
                then(function(){
                    self.login_branch = branch;
                    $rootScope.$broadcast('userSetBranch');
                });
    };

    user.applyProxyTicket = function(ticket) {
        $http.defaults.headers.common['X-Proxy-Ticket'] = ticket;
        user.clear().then(function() {
            return user.whenAuthenticatedUserDetails();
        }).then(function() {
            $state.go('home');
        });
    };

    user.exitProxy = function() {
        delete $http.defaults.headers.common['X-Proxy-Ticket'];
        $http.post('/api/logout?op=endproxy').then(function() {
            user.clear(true).then(function() {
                return user.whenAuthenticatedUserDetails();
            }).then(function() {
                $state.go('home');
            });
        }).catch(function(err) {
            console.log(err);
        });
    };

    user.getAccessibleBranchesAndGroups = function(perm) {
        var deferred = $q.defer();
        $http.get('/api/branch/?view=accessible&groups=1&perm='+encodeURIComponent(perm)).then(function(rsp) {
            if ((typeof(rsp) === 'object') && rsp.data)
                rsp = rsp.data;
            deferred.resolve(rsp);
        }, function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    user.setLastExecutedSearch = function(t) {
        user.lastExecutedSearch = t;
    };

    user.getLastExecutedSearch = function() {
        return user.lastExecutedSearch;
    };

    return user;
}]);

module.factory('BibBatchService', ["$http", "$q", "$timeout", "alertService", "configService", function($http, $q, $timeout, alertService, configService){
    // Service to generate templated email / print for search results set
    // or list of bibids.

    return {
        submit: function(batch, options){

                // options keys:  'op', 'recipient', 'subject'
                // options.op in ('email', 'print', 'download').

                // 'download' currently just yields an html doc with disposition attachment.
                // will support more formats in future.

                if(!options) options = { op: 'print' };
                var params = { op: (options) ? options.op : 'print' };

                if(typeof batch ==="string"){
                    params.searchquery = batch;
                } else {
                    // i.e. an array of bibids.
                    if(!batch.length) return $q.reject();
                    params.work = batch;
                }

                params.subject = (options.subject) ? options.subject :  "Requested data from " + configService.pageTitle;

                var deferred = $q.defer();
                var configObj = {
                    headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
                };
                if(options.op == 'email'){
                    params.recipient = options.recipient;
                    $http.post('/api/works-batch/', $.param(params, true), configObj)
                        .then(function(resp){
                            alertService.add({msg: "Email successfully sent.", type: "success"});
                            deferred.resolve(resp);
                        }, function(rsp){
                            var ermsg = "There was a problem with your request. ";
                            if(rsp=="403 Forbidden Sender has no email address"){
                                ermsg += " \nYou must have an email address on file with the library to send email.";
                                deferred.reject('NO_EMAIL');
                            } else {
                                deferred.reject('ERROR');
                            }
                            alertService.add({msg: ermsg, type: "error", persist: true});
                        }
                    );

                } else {

                    if(options.op=='download'){
                        configObj.responseType='blob';
                    }
                    $http.post('/api/works-batch', $.param(params, true), configObj).then(function(rsp){
                        if(options.op=='print'){
                            var printWindow = window.open();
                            printWindow.document.write(rsp.data);
                            printWindow.document.close();
                            printWindow.print();
                        } else {
                            // i.e. download.
                            var fname_match = rsp.headers("content-disposition").match(/\sfilename="([^"]+)"(\s|$)/);
                            var fname = (fname_match) ? fname_match[1] : 'download';
                            var a = document.createElement("a");
                            var url = window.URL.createObjectURL(rsp.data);
                            a.href = url;
                            document.body.appendChild(a);
                            a.download = fname;
                            a.click();
                            $timeout(function(){
                                    document.body.removeChild(a);
                                    window.URL.revokeObjectURL(url);
                            }, 200);

                        }
                        deferred.resolve();
                    }, function fail(e){
                        console.warn(e);
                        deferred.reject();
                    });

                }
                return deferred.promise;
            }
    };
}]);




module.factory('SavedSearchManager', ["$location", "$http", function($location, $http ){

    var SavedSearchManager = function(data){

        this.all = [];

        for (var i = 0; i < data.length; i++) {
            this.all.push(data[i]);
        }

        this.go = function(index){
            if(this.all[index]){
                var query = this.all[index].query.split('?');
                $location.path( '/app/search/' + decodeURIComponent(query[0]) ).search( query[1] ? decodeURIComponent(query[1]) : '');
            }
        };
        this.remove = function(index){
            if(this.all[index]){
                this.all[index]._deleting = true;
                var self = this;
                return $http({method: 'DELETE', url: '/api/saved-search/'+this.all[index].id, authRequired: true}).
                    then(function(){
                        self.all.splice(index,1);
                    }, function(e){
                        console.warn(e);
                        self.all[index]._deleting = false;
                    });
            }
        };
        this.update = function(index) {
            if (this.all[index]) {
                var self = this;
                return $http.put('/api/saved-search/' + this.all[index].id, JSON.stringify(this.all[index]), {authRequired: true});
            }
        };
        this.has = function(query){
            // prevent duplicates

            var url =  query.asUrl();
            for (var i = 0; i < this.all.length; i++) {
                if(this.all[i].query== url) return true;
            }
            return false;
        };
        this.add = function(query, name, apiParams, override){
            // query is a SearchQuery.

            var url = query.asUrl();
            url = url.replace(/^\/app\/search\//, '');
            var self =this;
            // FIXME: Don't send if not loggedin.
            return $http.post('/api/saved-search', $.param( {query: url, name: name, api_query: apiParams, override: override}),
                {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true} )
                    .then(function(rsp){
                        self.all.push(rsp.data);
                        return 1;
                    }, function(e){
                        if (e.status == 409) {
                            return 0;
                        }
                        else {
                            console.warn(e);
                            return -1;
                        }
                    });
        };

        let escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        } // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping

        this.defaultName = function(query){
            var name = query.q;
            var unique = -1;
            this.all.forEach(function (search) {
                    var m = search.name.match(new RegExp(escapeRegExp(name) + "\\s?(\\d+)?"));
                    if (m) {
                        if (!m[1]) unique = 1;
                        if (m[1] >= unique) unique = parseInt(m[1], 10) + 1;
                    }
            });
            if (unique > 0) name += ' ' + unique;
            return name;
        };
    };
    return SavedSearchManager;

}]);

module.factory('bibService', ["$http", "$q", "CacheFactory", "configService", "bvHoldings", "userService", "kwApi", function($http, $q, CacheFactory,
            configService, bvHoldings, userService, kwApi){

    // see svc.put() for the bib object .
    var svc = {};

    var bibcache = CacheFactory('kohaBibCache', {
            maxAge: 600000,
            capacity: 999
        });
    var holdingscache = CacheFactory('kohaHoldingsCache', {
            maxAge: 300000,
            capacity: 999
        });

    svc.bibCacheCapacity = function() {
        var cacheInfo = bibcache.info();
        return cacheInfo.capacity;
    };

    var bibPromiseCache = {};  // cache promises.
                    // These are deleted after resolution.


    svc.get = function(id, option){
        // returns a promise resolving to the bib instance defined in bibService.put .
        // if option { promise: false },
        // returns an empty object that populates when resolved.
        // Note, repeated calls may yield a new object if the cache expires.

        var opt = angular.extend({ promise: true, includeTags: false }, option);

        if(!id) return;

        var bibObj = bibcache.get(id),
            pendingPromise = bibPromiseCache[id];

        if( !pendingPromise && !bibObj ) {

            // store unresolved promise.
            bibObj = { $resolved: false };
            bibcache.put(id, bibObj );

            var self = this;
            // Note, this method isn't the only one that 'puts'.

            var embeds = "";
            if(opt.includeTags){
                embeds = "?include_tags=true";
            }
            pendingPromise = bibPromiseCache[id] =
                $http.get("/api/work/"+ id + embeds).then(
                    function(rsp){
                        var bib = self.put(rsp.data);
                        for (var key in bib){
                            if(bib.hasOwnProperty(key))
                                bibObj[key] = bib[key];
                        }
                        return bibObj;
                    }, function(e){
                        return $q.reject(e);
                    });

        }
        if(opt.promise)
            return pendingPromise || $q.when(bibObj);
        else {
            return bibObj;
        }
    };

    svc.clearCache = function(bibid){
        if(bibid){
            holdingscache.remove(bibid);
            bibcache.remove(bibid);
            bibPromiseCache[bibid] = undefined;
        } else {
            holdingscache.removeAll();
            bibcache.removeAll();
            bibPromiseCache = {};
        }
    };

    // svc.item_constraints = {};

    svc.hasItemField = {};
    angular.forEach(configService.ItemFields, function(f) {
        if (f.custom)
            svc.hasItemField[f.code] = 2;
        else
            svc.hasItemField[f.code] = 1;
    });

    // XXX YYY refactor
    svc.holdings = function(bibid, useroptions){
        // returns a promise.

        var options = angular.extend( { cache: true }, useroptions);
        var self = this;
        var deferred = $q.defer();

        if(options.cache && holdingscache.get(bibid.toString())){
            deferred.resolve(holdingscache.get(bibid.toString()));
        } else {
            var items = kwApi.Item.workItems({id: bibid});
            var mfhds = kwApi.Mfhd.workMfhds({id: bibid});
            var bib = svc.get(bibid);

            $q.all([items.$promise, mfhds.$promise, bib]).then(function(p){

                var holdings = new bvHoldings(p[2], mfhds, items);
                // XXX YYY check to make sure this can't happen too late
                // holdings.applyItemFacetConstraints(svc.item_constraints);

                if(options.cache) holdingscache.put(bibid, holdings);
                deferred.resolve(holdings);
            });
        }
        return deferred.promise;
    };

    /* unused */
    // svc.getItem = function(itemid){
    //     // returns an item if it's stored in the bib cache, otherwise null.
    //     var bibids = holdingscache.keys();
    //     for (var i = 0; i < bibids.length; i++) {
    //         if(holdingscache.get(bibids[i]).items[itemid]) return holdingscache.get(bibids[i]).items[itemid];
    //     }
    //     return null;
    // };

    svc.put = function(bibdata, options){

        var id = parseInt(bibdata.id,10);
        // options.replace: default true.  If bib exists in cache, don't overwrite it, just get it.
        var replace = (options && options.hasOwnProperty('replace') && !options.replace) ? false : true;

        var bib = bibcache.get(''+id);
        var depunct = function(str){ return (str||'').replace(/\s*[\/:\.]?\s*$/, ''); };

        bibdata.summary.full_item_count = bibdata.summary.item_count;
        //naive lostitem/suppressed handling.  [FIXME]
        if (!userService.is_staff) {
            bibdata.summary.item_count = bibdata.summary.available_item_count;
        }
        if(replace || !bib){
            var formats = (bibdata.format.length==1 && bibdata.format[0]==='') ? [] : bibdata.format;
            bib = {
                id: id,
                default_cover_id: bibdata.default_cover_id,
                title: depunct(bibdata.title),
                title_ext: depunct(bibdata.title_ext),
                isbn: bibdata.isbn,
                marc: new MarcRecord(bibdata.marc),
                uri: '/app/work/' + bibdata.id,
                format: formats,
                content: bibdata.content,
                language: (bibdata.language||'').replace(/^\s+|\s+$/g,''),
                summary: bibdata.summary,
                geo : bibdata.geo,
                records : $.map(bibdata.uuids,function(uuid){
                    var guide = $.map(bibdata.guides,function(currGuide){
                            if(currGuide.indexOf(uuid) > -1) return currGuide;});
                    return { uuid : uuid, GUIDE : guide === null ? null : guide[0]};
                }),
                opac_hold_policy: bibdata.opac_hold_policy,
                isSerial: formats.indexOf('serial') != -1,
                opac_hold_ok: function(lvl){
                    if(!configService.RequestOnOpac || this.opac_hold_policy=='none') // Removed `|| !this.summary.item_count` per https://www.pivotaltracker.com/story/show/161283062/comments/201664012
                        return false;
                    if(lvl){
                        return !this.opac_hold_policy || (this.opac_hold_policy==lvl);
                    }
                    return true;
                },
                author: function(){ return depunct( this.marc.subfield('100a') || this.marc.subfield('110a') ||
                                           this.marc.subfield('700a') || this.marc.subfield('710a') || '' ); },
                publicationDate: function(){ return (this.marc.has('264')) ? this.marc.subfield('264c')  : ""  ;},
                createdDate: function(){ return (this.marc.has('260')) ? this.marc.subfield('260c')  : ""  ;},
                loadDate: function(){ return (this.marc.has('260')) ? this.marc.subfield('260g') : ""  ;},
                isDeleted: function(){ return this.marc.subfield('942n')=='2'; },
                isSuppressed: function(){ return this.marc.subfield('942n')=='1'; },
                itemsAreRenewable: function(){
                    if (Number(this.summary.holds_count) && Number(this.summary.available_count) < Number(this.summary.holds_count)) {
                        return false;
                    }
                    return true;
                }
            };
            if(bibdata._embed.tag_terms)
                bib.tags = bibdata._embed.tag_terms;
            bib.summary.available_at = bib.summary.items_at.filter(function(loc){ return loc.available_count; });
            bibcache.put(''+id,bib);
        }

        // remove promise from cache.
        delete bibPromiseCache[id];

        return bib;
    };

    svc.find_isbn = function(isbn){
        var k = bibcache.keys();
        for (var i = k.length - 1; i >= 0; i--) {
            if(bibcache.get(k[i]).isbn == isbn) return bibcache.get(k[i]);
        }

        return null;
    };

    svc.details_url = function(bibid){
        // return href to details view by user's is_staff prop.
            if(userService.is_staff){
                return "/app/staff/bib/" + bibid + '/details';
            } else {
                return "/app/work/" + bibid;
            }
    };

    return svc;
}]);


module.factory('kohaSearchSvc', ["$state", "$injector", "configService", "SearchQuery", "$rootScope", "kwLuceneParser", function($state, $injector, configService, SearchQuery, $rootScope, kwLuceneParser){

    // Koha's search service.

    var currentSearch, lastSearch;

    var svc = {
        currentSearch: function(search){
            if(search){
                lastSearch = angular.copy(currentSearch);
                currentSearch = search;
                $rootScope.$emit('kohaSearchSvc.searchChanged');
            } else {
                return currentSearch;
            }
        },
        clearCurrentSearch: function(){
            lastSearch = angular.copy(currentSearch);
            currentSearch = null;
            $state.params.query = null;
            $rootScope.$emit('kohaSearchSvc.searchChanged');
        },
        lastSearch: function(){
            return lastSearch;
        },
// onSearchChange prob equiv to watching the service's currentSearch().
        onSearchChange: function(scope, fn) {
            scope.$on('$destroy', $rootScope.$on('kohaSearchSvc.searchChanged', fn));
        },
    };

    $rootScope.$on('loggedout', function() {
        svc.clearCurrentSearch();
        svc.lastSearch = null;
    });

    var check_map = function(is_open){
        var geo_on = configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0;
        return (is_open) ? geo_on && $injector.get('mapComptrollerSvc').status() : geo_on;
    };

    // legacy search interface.
    svc.triggerSearch = function(){
        // trigger search from map. ( deprecated ).
        if (check_map()) {
            var search = new SearchQuery(svc.currentSearch());
            var geoShape = $injector.get('mapComptrollerSvc')["geo-shape"];
            var status = $injector.get('mapComptrollerSvc').status();
            if (geoShape !== null && status ){
                search.q = $state.params.query || '*:*';
                search.rmLimit('geo-shape', null);
                search.addLimit('geo-shape', geoShape);
                $state.go('search-results.koha', search.stateParams());
            }
        }
    };

    svc.doSimpleSearch = function(srch){
        // FIXME:
        // takes a new SearchQuery, and searches, retaining previous search limits if map is open.
        if(srch.constructor === Object){
            srch = new SearchQuery(srch);
        }
        var curSrch = svc.currentSearch();
        if(!srch.hasLimits() && curSrch && check_map(true)){
            for(var field in curSrch.limitfields){
                srch.addLimit(field, curSrch.limitfields[field]);
            }
        }
        srch.go();

    };

    svc.termHighlighterWithin = function(el){
        // returns watcher fcn for boolean indicating highlight state.
        return function(highlight, wasHighlit){
                if(highlight){
                    try{
                        var parsed_query = kwLuceneParser.parse(svc.currentSearch().q);
                        var terms = kwLuceneParser.extractTerms(parsed_query).filter(
                                    function(term){ return term.length > 2; });
                        if(terms.length)
                            el.highlight(terms);
                    } catch (e) {
                        return;
                    }
                } else if(wasHighlit){
                    el.unhighlight();
                }
            };
        }

    return svc;
}]);


module.factory('kwBeepSvc', function(){

    var audio = { };
    var sound_map = {
            beep: "/intranet-tmpl/prog/sound/beep.ogg",
            warn: "/intranet-tmpl/prog/sound/critical.ogg",
            alert: "/intranet-tmpl/prog/sound/ending.ogg"
        };

    var svc = {

        play: function( type ){
            if(!sound_map[type]) type = 'beep';
            if(!audio[type]) audio[type] = new Audio(sound_map[type]);

            var j = audio[type].play();
        }

    };
    return svc;
});

module.factory('authoritySvc', ["$http", "$q", "CacheFactory", function($http, $q, CacheFactory) {
    var svc = {};

    var authcache = CacheFactory('kohaAuthCache', {
        maxAge: 600000,
        capacity: 999
    });

    var authPromiseCache = {};

// ## FIXME - this svc used only once.  removeme.

    svc.get = function(id) {
        if (!id) return;

        var authObj = authcache.get(id);
        var pendingPromise = authPromiseCache[id];

        if (!pendingPromise && !authObj ) {
            authObj = { $resolved: false };
            authcache.put(id, authObj);

            pendingPromise = authPromiseCache[id] =
                $http.get('/api/authority/'+id).then(
                    function(rsp) {
                        for (var key in rsp.data) {
                            if(rsp.data.hasOwnProperty(key))
                                authObj[key] = rsp.data[key];
                        }
                        return authObj;
                    }, function(e) {
                        return $q.reject(e);
                    }
                );
        }

        return pendingPromise || $q.when(authObj);
    };

    return svc;
}]);

module.factory('authoritiesSvc', ["$http", function($http){
    var svc = {
            query : '',
            fq_authtype : '',
            fq_linked: 'link_count_i:[1 TO *]',
            field: 'auth-heading',
            sort : 'auth-heading-sort asc',
            pager : null,
            results : [],
            hits : 0,
            searching: false,
            done: false
        };
    // a result has keys:
    //  summary, authid, rcn, used, authtype, marc

    svc.clear = function(){
        this.results = [];
        this.hits = 0;
        //this.pager = null;
        this.error = null;
        this.done = false;
    };

    svc.fetch = function(){
        this.clear();
        this.searching = true;
        var solrParams = {
            fq : [this.fq_linked, this.fq_authtype],
            sort : this.sort,
        };
        var headers = {};

        if(this.pager && this.pager.page > 1){
            headers.Range = 'records=' + this.pager.offset + '-' + this.pager.rangeEnd;
            solrParams.start = this.pager.offset;
        }

        var self = this;
        var query = this.field + ":(" + this.query + ")";
        $http.get("/api/index/"+query, { "params" : solrParams, "headers": headers})
            .then(function(response){
                angular.forEach(response.data.hits, function(auth){
                        self.results.push(
                        {
                            summary: auth._embed.summary,
                            authid: auth._embed.authid,
                            rcn:    auth._embed.rcn,
                            marc: auth._embed.marc,
                            marctext: auth._embed.formatted_marc,
                            used: auth.link_count,
                            typecode: auth._embed.typecode
                        });
                });

                self.hits = response.data.total_hits;  // approximate
                self.pager = new KOHA.Pager({numResults: self.hits, offset: response.data.start});
                if(!self.results.length && response.data.start + response.data.hits.length < response.data.total_hits){
                    // skip to next page (FIXME: this is an ugly way to do this.)
                    self.pager.setPage(self.pager.page);
                    self.fetch();
                }
                self.searching = false;
                self.done = true;

            }, function(data){
                self.error = data;
                self.searching = false;
                self.done = true;
            });
    };
    svc.toPage = function(pagenum){   // can't call this 'page' due to how angular calls it.
        this.pager.setPage(pagenum);
        this.fetch();
    };



    return svc;
}]);

module.factory('cartService', ["bibService", function(bibService){
    var svc = {
        bibs: [],
        dlsos: [],
        exportFlag : false,
        selected: {},
        fullview: false
    };

    svc.cartCapacity = function() {
        return bibService.bibCacheCapacity();
    };

    svc.cartCount = function(){
        return svc.bibs.length + svc.dlsos.length;
    };

    svc.add = function(bibid){
        bibid = parseInt(bibid,10);
        if(this.bibs.indexOf(bibid) == -1){
            if ((this.bibs.length + 1) > this.cartCapacity()) { return; }
            this.bibs.push(bibid);
        }
    };

    svc.addDLSO = function(dlso){
        for(var index = 0; index < this.dlsos.length; index++){
            if(this.dlsos[index].uuid == dlso.uuid){
                return;
            }
        }
        // WCS doesn't have a true limit, other than server resources. We're using cartCapacity here
        // for consistency, and also to constrain a user's selections to a reasonable number.
        if ((this.dlsos.length + 1) > this.cartCapacity()) { return; }
        this.dlsos.push(dlso);
    };

    svc.addExportDLSOs = function(uuids){
        this.exportFlag = true;
        var viableDLSOs = [];
        for(var uIndex = 0; uIndex < uuids.length;uIndex++){
            var AddFlag = true;
            for(var dIndex = 0; dIndex < this.dlsos.length;dIndex++){
                if(this.dlsos[dIndex].uuid == uuids[uIndex]){
                    AddFlag = false;
                    break;
                }
            }
            if(AddFlag){
            viableDLSOs.push({uuid : uuids[uIndex]});
            }
        }
        if(viableDLSOs.length > 0)
            this.dlsos = this.dlsos.concat(viableDLSOs);
    }
    svc.removeDLSO = function(guid){
        var sv = -1;
        for(var index = 0; index < this.dlsos.length; index++){

            if(this.dlsos[index].uuid == guid){
                sv = index;
            }
        }

        if(sv != -1)
            this.dlsos.splice(sv,1);

        if(this.dlsos.length == 0)
            this.exportFlag == false;
    };

    svc.remove = function(bibid){
        var i = $.inArray(parseInt(bibid,10), this.bibs);  // FIXME: where is it converted to string?
        if(i==-1) return;
        this.bibs.splice(i,1);
        delete this.selected[bibid];
    };
    svc.removeSelected = function(){
        for (var bibid in this.selected){
            if (this.selected[bibid]) this.remove(bibid);
        }
    };
    svc.removeAll = function(){
        this.bibs = [];
        this.selected = {};
        this.exportFlag = false;
        this.dlsos = [];
    };
    svc.removeAllBibs = function(){
        this.bibs = [];
        this.selected = {};

    };
    svc.removeAllDLSOs = function(){
        this.dlsos = [];
        this.exportFlag = false;
    };
    svc.selectAll = function(bool){
        if(bool){
            this.bibs.forEach(function(bibid){
                this.selected[bibid] = true;
            }, this);
        } else {
            this.selected = {};
        }
    };
    svc.inCart = function(bibid){
        // TODO: check performance.
        return ($.inArray(parseInt(bibid,10), this.bibs) == -1) ? false : true;
    };
    svc.num_selected = function(){
        var n = 0;
        for(var id in this.selected){
            if(this.selected[id]) n++;
        }
        return n;
    };
    return svc;
}]);

module.factory('kohaListsSvc', ["userService", "bibService", "$http", "$q", function(userService, bibService, $http, $q){
    var svc = {
        lists: {},
        dirty: true
    };

    // selected and updating look like { listid: { bibid: bool, bibid: bool ... } ... }

    svc.sync = function(){
        var self = this;
        var p1 = $q.defer();
        $http.get('/api/works-list').then(function(rsp){
            p1.resolve(rsp.data);
        })
        .catch(function(e) {
            p1.reject(e);
        });

        var p2 = $q.defer();

        userService.whenGetUserId().then(function(id) {
            if(userService.id){
                $http.get('/api/patron/'+userService.id+'/works-lists', {authRequired: true}).then(function(rsp){
                    p2.resolve(rsp.data);
                })
                .catch(function(e) {
                    p2.reject(e);
                });
            }
            else {
                p2.resolve([]);
            }
        });

        // These MUST be done in THIS order
        var all = $q.defer();
        p1.promise.then(function(data) {
            self.lists = [];
            data.forEach(function(listdata){
                self.lists[listdata.workslist.shelfnumber] = new KOHA.Biblist(listdata.workslist);
              //  self.pub.push(listdata.workslist)
            });

            return p2.promise;
        }).then(function(data) {
            data.forEach(function(listdata){
                listdata.workslist.is_mine = true;
                self.lists[listdata.workslist.shelfnumber] = new KOHA.Biblist(listdata.workslist);
              //  self.pub.push(listdata.workslist)
            });

            all.resolve(self.lists);
        }, function(e) {
            all.reject(e);
        });
        return all.promise;
    };


    svc.updateList = function(id){

        delete this.lists[id]._error;
        delete this.lists[id]._edit;
        if(id && !this.lists[id]._updating){
            this.lists[id]._updating = true;
            var self = this;
            var data = {};
            ['shelfname','shelfnumber','sortfield', 'is_public'].forEach(function(key){
                data[key] = self.lists[id][key];
            });
            // remove bibdata.
            data.works = this.lists[id].works.map(function(workObj){return {id: workObj.id, added_on:workObj.added_on};});

            return $http.put('/api/works-list/'+id, JSON.stringify(data) , {authRequired: true})
                        .then(function(){
                            self.lists[id]._updating = false;
                        }, function(e){
                            self.lists[id]._updating = false;
                            self.lists[id]._error = e;
                        });
        }
    };
    svc.createList = function(listdata, bibs){
        var now = new Date();
        var params = {};
        angular.copy(listdata, params);
        params.works = JSON.stringify(bibs.map(function(bib){ return {id: bib.id, added_on: now.toISOString()};}));
        params.op = 'create';
        var self = this;
        return $http.post('/api/works-list', $.param(params), {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
            .then(function(){ self.sync(); }); // FIXME: Calling .sync() here could cause race condition if other requests are outstanding.

    };
    svc.addToList = function(id, bibs){
        var now = new Date();

        var to_update = 0;
        bibs.forEach(function(bib){
            if( ! this.lists[id].has_bib(bib.id)){
                // stringify the bib.id to maintain a consistent list of strings
                this.lists[id].works.push( {id: bib.id.toString() , added_on: now.toISOString() });
                to_update++;
            }
        }, this);
        if(to_update){
            return this.updateList(id);
        } else {
            return undefined;  // FIXME: return deferred.
        }
    };
    svc.rmFromList = function(id, bibs){
        if(bibs.length){
            var self = this;
            this.lists[id].works = this.lists[id].works.filter(function(work){
                return (bibs.indexOf(1*work.id) == -1 && bibs.indexOf(""+work.id) == -1);
            });
            bibs.forEach(function(bibid){
                delete self.lists[id].selectedbibs[bibid];
            });
            return this.updateList(id).then(function(rsp){
                console.log(rsp.data);
            }).catch(function(e){
                self.sync();
            });
        }
    };
    svc.deleteList = function(id){
        var self = this;
        self.lists[id].deleting = true;
        return $http({method: 'DELETE', url: '/api/works-list/'+id, authRequired: true}).
                then(function(){
                    delete self.lists[id];
                });
    };

    svc.get_all = function(){
        var allshelves = [];
        Object.keys(this.lists).forEach(function(listid){ allshelves.push(this.lists[listid]); }, this);
        return allshelves.sort(function(a,b){ return a.shelfname.toLowerCase() > b.shelfname.toLowerCase() ? 1 : -1 ;});
    };

    svc.get_public = function(){
        var pubshelves = [];
        Object.keys(this.lists).forEach(function(listid){ if(!this.lists[listid].is_mine) pubshelves.push(this.lists[listid]); }, this);

        return pubshelves.sort(function(a,b){ return a.shelfname.toLowerCase() > b.shelfname.toLowerCase() ? 1 : -1 ;});
        // fixme: pubshelves and myshelves should perhaps be a property of the svc for better performance
    };
    svc.get_mine = function(){
        var myshelves = [];
        Object.keys(this.lists).forEach(function(listid){ if(this.lists[listid].is_mine) myshelves.push(this.lists[listid]); }, this);
        return myshelves.sort(function(a,b){ return a.shelfname.toLowerCase() > b.shelfname.toLowerCase() ? 1 : -1 ;});

    };

    // List comments
    svc.commentCreate = function(list,comment) {
        var deferred = $q.defer();
        var params = {comment: comment};
        $http.post('/api/works-list/' + list.id + '/comments', $.param(params),
            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
                .then(function(resp) {
                    list.comments = resp.data.map(function(obj) { return obj.comment; });
                    deferred.resolve(list.comments);
                }).catch(function(err) {
                    deferred.reject(err);
                });
        return deferred.promise;
    };

    svc.commentUpdate = function(list, c) {
        var deferred = $q.defer();
        $http.put('/api/list-comment/' + c.id, JSON.stringify(c),{authRequired: true}).then(function() {
            $http.get('/api/works-list/' + list.id + '/comments').then(function(rsp) {
                list.comments = rsp.data.map(function(obj) { return obj.comment; });
                deferred.resolve(list.comments);
            }).catch(function(err) {
                deferred.reject(err);
            });
        }).catch(function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    svc.commentDelete = function(list, c) {
        var deferred = $q.defer();
        $http({method: 'DELETE', url: '/api/list-comment/' + c.id, authRequired: true}).then(function() {
            $http.get('/api/works-list/' + list.id + '/comments').then(function(rsp) {
                list.comments = rsp.data.map(function(obj) { return obj.comment; });
                deferred.resolve(list.comments);
            }).catch(function(err) {
                deferred.reject(err);
            });
        }).catch(function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    svc.get = function(id){
        // add bibdata.
        var shelf = this.lists[id];
        var self = this;
        if (!shelf.comments) {
            shelf.comments = [];
            $http.get('/api/works-list/' + id + '/comments').then(function(resp) {
                shelf.comments = resp.data.map(function(obj) { return obj.comment; });
            });
        }

        if(shelf){

            shelf._shownWorks = shelf.works.length || 0;

            shelf.works.forEach(function(bibdata, i){
                bibService.get(bibdata.id).then(function(bib){
                    bibdata.bib = bib;
                    bibdata.is_visible = !bib.isDeleted() && !bib.isSuppressed();
                    if(!bibdata.is_visible) --shelf._shownWorks;
                }, function(err) {
                    --shelf._shownWorks;
                });
            });
        }
        return shelf;
    };

    svc.clear_private = function(){
        // remove nonpublic lists.
        for(var id in this.lists){
            if(this.lists[id].is_public){
                delete this.lists[id].is_mine;
            } else {
                delete this.lists[id];
            }
        }
    };

    return svc;
}]);
module.factory('SelectionMgr', function(){

    // Simple hash selection manager.
    // tracks selection by id.
    // call select() with the id or the object (or array of either).
    return function SelectionMgr(key){
        if(!key) key = 'id'; // allows selection by object.
        var self = this;
        function _key(keyOrObj){
            if(angular.isObject(keyOrObj)) return keyOrObj[key];
            return keyOrObj;
        }
        this.selected = {};
        this.clear = function(thing){
            if(thing) delete self.selected[_key(thing)];
            else angular.forEach(self.selected, function(v,k){
                        delete self.selected[k];
                    });
        };
        this.count = function(){
            return Object.keys(self.selected).filter(
                    function(id){return self.selected[id]; }.bind(this)).length;
        };
        this.select = function(things){
            if(angular.isArray(things))
                things.forEach(function(thing){ self.selected[_key(thing)] = true; }.bind(this));
            else self.selected[_key(things)] = true;
        };
        this.ids = function(){ return Object.keys( self.selected ).filter(function(k){ return self.selected[k]; })};
    };
});

module.factory('kohaIssuesSvc', ["configService", function( configService){

    var svc = {};

    svc.checkOutBarcode = function(barcode){
        var outcome = {};
 
        var id = null;
 
        //37570000422088
        $.ajax({
                    type: "GET",
                    url:  '../../api/item?barcode='+barcode,
                    async: false,
                    cache: true,
                    contentType: "application/json",
                    headers: configService.getXSRFHeader(),
                    success: function (data) {
                        id = data["_embed"]['item']["itemnumber"];                       
                    },
                    error: function (err) {
                       outcome.status = false;
                       outcome.message = "Failed to complete checkout with provided barcode.";
 
                    }
        });
        
        if(outcome.status == false)
            return outcome;

        $.ajax({
                    type: "POST",
                    url:  '../../api/item/' + id + '?op=issue',
                    async: false,
                    cache: true,
                    headers: configService.getXSRFHeader(),
                    success: function (data) {
                       outcome.status = true;
                       
                    },
                    error: function (err) {
                       outcome.status = false;
                       outcome.message = err.statusText + " -  " + err.responseText;
                    }
        });
 
        return outcome;
    };

    return svc;
}]);

module.factory('kohaCourseSvc', ["$http", "bibService", function($http, bibService){
    var svc = { courses : [] };
    // TODO: Will likely have to implement paging.
    // Or maybe filter out courses with no reserves.

    svc.sync = function(){
        this.loading = true;
        var self= this;
        $http.get('/api/course')
            .then(function(rsp){
                self.courses = jQuery.grep(rsp.data, function(course){ return (course.course_status == "enabled"); });
                self.loading = false;
            }).catch(function(e){
                console.warn(e);
                self.loading = false;
            });
    };
    svc.getCourse = function(id){
        for (var i = this.courses.length - 1; i >= 0; i--) {
            if(this.courses[i].course_id === id){
                if(this.courses[i].course_status==='enabled' && this.courses[i].reserves.length){
                    this.courses[i].reserves.forEach(function(reserve){
                        $http.get('/api/item/'+ reserve.itemnumber).then(function(rsp){
                            reserve.bibid = rsp.data.biblionumber;
                            reserve.bib = {};
                            bibService.get(rsp.data.biblionumber).then(function(bib){
                                console.log(bib);
                                reserve.bib = bib;
                            });
                        });
                    });
                }
                return this.courses[i];
            }
        }
        return null;
    };
    return svc;
}]);

module.factory('kohaReviewSvc', ["$http", "userService", function($http, userService){
    // maintain reviews for a single bib at a time.
    var svc = {
        bibid: null,
        loading: false,
        reviews: [],
        mine: null
    };
    svc.clear = function(){
        svc.bibid = null;
        svc.loading = false;
        svc.reviews = [];
        svc.mine = null;
    };
    svc.get = function(bibid){
        this.clear();
        this.loading = true;
        this.bibid = bibid;
        var self= this;
        $http.get('/api/work/'+bibid+'/reviews')
            .then(function(rsp){
                rsp.data.forEach(function(obj){
                    self.reviews.push(obj.review);
                    if(userService.id && obj.review.borrowernumber==userService.id){
                        self.mine = obj.review;
                    }
                });
                self.reviews = self.reviews.sort(function(a,b){return a.datereviewed < b.datereviewed;});
                self.loading = false;

            }, function(e){
                console.log(e);
                self.loading = false;
            });
    };
    svc.submit = function(review){
        if(review.review && this.bibid && userService.id){
            return $http.post('/api/work/'+this.bibid+'/reviews', $.param({text: review.review}),
                {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true});
        }

    };
    return svc;
}]);

module.factory('patronRegSvc', ["$q", "alertService", "kwApi", function($q, alertService, kwApi){
    var svc = { loading: false };

    svc.getPatronApps = function(){
        var deferred = $q.defer();
        svc.loading = true;
        kwApi.PatronRegistration.getApps().$promise.then(function(r){
            svc.loading = false;
            deferred.resolve(r);
        });
        return deferred.promise;
    };

    svc.rejectAppSvc = function(d){
        var deferred = $q.defer();
        d.status = 'rejected';
        kwApi.PatronRegistration.rejectApp({id: d.id}, JSON.stringify(d)).$promise.then(function(){
                alertService.add({msg: "Patron application rejected", type: "success"});
                deferred.resolve(1);
            }, function(e){
                alertService.addApiError(e, "Patron application update failed");
                console.warn(e);
                deferred.reject();
            });
        return deferred.promise;
    };

    svc.approveAppSvc = function(d){
        var deferred = $q.defer();
        d.status = 'approved';
        kwApi.PatronRegistration.approveApp({id: d.id}, JSON.stringify(d)).$promise.then(function(){
                alertService.add({msg: "Patron application approved", type: "success"});
                deferred.resolve(1);
            }, function(e){
                alertService.addApiError(e, "Patron application update failed");
                console.warn(e);
                deferred.reject();
            });
        return deferred.promise;
    };

    return svc;
}]);

module.factory('kohaCallslipSvc', ["$http", "userService", "alertService", function($http, userService, alertService){
    var svc = { requests: {}, loading: false };
    svc.sync = function(){
        var self= this;
        this.loading = true;
        return $http.get('/api/patron/'+userService.id+'/callslips', {authRequired: true})
            .then(function(rsp){
                self.requests = rsp.data.map(function(obj){ return obj.callslip; });
                console.log(self.requests);
                self.loading = false;
            }, function(e){
                console.log(e);
                self.loading = false;
            });
    };
    svc.submit = function(request){
        var data = {};
        angular.copy(request, data);

        data.work_id = data.biblionumber;
        data.branch_id = data.pickup_branch;
        data.item_id = data.requested_itemnumber;
        if(data.not_needed_after && typeof(data.not_needed_after)=='object'){
            data.not_after = data.not_needed_after.toISOString();
        }
        data.type = data.request_type;
        data.note = data.request_note;
        data.op = 'place';
        return $http.post('/api/callslip', $.param(data),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
            .then(function(){
                // FIXME: API isn't working yet.  return to this later.
            });
    };
    svc.cancel = function(id){
        var self =this;
        return $http({
            method: 'DELETE',
            url: '/api/callslip/'+id,
            authRequired: true,
            headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).then(function(){
                for (var i = self.requests.length - 1; i >= 0; i--) {
                    if(self.requests[i].callslip_id===id){
                        self.requests[i].request_status= 'canceled';
                        break;
                    }
                }
                alertService.add({msg: "Request successfully canceled.", type: "success"});
            }, function(why){
                alertService.addApiError(why,'Cancellation failed');
            });
    };
    svc.has_current_request = function(bibid){
        // users may only have one request at a time ona bib.

    };
    return svc;
}]);

module.factory('ratingService', ["$http", "$q", "userService", "alertService", function($http, $q, userService, alertService) {
    var svc = {
        user_cache: {},
        loaded: false,
        user_id: 0
    };

    svc.submit = function(rating, bibid) {
        var deferred = $q.defer();

        $http.post('/api/work/'+bibid + '/ratings', $.param({rating: rating}), {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
        .then(function(resp) {
            alertService.add({msg: 'Rating updated'});
            deferred.resolve(resp);

        }, function(resp) {
            alertService.addApiError(resp,'Rating submmission failed');
            deferred.reject(resp);
        });

        return deferred.promise;
    };

    svc.getPatronRatings = function() {
        var deferred = $q.defer();

        userService.whenGetUserId().then(function(id) {
            if (!id) {
                deferred.reject();
                return;
            }
            if (id != svc.user_id) {
                svc.loaded = false;
                svc.user_cache = {};
                svc.user_id = userService.id;
            }

            if (svc.loaded) {
                deferred.resolve(svc.user_cache);
            }
            else {
                $http.get('/api/patron/'+userService.id+'/ratings', {authRequired: true}).then(function(rsp){
                    svc.user_cache = {};
                    rsp.data.forEach(function(rec) {
                        svc.user_cache[rec.rating.work_id] = rec.rating.rating;
                    });
                    deferred.resolve(svc.user_cache);
                }, function(r) {
                    deferred.reject(r);
                });
            }
        }, function(r) {
            deferred.reject(r);
        });

        return deferred.promise;
    };

    return svc;
}]);


module.factory('kohaTagsSvc', ["$http", "$q", "userService", "alertService", "configService", function($http, $q, userService, alertService, configService){
    var svc = {
        mytags: {},
    };

    svc.get = function(bibid){
        var deferred = $q.defer();
        $http.get('/api/work/'+bibid+'/tag-terms').then(function(rsp){
            deferred.resolve(rsp.data);
        }, function(e){
            deferred.reject(e);
        });
        return deferred.promise;
    };
    svc.submit = function(tagterm, bibid){
        var self= this;
        return $http.post('/api/work/'+bibid+'/tag-terms', $.param({ op: 'tag', term: tagterm }), {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true} )
            .then(function(rsp){
                alertService.add({msg: (configService.TagsModeration) ? ' Tag submitted for review.' : 'Tag added!'});
            }, function(data){
                alertService.addApiError(data,'Tag submission failed');
                // TODO: remove tag from display.
            });
    };
    svc.deleteTag = function(id){
        var self = this;
        $http({method: 'DELETE',
                url: '/api/tag/'+id ,
                authRequired: true,
                headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
            }).then(function(){
                alertService.add({msg: "Tag successfully deleted.", type: "success"});
                delete self.mytags[id];
            }, function(huh){
                alertService.addApiError(huh,'Tag deletion failed');
            });
    };
    svc.cloud = function(){
        var deferred = $q.defer();
        var maxsize = 4;  // i.e. em
        $http.get('/api/tag-term').then(function(rsp){
            // api returns ordered by weight, so first result is max weight.
            // FIXME: should probably limit number returned by api.
            // this only works well for maxweight >> 4.
            if(rsp.data.length){
                var x = maxsize / parseInt(rsp.data[0].weight,10);
                deferred.resolve(rsp.data.map(function(tag){ tag.size = parseInt(tag.weight,10) * x + 1; return tag; })
                                 .sort(function(a,b){  return (a.term.toLowerCase() > b.term.toLowerCase()) ? 1:-1; }));
            }
        }, function(data){
            deferred.reject(data);
        });
        return deferred.promise;
    };

    svc.sync_mytags = function(){
        var self = this;
        this.mytags = {};
        var q = $q.defer();
        $http.get('/api/patron/'+userService.id+'/tags', {authRequired: true}).then(function(rsp){
            rsp.data.forEach(function(tag){
                // tag.bib = bibService.get(tag.biblionumber).then(function(bib){
                //     tag.title = bib.title;
                //     tag.title_ext = bib.title_ext;
                //     // This sucks.  now that promises aren't auto-unwrapped, it's getting messy.
                // });
                self.mytags[tag.tag_id] = tag;
            });
            q.resolve(self.mytags);
        });
        return q.promise;
    };

    return svc;
}]);

module.factory('kohaSuggestSvc', ["$http", "$q", "userService", "alertService", function($http, $q, userService, alertService){
    var svc = {};
    var statusmap = { ASKED: 'Requested', REJECTED: 'Rejected', ORDERED: 'On order', ACCEPTED: 'Accepted' };

    svc.mine = function(){
        var deferred = $q.defer();
        $http.get('/api/patron/'+userService.id+'/suggestions', {authRequired: true}).then(function(rsp){
            var suggestions = [];
            rsp.data.forEach(function(responsedata){
                responsedata.suggestion.status = statusmap[responsedata.suggestion.STATUS];
                suggestions.push(responsedata.suggestion);
            });
            deferred.resolve(suggestions);
        }, function(e){
            deferred.reject(e);
        });
        return deferred.promise;
    };
    svc.deleteSuggestion = function(id){
        return $http({method: 'DELETE', url: '/api/suggestion/'+id, authRequired: true}).then(function(){
            alertService.add({msg: "Purchase suggestion successfully deleted.", type: "success"});
        }, function(huh){
            alertService.addApiError(huh,'Purchase suggestion deletion failed');
        });
    };
    svc.submit = function(data){
        console.log(data);
        data.op = 'create';
        if(data.holdfor) data.holdfor = userService.id;
        return $http.post("/api/suggestion", $.param(data), {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true})
                    .then(function(rsp){
                        alertService.add({msg: "Purchase suggestion successfully submitted", type: 'success'});
                    }, function(e){
                        console.warn(e);
                    });

    };
    return svc;
}]);

module.factory('kohaILLRequestSvc', ["$http", "$q", "userService", "alertService", "$filter", function($http, $q, userService, alertService, $filter){
    var svc = {};
    var statusmap = { submitted: 'Submitted', rejected: 'Rejected', ordered: 'On order', approved: 'Approved', available: 'Available' };

    svc.mine = function(){
        var deferred = $q.defer();
        $http.get('/api/patron/'+userService.id+'/ill-requests', {authRequired: true}).then(function(rsp){
            var requests = [];
            rsp.data.forEach(function(ent) {
                ent.request.status = statusmap[ent.request.status] || 'Error';
                if (ent.request.needed_by_date) {
                    ent.request.needed_by_date = $filter('kohaDate')(ent.request.needed_by_date);
                }
                requests.push(ent.request);
            });
            deferred.resolve(requests);
        }, function(e){
            deferred.reject(e);
        });
        return deferred.promise;
    };

    svc['delete'] = function(id){
        return $http({method: 'DELETE', url: '/api/ill-request/'+id, authRequired: true}).then(function(){
            alertService.add({msg: "Interlibrary loan request successfully deleted.", type: "success"});
        }, function(huh){
            alertService.addApiError(huh,'Interlibrary loan request deletion failed');
        });
    };
    svc.create = function(data){
        data.op = 'create';
        if (data.needed_by_date) {
            data.needed_by_date = data.needed_by_date.toISOString();
        }
        if (data.hold_for)
            data.hold_for = userService.id;

        return $http.post("/api/ill-request", $.param(data),
                {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}, authRequired: true}
            ).then(function(){
                alertService.add({msg: "Interlibrary loan request submitted", type: 'success'});
            }, function(huh){
                alertService.addApiError(huh,'Interlibrary loan request submission failed');
                console.warn(huh);
            });
    };
    svc.update = function(data) {
        return $http.put("/api/ill-request/" + data.id, JSON.stringify(data), {authRequired: true}).then(function() {
            alertService.add({msg: "Interlibrary loan request updated", type: 'success'});
        }, function(huh){
            alertService.addApiError(huh,'Interlibrary loan request update failed');
            console.warn(huh);
        });
    };
    return svc;
}]);

module.factory('kohaXISBNSvc', ["$http", "$q", "bibService", function($http, $q, bibService){
    var svc = { loading: false };

    var bibs = {};
    // map of bibid to an array of bibids.

    svc.get = function(bibid){
        this.loading = true;
        var self = this;
        var deferred = $q.defer();
        $http.get('/api/work/'+bibid+'/xisbns').then(function(rsp){
            deferred.resolve(rsp.data.map(function(rel_id){ return bibService.get(rel_id); }));
            self.loading = false;
        }, function(){
            self.loading = false;
        });
        return deferred.promise;

    };
    return svc;

}]);

module.factory('alertService', ["$timeout", "$sce", function($timeout,  $sce){
    var alerts = [];
    var curId = 0;
    var svc = {
        timeout: 7100
    };

    svc.addApiError = function(err, context) {
        var str;
        if ((typeof(err) === 'object') && err.data) {
            str = err.data;
        }
        else if ((typeof(err) === 'object') && err.statusText) {
            str = err.statusText;
        }
        else if (typeof(err) === 'object') {
            str = 'API Error';
        }
        else {
            str = '' + err;
        }

        if (context) {
            str = context + ': ' + str;
        }
        svc.add({msg: str, type: 'error'});
    };


    svc.add = function(alert){
        var self = this;
        // Can't prune by array position with mixed persistent / transient alerts
        curId++;
        // bootstrap alerts.
        // an alert should be: { type: ('error'|'success'), default 'info', msg: 'message.  just text.'}
        // optional property:  persist: true => won't auto expire.

        if (alert.type === 'error')
            alert.type = 'danger'
        else if (!alert.type)
            alert.type = 'info';

        alerts.push({
            id: curId,
            type: alert.type,
            class: 'alert-' + alert.type,
            msg: $sce.trustAsHtml((alert.msg)),
            link : alert.link,
            linkCallBack : alert.linkCallBack
        });
        var t = (alert.timeout > 0 ? alert.timeout : self.timeout);
        if (t && !alert.persist) {
            var id = curId;
            $timeout(function(){
                self.dismiss(id);  // TODO: Add animation.
            }, t);
        }
    };
    svc.dismiss = function(id){
        for (var i=0; i<alerts.length; i++) {
            if (alerts[i].id == id) {
                alerts.splice(i, 1);
                break;
            }
        }
    };
    svc.get = function(){
        return alerts;
    };

    svc.setTimeout = function(n) {
        console.log("Set timeout = " + n);
        svc.timeout = n*1000;
    };
    svc.getTimeout = function() {
        return Math.floor(svc.timeout / 100) / 10;
    };
    return svc;
}]);

module.factory('CoverImage', ["$http", "$q", "bibService", "configService", function($http, $q, bibService, configService){

    // Cover image object.
    // holds a promise, which when resolved has img src attribute for bib cover.
    // should only be used from kwCoverImgSvc for google's image service.

    // serialize thumbnail requests.
    function tryDlsoThumbnail(uuids, deferred){

        var uuid = uuids.shift();

        if(!uuid) {
            deferred.reject("no_uuids");
        }
        else {
            $http.get('api/dlso/'+uuid +'/thumbnail').then(
                function(response){
                        deferred.resolve(uuid);
            }, function(error) {
                if(uuids.length){
                    tryDlsoThumbnail(uuids, deferred);
                } else {
                    deferred.reject("no_valid_thumbnails");
                }
            });
        }
    }
    var patt = new RegExp("(GUIDE://[0-9]{4}/)?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$");
    function getUUID(guide_or_uuid){
        var match = patt.exec(guide_or_uuid);
        return (match) ? match[2] : undefined;
    }

    return function(bibid){
        this._q = $q.defer();
        this.bibid = bibid;
        this.promise = this._q.promise;
        this.src = function(size){ return null; };
        this.dlso = null;
        this.service_link = {};
        this.provider = configService.coverImgSource;


        bibService.get(bibid).then(function(bib){
            var is_dlso = configService.dls.enabled && bib.records.length;

            if(is_dlso){
                this.provider = null;
                var uuids = [];
                var preferred_thumb;
                var fileTypes = {};
                bib.marc.fields('856').forEach(function(curr856){
                    var uuid = getUUID(curr856.subfield("b"));
                    if(uuid){
                        var filetype = curr856.subfield("q");
                        if(ARCHVIEW.mainThumbnailSubField && curr856.subfield(ARCHVIEW.mainThumbnailSubField))
                                preferred_thumb = uuid;
                        uuids.push(uuid);

                    }
                });
                if(preferred_thumb){
                    this.dlso = preferred_thumb;
                    this.src = function(){ return '/api/dlso' + preferred_thumb + '/thumbnail'; };
                    this._q.resolve(this);
                } else {
                    var deferred_uuid = $q.defer();
                    tryDlsoThumbnail(uuids, deferred_uuid);
                    deferred_uuid.promise.then(function(valid_uuid){
                            this.dlso = valid_uuid;
                            this.src = function(){ return "/api/dlso/"+ valid_uuid + "/thumbnail"; };
                            this._q.resolve(this);
                        }.bind(this), function(fail){
                            console.warn(fail);
                            this._q.reject("no_uuid");
                        }.bind(this)
                    );
                }

            } else {
                if (configService.cloudlibrary) {
                    var regExCL = /^cloudLibrary$/,
                        regExImg = /images.yourcloudlibrary.com/,
                        clImageURI = undefined;
                    bib.marc.fields('037').forEach( function (f037) {
                        if (regExCL.test(f037.subfield('b'))) {
                            bib.marc.fields('856').forEach( function (f856) {
                                if (regExImg.test(f856.subfield('u'))) {
                                    clImageURI = f856.subfield('u');
                                    clImageURI = clImageURI + '&size=NORMAL&src=img';
                                }
                            });
                        }
                    });
                    if (clImageURI) {
                        this.src = function(){ return clImageURI;};
                        this.provider = 'clouldLibrary';
                        this._q.resolve(this);
                        return;
                    }
                }

                if (configService.overdrive) {
                    var regExOD = /^OverDrive, Inc.$/,
                        regExImgOD = /images.contentreserve.com/,
                        odImageURI = undefined;
                    bib.marc.fields('037').forEach( function (f037) {
                        if (regExOD.test(f037.subfield('b'))) {
                            bib.marc.fields('856').forEach( function (f856) {
                                if (regExImgOD.test(f856.subfield('u'))) {
                                    odImageURI = f856.subfield('u');
                                    odImageURI = odImageURI.replace(/Type-100/, 'Type-200');
                                    odImageURI = odImageURI.replace(/Img100/, 'Img200');
                                }
                            });
                        }
                    });
                    if (odImageURI) {
                        this.src = function(){ return odImageURI;};
                        this.provider = 'overdrive';
                        this._q.resolve(this);
                        return;
                    }
                }

                var isbn = bib.isbn ? bib.isbn : '';
                if((!isbn || isbn.length === 0) && configService.coverImgSource !== 'syndetics'){
                    this._q.reject("no_isbn");
                    return;
                }
                this.isbn = (typeof isbn === 'object') ? isbn[0] : isbn;
                var sizes;
                if(configService.coverImgSource == 'syndetics'){
                    sizes = { l: 'LC', m: 'MC' };

                    var upc_param = '';
                    var f024 = bib.marc.field('024');
                    if (f024 && f024.indicator(1) === '1' && f024.subfield('a')) {
                        upc_param = '&upc=' + f024.subfield('a');
                    }

                    this.src = function(size){
                        var sizecode = sizes[size] || 'SC';
                        return 'https://secure.syndetics.com/index.aspx?isbn=' + this.isbn + '/' +
                            sizecode + '.GIF&type=xw10&client=' + configService.SyndeticsClientCode
                            + upc_param;
                    };
                    this._q.resolve(this);

                } else if(configService.coverImgSource == 'btol'){
                    sizes = { l: '&Type=L', m: '&Type=M' };
                    this.src = function(size){
                        var sizeparam = sizes[size] || '&Type=S';
                        return "https://contentcafe2.btol.com/ContentCafe/Jacket.aspx?UserID="+
                                    configService.BakerTaylorUsername + "&Password="+
                                    configService.BakerTaylorPassword + "&Value="+
                                    this.isbn + sizeparam;
                    };
                    this._q.resolve(this);

                } else if(configService.coverImgSource == 'amazon'){
                    var file_ext = { l: '.01._SS50_.jpg', m: '.01.SCMZZZZZZZ.jpg'};
                    this.src = function(size){
                        var ext = file_ext[size] || '.01.TZZZZZZZ.jpg';
                        return '//images.amazon.com/images/P/'+this.isbn+ext;
                    };
                    this._q.resolve(this);
                } else {
                    if(configService.coverImgSource == 'gbs'){
                        // TODO: set timer to reject after 5s.
                        this.src = function(size){
                            // Note zoom can be 1-5 (5 is thumbnail, 1-4 = med-xlarge)
                            // but there doesn't appear to be a way to know beforehand which sizes are available.  it appears 1 and 5 always are.
                            // Apparently you can get all available images from https://www.googleapis.com/books/v1/volumes/:bookid
                            // zoom is required.
                            return (size === 'l' || size === 'm') ? 
                                    (this.gbs_thumb||'').replace(/&zoom=(\d)/, '&zoom=1') : this.gbs_thumb;
                        };
                    } else {
                        this._q.reject();
                    }
                }
            }

        }.bind(this), function(e){
            console.warn(e);
            this._q.reject(e);
        }.bind(this));

        this.gbs_update = function(gbsdata){
            if(configService.coverImgSource != 'gbs') return;
            if(gbsdata){
                if(gbsdata.thumbnail_url){
                    this.gbs_thumb = gbsdata.thumbnail_url.replace(/&edge=curl/,'');
                    // sometimes we get curls whether we ask for it or not.;
                }
                this.service_link = {
                    url: gbsdata.info_url,
                    img: (configService.gbs.img == 'button') ?
                                "//books.google.com/intl/en/googlebooks/images/gbs_preview_button1.gif"
                                : "//books.google.com/intl/en/googlebooks/images/gbs_preview_sticker1.gif",
                    title: "View in Google Books service"
                };
                this._q.resolve(this);
            } else {
                this._q.reject("no_gbs_data");
            }
        };
    };
}]);

module.factory('kwCoverImgSvc', ["$http", "$timeout", "bibService", "configService", "CoverImage", function($http, $timeout, bibService, configService, CoverImage){
    // cached cover images.  returns promise.  handles bundling of gbs requests.

    var covers = {};

    var gbs_timeout = null;
    var gbs_queue = {};  // isbn => bibid(s)

    var trigger_gbs_load = function(bib){

        if(!bib.isbn) return;
        if(gbs_queue[bib.isbn]) gbs_queue[bib.isbn].push(bib.id);
        else gbs_queue[bib.isbn] = [bib.id];

        if(!gbs_timeout){
            gbs_timeout = $timeout(function(){
                if(Object.keys(gbs_queue).length){
                    var requested_isbn = angular.copy(gbs_queue);

                    $http.jsonp("//books.google.com/books?bibkeys=" + encodeURIComponent(Object.keys(requested_isbn)) +
                        "&jscmd=viewapi").then(function(rsp){
                            angular.forEach(rsp.data, function(data, isbn){
                                if(!requested_isbn[isbn]) return;
                                requested_isbn[isbn].forEach(function(bibid){
                                    covers[bibid].gbs_update(data);
                                });
                                delete requested_isbn[isbn];
                            });
                            angular.forEach(requested_isbn, function(bibs, isbn){
                                bibs.forEach(function(bibid){ covers[bibid].gbs_update(null);});
                            });
                        }, function(e,status){
                            console.warn("gbs failed with status "+status);
                            console.warn(e);
                            angular.forEach(requested_isbn, function(bibs, isbn){
                                bibs.forEach(function(bibid){ covers[bibid].gbs_update(null);});
                            });
                        });
                    gbs_queue = {};
                }
                gbs_timeout = null;
            }, 50);
        }
    };

    var svc = {
        get: function(bibid){
            if(!covers[bibid]){
                var cover = new CoverImage(bibid);
                bibService.get(bibid).then(function(bib){
                    if(cover.provider == 'gbs'){
                        if(bib.isbn){
                            trigger_gbs_load(bib);
                        } else {
                            cover.gbs_update(null);
                        }
                    }
                }, function(){
                    if(configService.coverImgSource == 'gbs'){
                        cover.gbs_update(null);
                    }
                });
                covers[bibid] = cover;
            }

            return covers[bibid].promise;
        }
    };

    return svc;
}])


.service('bvSearchToPick', ["$state", "$rootScope", "userService", function($state, $rootScope, userService){

        // maintain patron id for  search-to-hold and search-to-issue

            this.patronid = null; // patron id.
            this.action = null; // issue or hold.
            this.targetBib = null;
            var stateListener = null;
            var self = this;

            this.issueTo = function(p){

                this.patronid = p;
                this.action = 'issue';
                // listener should probably be in directive.

                stateListener = $rootScope.$on('$stateChangeSuccess', function(e, toState, toParams){
                    if($state.includes('staff.bib')){
                        self.targetBib = $state.params.biblionumber;
                    } else if($state.includes('work')){
                        self.targetBib = $state.params.bibid;
                    } else {
                        self.targetBib = null;
                    }
                });
            };

            this.holdFor = function(p){
                this.patronid = p;
                this.action = 'hold';
            };

            this.cancel= function(){
                var prevPatronId = this.patronid;
                var prevAction = this.action;
                if(stateListener) stateListener();
                this.patronid = this.action = this.targetBib = stateListener = null;
                if (prevAction == 'hold') {
                    $state.go('staff.patron.checkout',{borrowernumber: prevPatronId});
                }
            };

            this.fastAdd = function(targetBib){
                if(!this.targetBib) return;

                $state.go('staff.circ.fast-add', {
                    biblionumber: this.targetBib,
                    // op: 'save',  // uncomment to create item without form step.
                    // redirect: 1,
                    borrowernumber: this.patronid,
                    holdingbranch: userService.login_branch
                });
                this.cancel();
            };

            this.returnToOrigin = function() {
                // var toState = (this.action=='hold') ?
                //     'staff.patron.details' : 'staff.patron.checkout';
                $state.go( 'staff.patron.checkout', { borrowernumber: this.patronid });
                this.cancel();
            };

}]);


module.factory('kohaDlg', ["$uibModal", "$timeout", "$q", "$injector", "$http", "$state", "kwApi", "userService", "bvSearchToPick", "configService", "$rootScope", function($uibModal, $timeout, $q, $injector, $http,
                        $state, kwApi, userService, bvSearchToPick, configService, $rootScope){

        // globally available modal dialogs, as well as a generic modal. (wait, what??)

    var svc = {

        placeHold: function(bibids, patronid){

                if(!userService.loggedin && configService.external_auth.saml && !configService.external_auth.saml.iframe){
                    window.location.assign('/api/saml/login');
                    return;
                }

                return userService.whenAuthenticatedUserDetails().then(function(details){
                    var maybeGetPatron = patronid || bvSearchToPick.patronid;
                    if(userService.can({reserveforothers: '*'}) && !maybeGetPatron ){
                        // require patron selection first.
                        var bvStaffDlg = $injector.get('bvStaffDlg');
                        maybeGetPatron = bvStaffDlg.patronSelect();
                    }
                    return $q.when(maybeGetPatron, function(patronid){
                        var modalInstance =  $uibModal.open({
                            backdrop: false,
                            templateUrl: '/app/static/partials/placehold-modal.html',
                            controller: 'PlaceHoldsDlgCtrl',
                            windowClass: "modal placehold",
                            size: 'lg',
                            resolve: {
                                bibids: function() { return bibids; },
                                patronid: function () { return patronid; }
                            }
                        });
                        modalInstance.result.finally(function(){
                            if(bvSearchToPick.patronid){
                                bvSearchToPick.returnToOrigin();
                                $rootScope.$broadcast('loadingResolve'); // #177004886
                            }
                        });
                        return modalInstance.result;
                    }, function(fail){
                        console.warn(fail);
                    });
                });

        },
        browseShelf: function(item){

                var modalInstance = $uibModal.open({
                    // backdrop: false,
                    templateUrl: '/app/static/partials/shelfbrowse-modal.html',
                    controllerAs: 'shelf',
                    size: 'lg',
                    controller: ["startItem", function(startItem){
                        // any click should close the modal, except carousel nav.
                        console.log(startItem);
                        this.closeOnSelect = function(modalClose, event){
                            if( $(event.target).parents('div.carousel-inner').length){
                                modalClose();
                            }
                        };

                        this.startItem = startItem;
                        this.branch = startItem.homebranch;
                        this.cn = startItem.itemcallnumber;
                        this.location = startItem.location;
                    }],
                    windowClass: "shelfbrowse-viewer",
                    resolve: {
                        startItem: function () {
                            console.log(item);
                            if(angular.isObject(item))
                                return item;
                            else
                                return kwApi.Item.get({ id: item }).$promise;
                        }
                    }
                });
                return modalInstance.result;

        },
        delBibs: function( bibids ) {
            var modalInstance = $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/del-bibs-modal.html',
                controller: 'DelBibsDlgCtrl',
                resolve: {
                    bibids: function () {
                        return bibids;
                    }
                }
            });
            return modalInstance.result;
        },
        savedSearch: function () {
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/savedSearch-modal.html',
                controller: 'SavedSearchCtrl'
            });
            return false;
        },
        lists: function (shelfid) {
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/list-modal.html',
                size: 'lg',
                controller: 'ListDlgCtrl',
                windowClass: "modal list-viewer",
                resolve: {
                    shelfnumber: function () {
                        return shelfid;
                    }
                }
            });
            return false;
        },
        addToList: function (bibids) {
            if(!userService.loggedin && configService.external_auth.saml && !configService.external_auth.saml.iframe){
                window.location.assign('/api/saml/login');
                return;
            }
            return userService.whenAuthenticatedUserDetails().then(function(d){
                var modalInstance = $uibModal.open({
                    backdrop: false,
                    templateUrl: '/app/static/partials/addtolist-modal.html',
                    controller: 'AddToListDlgCtrl',
                    windowClass: "modal list-adder",
                    resolve: {
                        bibids: function () {
                            return bibids;
                        }
                    }
                });
                return modalInstance.result;
            });

        },
        lostPass: function () {
            // FIXME: debounce.
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/lostpass-modal.html',
                controller: 'LostPassDlgCtrl',
            });

            return false;
        },

        recordView: function(bibid, view){
            var modalInstance = $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/marcview-modal.html',
                size: 'lg',
                controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                    $scope.type = (view == 'labeled') ? 'html' : 'text';
                    $scope.closeModal = $uibModalInstance.close;
                    if (view == 'labeled') {
                        $http.get('/api/work/' + bibid + '/exports/html').
                        then(function (response) {
                            $scope.marc = response.data;
                            $scope.kind = 'Expanded MARC';
                        });
                    } else if (view == 'isbd') {
                        $http.get('/api/work/' + bibid + '/exports/isbd').
                        then(function (response) {
                            $scope.marc = response.data;
                            $scope.kind = 'ISBD';
                        });
                    } else if (view == 'cite-apa') {
                        $http.get('/api/work/' + bibid + '/cite?style=APA&format=html').
                        then(function (response) {
                            if (response.data.cite === null) {
                                $scope.marc = '<em>Citation unavailable</em>';
                            } else {
                                $scope.marc = response.data.cite;
                            }
                            $scope.kind = 'APA Citation';
                        });
                    } else if (view == 'solr') {
                        $scope.closeModal = function(){
                            $uibModalInstance.close($scope.index_time);
                        };
                        $http.get('/api/work/' + bibid + '/exports/solr').
                            then(function (response) {
                                $scope.marc = response.data;
                                $scope.kind = 'Search Index';
                                $scope.bibid = bibid;
                            });
                        $http.get('/api/work/' + bibid + '?view=index_info').
                            then(function (response) {
                                var last_changelog_entry = response.data.last_changelog_entry;
                                var last_solr_index = response.data.last_solr_index;
                                $scope.update_time = last_changelog_entry;
                                $scope.index_time = last_solr_index;
                                var updatetime = new Date(last_changelog_entry);
                                var indextime  = new Date(last_solr_index);
                                if ( (indextime.getTime() - updatetime.getTime()) < 0 ) {
                                    $scope.stale = 1;
                                }
                                else {
                                    $scope.stale = 0;
                                }
                            });
                    } else {
                        $http.get('/api/work/' + bibid + '/exports/text').
                        then(function (response) {
                            $scope.marc = response.data;
                            $scope.kind = 'MARC';
                        });
                    }

                    $scope.indexNow = function (bibid) {
                        $http.post('/api/work/' + bibid + '?op=reindex', {authRequired: true},
                            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}}).
                            then(function (response) {
                                $scope.update = 1;
                                $scope.stale = 0;
                                $scope.index_status = '';
                            }, function (response) {
                              $scope.update = 0;
                              if ( angular.isObject(response) ) {
                                  $scope.index_status = 'ERROR: ' + Object.keys(response);
                              }
                              else {
                                  $scope.index_status = 'ERROR: ' + (response || 'system error') ;
                              }
                            });
                        $timeout(function () {
                            $http.get('/api/work/' + bibid + '?view=index_info').
                                then(function (response) {
                                    var last_changelog_entry = response.data.last_changelog_entry;
                                    var last_solr_index = response.data.last_solr_index;
                                    $scope.update_time = last_changelog_entry;
                                    $scope.index_time = last_solr_index;
                            });
                            $http.get('/api/work/' + bibid + '/exports/solr').
                                then(function (response) {
                                    $scope.marc = response.data;
                                    $scope.kind = 'Search Index';
                                    $scope.bibid = bibid;
                            });
                        }, 2200);
                    };

                }],
                windowClass: "modal marc-viewer",
            });
            return modalInstance.result;
        },
        hotkeyModal: function(){
            var modalInstance = $uibModal.open({
                templateUrl: '/app/static/partials/hotkeyModal.html',
                controller: ["$scope", "$uibModalInstance", "kwHotkeySvc", function($scope, $uibModalInstance, kwHotkeySvc){
                    $scope.keys = kwHotkeySvc.list();

                    var contexts = {
                        global: { name: 'Global', actions: [] },
                        marced: { name: 'Cataloging Editor', actions: []}
                    };
                    $scope.contexts = ($state.includes('staff.marced')) ?
                        [ contexts.marced, contexts.global ] : [ contexts.global, contexts.marced ];

                    var hotkey_actions = kwHotkeySvc.list();
                    var active_hotkeys = kwHotkeySvc.list({active: true});
                    for(var action in hotkey_actions){
                        var context = hotkey_actions[action].context ? 'marced' : 'global';
                        contexts[context].actions.push(action);
                        $scope.keys[action] = {
                            key: hotkey_actions[action].key,
                            action: action,
                            name: hotkey_actions[action].name,
                            desc: hotkey_actions[action].desc,
                            active: kwHotkeySvc.keyAction(hotkey_actions[action].key)==action
                        };
                    }
                    // marced copy-paste.
                    var cpModifier = (window.navigator.platform.match(/^Mac/)) ? 'cmd' : 'ctrl';
                    $scope.keys.marcCopy = {
                        key: cpModifier + '+c',
                        name: 'Copy',
                        desc: 'Copy MARC field or subfield',
                        active: $state.includes('staff.marced')
                    };
                    $scope.keys.marcPaste = {
                        key: cpModifier + '+v',
                        name: 'Paste',
                        desc: 'Paste MARC field or subfield',
                        active: $state.includes('staff.marced')
                    };
                    contexts[context].actions.push('marcCopy');
                    contexts[context].actions.push('marcPaste');

                }]
            });
            return modalInstance.result;
        },
        dialog:  function(data){
        // simple generic dialog.
        // confirm, dialog, alert or wait .
        // confirm & alert just provide buttons for you.
        // options:
        //      heading:  string (required)
        //      message: string|obj (required) [if obj: { tmpl: '/path/to/partial' } / uses ng-include]
        //      type: string, 'dialog', 'notify', or 'wait'.  these provide defaults for the following options:
        //        alertClass: classname(s) for heading.
        //        icon: icon classnames.
        //        buttons: array:
        //              [ {val: any, btnClass: classname, label: 'Ok'}, ... ]
            var modal = $uibModal.open({
                templateUrl: '/app/static/partials/dialogModal.html',
                controller: ["dlgData", "$scope", function(dlgData, $scope){
                        // type: 'dialog', 'notify' or 'help'.
                        // options :
                        //   icon: icon classnames in modal header
                        //   alertClass: heading classname
                        //   heading: text on modal header
                        //   message: text for body of message.
                        //   templateUrl: template to include in modal body.
                        //   close: bool -- show/hide close 'x'

                        // You may prompt for user input (simple text inputs)
                        // by including inputs array:
                        //  [ { name: str, label: str, val: defaultVal, type: str }, ...] .
                        //  options: an ngOptions comprehension_expression, making the input a SELECT.
                        // (excluding options means its an input element with type==type||'text'.)

                        // you may pass in scope vars for any comprehension expressions in a 'scopevars' prop.
                        // -- take care not to clobber anything.

                        // Buttons in that case should return BOOL to either pass through the input values
                        // or return false.

                        // Reusable dialog for confirmation / notification.

                            //$scope.dlg = dlgData;
                            // Defaults:
                            // alertClass : error, info, warning, success .
                            var defaults = {
                                dialog: {
                                    icon: 'bi bi-check',
                                    message: 'Please confirm before proceeding.',
                                    heading: 'Are you sure?',
                                    buttons: [ { label: 'Yes', val: true, btnClass: 'btn-primary', submit: true },
                                                {label: 'No', val: false, btnClass: 'btn-outline-secondary'} ],
                                    alertClass: 'info'
                                },
                                notify: {
                                    icon: 'bi bi-info-sign',
                                    message: '',
                                    heading: 'Notice',
                                    buttons: [ { label: 'Ok', val: true} ],
                                    alertClass: 'info'
                                },
                                help: {
                                    icon: 'bi bi-question-square',
                                    heading: 'Help', alertClass: 'info',
                                    buttons: [ { label: 'Close', btnClass: 'btn-primary'}]
                                }
                            };

                            if(!defaults[dlgData.type]) dlgData.type = 'dialog';

                            $scope.dlg = angular.extend(defaults[dlgData.type], dlgData);
                            $scope.modalBodyClass= dlgData.type + '-modal';
                            for(var s in dlgData.scopevars){
                                $scope[s] = dlgData.scopevars[s];
                            }
                            if(dlgData.ngInclude){
                                $scope.templateUrl = dlgData.ngInclude;
                            }
                            if($scope.dlg.buttons)
                                $scope.dlg.buttons.forEach(function(btnDef){
                                    if(!btnDef.btnClass) btnDef.btnClass = 'btn-outline-secondary' });
                            if(dlgData.inputs){
                                $scope.dlg.inputs = dlgData.inputs;
                                $scope.dlg.input_params = function(){
                                    var ret = {};
                                    for (var i = 0; i < $scope.dlg.inputs.length; i++) {
                                        ret[$scope.dlg.inputs[i].name] = $scope.dlg.inputs[i].val;
                                    }
                                    return ret;

                                };
                            }
                            if(!dlgData.icon  ){
                                if(dlgData.alertClass=='info'){
                                    $scope.dlg.icon = 'icon icon-info-sign';
                                } else if(dlgData.alertClass=='danger' || dlgData.alertClass=='warning'){
                                    $scope.dlg.icon = 'icon icon-warning-sign';
                                } else if(dlgData.alertClass=='success'){
                                    $scope.dlg.icon = 'icon icon-check';
                                }
                            }

                }],
                resolve: { dlgData: function(){
                    return data;
                }},
                windowClass: 'dlg ' + data.alertClass
            });
            return modal;
        }
    };
    return svc;

}]);

module.factory('messageService', ["$http", "$timeout", "$q", "userService", "alertService", "$rootScope", "$sce", function($http, $timeout, $q, userService, alertService, $rootScope, $sce){

    var svc = {
        pullInterval: 300000,
        retryInterval: 5000,   // No userid
        failInterval: 30000,   // Server error
        running: 0,
        list: [],
        prefs: [],
        lastPollTime: new Date(),
        messageIdCache: {}
    };

    // FIXME - if we refresh on the messaging settings page the interval returns to default
    console.log("Calling whenAnyUD from message service");
    var setPollPref = function(){
        userService.whenAnyUserDetails().then(function() {
            if (userService.merged_prefs) {
                if ('message_poll_interval' in userService.merged_prefs) {
                    svc.setPollInterval(userService.merged_prefs.message_poll_interval);
                }
            }
        });
    };
    setPollPref();

    $rootScope.$on('loggedin', function(e, args) {
        console.log("Clearing message ID cache");
        svc.messageIdCache = {};
        svc.lastPollTime = new Date();
        setPollPref();
    });

    svc.start = function() {
        if (svc.pullInterval > 0) {
            svc.running = 1;
            svc._pull(100);
        }
    };

    svc.stop = function() {
        svc.running = 0;
    };

    // Exposed to the outside world as "poll", not "pull", as the mechanism isn't relevant to the caller
    svc.getPollInterval = function() {
        var n = Math.floor(svc.pullInterval / 1000);
        return n;
    };

    svc.setPollInterval = function(n) {
        svc.pullInterval = Math.floor(n)*1000;
        svc.failInterval = svc.pullInterval + 10000;       // entirely arbitrary
        if (n == 0) {
            if (svc.running) {
                svc.stop();
            }
        }
        else { 
            if (!svc.running) {
                svc.start();
            }
        }
    };

    svc.getPrefs = function() {
        var deferred = $q.defer();
        var id = userService.id;
        $http.get('/api/patron/' + userService.id + '/message_preferences').then(function(rsp) {
            svc.prefs = rsp.data;
            deferred.resolve(svc.prefs);
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    svc.updatePrefs = function() {
        var deferred = $q.defer();
        $http.put('/api/patron/' + userService.id + '/message_preferences', JSON.stringify(this.prefs),{authRequired: true}).then(function(rsp) {
            deferred.resolve(rsp.data);
        }, function() {
            deferred.reject();
        });

        var p2 = userService.addPrefs({message_poll_interval: Math.floor(svc.pullInterval/1000)});
        return $q.all([deferred.promise, p2]);
    };


    svc.getList = function() {
        var deferred = $q.defer();
        // Ensure we have a current list even if pull interval is large
        svc._getPending().then(function() {
            // Return a copy so we're not updating the DOM any time we refresh.
            deferred.resolve(angular.copy(svc.list));
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    svc.clear = function(n) {
        var deferred = $q.defer();
        n.delivery_status = 'sent';
        $http.put('/api/message/' + n.id, JSON.stringify(n)).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };


    svc._pull = function(delay) {
        if (svc.running) {
            $timeout(function() {
                svc._getPending().then(function(newDelay) {
                    svc._pull(newDelay);
                }, function(newDelay) {
                    svc._pull(newDelay)
                });
            }, delay);
        }
    };

    svc.get = function(id) {
        var deferred = $q.defer();
        $http.get('/api/message/' + id + '?format=json').then(function(rsp) {
            deferred.resolve(rsp.data);
        }, function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    svc._getPending = function() {
        var deferred = $q.defer();
        var id = userService.id;
        if (id > 0) {
            var curTime = new Date();
            var elapsed = Math.floor((curTime - svc.lastPollTime) / 1000 + 60);

            // Don't keep anything in the cache more than an hour hold
            angular.forEach(svc.messageIdCache,function(val, key) {
                var age = (curTime - val)/1000;
                if (age > elapsed + 3600) {
                    delete svc.messageIdCache[key];
                }
            });

            $http.get('/api/patron/' + id + '/messages?within=' + elapsed + '&message_endpoint_types=popup%2Clist',
              {headers:{'X-KohaOX-IdleReset':false}}).then(function(rsp) {
                svc.lastPollTime = curTime; // time of request, not response

                svc.list.length = 0;
    
                rsp.data.forEach(function(rec) {
                    var n = rec.message;

                    if (n.message_endpoint_type == 'popup' && n.code == 'sys.signal' && !(n.id in svc.messageIdCache)) {
                        var content = JSON.parse(n.content);
                        svc.messageIdCache[n.id] = curTime;
                        
                        $rootScope.$broadcast("sys.signal." + content['event'], content.parameters);
                        n.delivery_status = 'sent';
                        $http.put('/api/message/' + n.id, JSON.stringify(n));
                    }
                    else if (n.message_endpoint_type == 'popup' && !(n.id in svc.messageIdCache)) {
                        svc.messageIdCache[n.id] = curTime;

                        var msg = {};
                        if (n.level && n.level > 1) {
                            msg.type = 'error';
                            msg.persist = true;
                        }
                        else {
                            msg.type = 'info';
                        }

                        if (n.title) {
                            msg.msg = '<b>' + n.title + '</b><br />' + n.content;
                        }
                        else {
                            msg.msg = n.content;
                        }
                        
                        alertService.add(msg);
                        n.delivery_status = 'sent';
                        $http.put('/api/message/' + n.id, JSON.stringify(n));
                    } else if (n.message_endpoint_type == 'list') {
                        $sce.trustAsHtml(n.content);
                        svc.list.push(n);
                    }
                });
                deferred.resolve(svc.pullInterval);
            }, function(data) {
                deferred.reject(svc.failInterval);
            });
        }
        else {
            deferred.reject(svc.retryInterval);
        }
        return deferred.promise;
    };
    return svc;
}]);

module.factory('jobService', ["$http", "$q", "userService", function($http, $q, userService){

    var svc = {
        list: [],
    };

    svc.getList = function() {
        var deferred = $q.defer();
        var id = userService.id;
        if (id > 0) {
            $http.get('/api/patron/' + id + '/async-jobs').then(function(rsp) {
                svc.list.length = 0;
                rsp.data.forEach(function(rec) {
                    svc.list.push(rec.job);
                });
                deferred.resolve(svc.list);
            }, function(e) {
                deferred.reject(e);
            });
        }
        else {
            deferred.reject("No user");
        }
        return deferred.promise;
    };

    svc.remove = function(n) {
        var deferred = $q.defer();
        $http.delete('/api/async-job/' + n.id).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };

    svc.suspend = function(n) {
        var deferred = $q.defer();
        $http.post("/api/async-job/" + n.id, $.param({op: 'suspend'}),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}}).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };

    svc.resume = function(n) {
        var deferred = $q.defer();
        $http.post("/api/async-job/" + n.id, $.param({op: 'resume'}),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}}).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };


    svc.update = function(n) {
        var deferred = $q.defer();
        $http.put('/api/async-job/' + n.id, JSON.stringify(n)).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };

    svc.get = function(id) {
        var deferred = $q.defer();
        $http.get('/api/async-job/' + id).then(function(rsp) {
            deferred.resolve(rsp.data);
        }, function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    svc.reload = function(n) {
        if (n.id) {
            $http.get('/api/async-job/' + n.id).then(function(rsp) {
                angular.extend(n, rsp.data);
            });
        }

    };

    return svc;
}]);

// TODO - could be moved to staff
module.factory('importBatchService', ["$http", "$q", "userService", function($http, $q, userService) {

    var svc = {
        list: [],
        total_count: 0,
        start: 0,
        count: 20,
        sort: {
            field: 'upload_timestamp',
            reverse: true,
        }
    };

    svc.getList = function() {
        var deferred = $q.defer();
        var id = userService.id;
        if (id > 0) {
            $http.get('/api/import-batch'
                + '?view=list'
                + '&start=' + svc.start
                + '&count=' + svc.count
                + '&sort=' + encodeURIComponent(svc.sort.field)
                + '&dir=' + (svc.sort.reverse ? 'DESC' : 'ASC')
            ).then(function(resp) {
                svc.total_count = resp.data.total_count;
                svc.list.length = 0;
                resp.data.data.forEach(function(rec) {
                    svc.list.push(rec);
                });

                svc.pager = new KOHA.Pager({numResults: resp.data.total_count, offset: 0});
                deferred.resolve(svc.list);
            }, function(e) {
                deferred.reject(e);
            });
        }
        else {
            deferred.reject("No user");
        }
        return deferred.promise;
    };

    svc.toPage = function(page) {
        svc.start = (page-1) * svc.count;
        svc.getList();
    };

    svc.filter = function(batch, ids, async) {
        var deferred = $q.defer();
        
        $http.post("/api/import-batch/" + batch.import_batch_id,
            $.param({
                op: 'filter',
                filter_ids: ids.join(','),
                async: async
                }),
            {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}}
        ).then(function() {
            deferred.resolve(1);
        }, function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };


/*    svc.remove = function(n) {
        var deferred = $q.defer();
        $http.delete('/api/async-job/' + n.id).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };

    svc.suspend = function(n) {
        var deferred = $q.defer();
        $http.post("/api/async-job/" + n.id, $.param({op: 'suspend'}),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}}).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };

    svc.resume = function(n) {
        var deferred = $q.defer();
        $http.post("/api/async-job/" + n.id, $.param({op: 'resume'}),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}}).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };


    svc.update = function(n) {
        var deferred = $q.defer();
        $http.put('/api/async-job/' + n.id, JSON.stringify(n)).then(function() {
            deferred.resolve(1);
        }, function() {
            deferred.reject();
            svc.reload(n);
        });
        return deferred.promise;
    };

    svc.get = function(id) {
        var deferred = $q.defer();
        $http.get('/api/async-job/' + id).then(function(rsp) {
            deferred.resolve(rsp.data);
        }, function(err) {
            deferred.reject(err);
        });
        return deferred.promise;
    };

    svc.reload = function(n) {
        if (id) {
            $http.get('/api/async-job/' + id).then(function(rsp) {
                angular.extend(n, rsp.data);
            });
        }

    };*/

    return svc;
}]);

// TODO - could be moved to staff
module.factory('bertService', ["$http", "$q", "userService", function($http, $q, userService) {

    var svc = {
        list: [],
    };

    svc.getList = function() {
        var deferred = $q.defer();
        var id = userService.id;
        if (id > 0) {
            $http.get('/api/bert?view=list').then(function(resp) {
                svc.list.length = 0;
                resp.data.forEach(function(rec) {
                    svc.list.push(rec);
                });
                deferred.resolve(svc.list);
            }, function(data) {
                deferred.reject(data);
            });
        }
        else {
            deferred.reject("No user");
        }
        return deferred.promise;
    };

    return svc;
}]);

module.factory('Pager', function(){
    // Pager class to track ranges.
    // Fits with uib's pagination, which updates 'page' via ng-model.
    return function(p){
        if(!('count' in p)) throw new Error("Pager requires count");
        this.count = p.count;
        this.pagelength = p.pagelength || 20;
        this.page = p.page || 1;
        this.offset = function(){
            return this.pagelength * (this.page - 1);
        };
        this.rangeEnd = function(){
            return Math.min( this.count, this.offset() + this.pagelength);
        };
        this.numPages = function(){
            return Math.ceil(this.count||0 / this.pagelength);
        };
    };
});

module.factory('SearchQuery', ["userService", "$state", "configService", "$q", "$http", "bibService", "SearchResults", "kwLuceneParser", function(userService, $state, configService,
                $q, $http,  bibService, SearchResults, kwLuceneParser){


    function SearchQuery(param){
        // query can be a string, as 'field:query'.
        // limits is an object of { field:value(s)}.
        // for now, all multivalued fields are assumed to be OR'ed.

        if(!param) param = {};
        if(param.fromState){
            // fromState may be $transition$ obj.
            var sparams = ( param.fromState.params ) ? param.fromState.params() : $state.params;

            this.q = sparams.query || '*:*';
            this.limitfields = {};
            for( var p in sparams ){
                if( (p=='fq' || configService.searchFields[p]) && sparams[p] !== undefined ){
                    this.limitfields[p] = angular.copy(sparams[p]);
                }
            }
            param.page = sparams.page;
            param.sort = sparams.sort;
            if(sparams.op) param.op = sparams.op;

        } else if(typeof param.stateParams == "function" ){ // i.e. another SearchQuery
            this.q = param.q;
            this.sort = param.sort;
            this.page = param.page;
            this.limitfields = angular.copy(param.limitfields);
        } else {
            this.q = param.q || '*:*';
            this.limitfields = angular.copy(param.limits) || {};  // limitfields can hold 'fq' keyed values which are passed as is.
        }

        this.staticlimits = {};  // permissions-dependent, cannot be changed by user.
        this.sort = param.sort || null;
        this.page = param.page || 1;
        this.numPerPage = param.numPerPage || configService.OPACnumSearchResults;
        this.status = { error: false, searching: false, done: false };
        this.op = param.op; // Note server-side default is AND.

        // lost, deleted && suppression
        if(userService.is_staff){
            if(!this.limitfields.suppress || this.limitfields.suppress=='0')
                    this.staticlimits.suppress = '[0 TO 1]';
            this.staticlimits.lost = '*';
        } else {
            //for non staff users, we use hidelostitems syspref to decide if we want 
            // to display a bib that only has lost items
            if(!KOHA.config.hidelostitems){
                this.staticlimits.lost = '*';
            }
        }

        // sort and page may be in limits, which just come from url params, so extract them.
        if('sort' in this.limitfields){
            this.sort = this.limitfields.sort;
            delete this.limitfields.sort;
        } else {
            if(!this.sort && configService.OPACdefaultSortField){
                this.sort = configService.OPACdefaultSortField + ' ' + (configService.OPACdefaultSortOrder || 'desc');
            } else if(this.q=='*:*' && (this.sort === 'score desc' || !this.sort)){
                this.sort = 'acqdate desc';
            }
        }
        if('page' in this.limitfields){
            this.page = this.limitfields.page;
            delete this.limitfields.page;
        }

        for(var field in this.limitfields){
            if(!$.isArray(this.limitfields[field])) this.limitfields[field] = [this.limitfields[field]];
        }

        this.stateParams = function(newLimits){

            var qParams = { query: this.q };
            for ( var param in this.limitfields ) {
                if( this.limitfields[param] !== undefined )
                    qParams[param] = this.limitfields[param];
            }
            if(this.op) qParams.op = this.op;
            if(this.page != 1)
                qParams.page = this.page;
            if(this.q == '*:*' && (this.sort === 'score desc' || !this.sort ))
                qParams.sort = 'acqdate desc';
            else
                qParams.sort = this.sort;

            return qParams;

        };

        this.asUrl = function() {
            return $state.href( 'search-results.koha', this.stateParams(), { inherit: false });
        }

        this.addLimit = function( fieldOrObj, value ){
            // addLimit( { itemtype: 'BOOK' })  or addLimit( 'itemtype', 'BOOK' );

            var newLimits = {};
            if( typeof fieldOrObj == 'object' ){
                angular.copy( fieldOrObj, newLimits );
            } else {
                newLimits[fieldOrObj] = value;
            }

            angular.forEach( newLimits, function( v, f ){
                if( v != '' ){
                    // Note multiple limit values will simply be passed as repeated query parameters.
                    var values = (Array.isArray(value) ? angular.copy(value) : [ v ]);
                    if(!this.limitfields[f]) this.limitfields[f] = [];
                    values.forEach( function(val){
                            if(this.limitfields[f].indexOf(val)==-1) this.limitfields[f].push(val);
                        }.bind(this));
                }
            }.bind(this));

            return this;
        };

        this.rmLimit = function(field, value){
            // remove a limit. pass value===null to remove all limits on that field.

            if(!jQuery.isArray(this.limitfields[field])) return;
            if(value===null){
                delete this.limitfields[field];
            } else {
                this.limitfields[field] = this.limitfields[field].filter(function(el){
                    return (el != value) ? true : false ;
                });
                if(this.limitfields[field].length === 0){
                    delete this.limitfields[field];
                }
            }
            return this;
        };
        this.hasLimits = function(){
            var keys = Object.keys(this.limitfields);
            // public users don't need to know about suppressed limits
            if (! userService.is_staff) {
                if (keys.length && keys.indexOf('suppress') > -1) {
                    keys.splice(keys.indexOf('suppress'), 1);
                }
            }
            return (keys.length) ? true : false;
        };
        this.hasLimit = function(field, value){
            return this.limitfields[field] && this.limitfields[field].indexOf(value) != -1;
        };

        this.fq = function(){
            // get fq parameters as an array.
            var fqArr = [];
            //fixme: need to sort so that query string will be the same irrespective of fq order.
            jQuery.each(this.limitfields, function(field, limitArr){
                            jQuery.each(limitArr, function(i, limitStr){

                                if(field === 'fq'){
                                    fqArr.push( limitStr );
                                } else {
                                    // limitStr is an array.

                                 if(limitStr.match(/^[(\[]/) || field == 'geo-shape'){
                                        fqArr.push( field+":"+limitStr);
                                    } else {
                                        fqArr.push( field+":("+limitStr+")");
                                    }
                                }
                            });
                        });
            jQuery.each(this.staticlimits, function(field, limitStr){
                fqArr.push( field + ':' + limitStr );
            });
            if (userService.merged_prefs && userService.merged_prefs.default_query_filter) {
                if ((typeof(userService.merged_prefs.use_default_filter) != 'boolean') || userService.merged_prefs.use_default_filter) {
                    fqArr.push(userService.merged_prefs.default_query_filter);
                }
            }
            // FIXME: should return [] ?
            return fqArr.length ? fqArr : null;
        };

        var defaultSolrParams = {
            'facet.field': configService.SearchFacets
        };

        if(defaultSolrParams['facet.field'].indexOf('lexile_i') > -1) {
            defaultSolrParams['facet.range'] = 'lexile_i';
            defaultSolrParams['f.lexile_i.facet.range.start'] = 0;
            defaultSolrParams['f.lexile_i.facet.range.end'] = 2000;
            defaultSolrParams['f.lexile_i.facet.range.gap'] = 100;
        }

        var pubyear_intervals = {};
        if(defaultSolrParams['facet.field'].indexOf('pubyear')>-1){
            // TODO: Add range facets into config.
            var thisyear = new Date().getFullYear();
            var decadeThreshold = Math.floor( (thisyear - 4) / 10) * 10;
            var interval = 0;
            var pubdateIntervals = [];

            for (var y = thisyear; y > 1400 ; y-=interval) {
                if(y==thisyear) pubdateIntervals.push( '['+y+','+y+']');
                if(y > decadeThreshold){
                    if(y-decadeThreshold >= interval*2+2 ){
                        if(interval < 3) interval++;
                    } else {
                        if(y-decadeThreshold < interval*2) interval = y-decadeThreshold;
                    }
                } else if(y > 1900){
                    interval = 10;
                } else {
                    interval = 100;
                }
                pubdateIntervals.push( '['+(y-interval)+','+y+')');
            }
            defaultSolrParams['f.pubyear.facet.sort'] ='index';  // this isn't working if pubyear_intervals isn't set.
            pubyear_intervals['facet.interval'] = 'pubyear';
            pubyear_intervals['f.pubyear.facet.interval.set'] = pubdateIntervals;
        }

        this.solrParams = function() {

            var self = this;
            var solrParams = angular.copy(defaultSolrParams);

            var lexidx = solrParams['facet.field'].indexOf('lexile_i');
            if (lexidx > -1) {
                solrParams['facet.field'].splice(lexidx, 1)
            }

            if((this.limitfields.pubyear||[''])[0].match(' TO ')){
                solrParams['f.pubyear.facet.limit'] = 100; // so we can sort reverse chrono.
            } else {
                angular.extend(solrParams, pubyear_intervals);
            }
            // FIXME: allow skipping facet requests.
            if(this.fq()){
                solrParams.fq = this.fq();
                if (userService.acl_fq) {
                    solrParams.fq.push(userService.acl_fq);
                }
            }
            else if (userService.acl_fq) {
                solrParams.fq = [userService.acl_fq];
            }
            if(this.sort && this.sort != 'score desc'){
                solrParams.sort = this.sort;
            }
            if(this.op && this.op=='OR'){
                solrParams['q.op'] = this.op;
                solrParams.mm = 1; // if default mm is set server-side, q.op doesn't work.
            }
            var headers = {};

            var endrange = this.page * this.numPerPage - 1;
            var startrange = (this.page-1)*this.numPerPage;
            headers.Range = 'records='+ startrange +'-'+endrange;
            // Duplicate range data so the browser will cache, and some folks have misbehaving proxies.
            solrParams.start = startrange;
            solrParams.count = this.numPerPage;

            if(configService.OPACSearchSuggestionsCount){
                solrParams.spellcheck=true;
                solrParams['spellcheck.count'] = configService.OPACSearchSuggestionsCount * 2;
                solrParams['spellcheck.maxCollations'] = configService.OPACSearchSuggestionsCount * 2;
                solrParams['spellcheck.collate'] = true;
            }
            var embedResults = parseInt(configService.search.embeddedResults,10);
            if(!isNaN(embedResults)) solrParams.embed = embedResults;

            return {headers: headers, params: solrParams};
        };

        this.equals = function(otherSearch){
            if(otherSearch && otherSearch.stateParams){
                return angular.equals( this.stateParams(), otherSearch.stateParams());
            }
        };

        var resultsDeferred = $q.defer();
        this.whenResults = function(){
            return resultsDeferred.promise;
        };

        this.fetch = function(options){
            var opt = { facet: true };
            angular.extend(opt, options);

            // returns a promise resolving to a SearchResults.

            this.status.done = false;
            this.status.searching = true;

            var self = this;

            userService.setLastExecutedSearch([
                this.q,
                angular.copy(this.solrParams().params)
            ]);

            $http.get("/api/opac/"+encodeURIComponent(this.q), this.solrParams())
                .then(function(rsp){
                            rsp.data._resultsPerPage = self.numPerPage;
                            self.results = new SearchResults(rsp.data, self);
                            if(self.results.spellcheck.length > configService.OPACSearchSuggestionsCount){
                                // FIXME: this isn't really appropriate here.
                                self.results.spellcheck = self.results.spellcheck.slice(0,configService.OPACSearchSuggestionsCount);
                            }
                            self.status.error = false;
                            self.status.searching = false;
                            self.status.done = true;

                            rsp.data.hits.forEach(function(result){
                                if(result._embed && 'marc' in result._embed){
                                    bibService.put(result._embed);
                                }
                            });
                            resultsDeferred.resolve(self.results);
                }, function(e){
                    console.warn(e);
                            self.status.error = true;
                            self.status.done = true;
                            self.status.searching = false;
                            resultsDeferred.reject(e);
                });
            return resultsDeferred.promise;
        };
        this.testFQ = function(fqArr){
            return angular.equals(this.solrParams().params.fq, fqArr);
        };
        var _itemFacetConstraints;
        // this.makeItemConstraints = function() {
        this.itemFacetConstraints = function() {
            if(_itemFacetConstraints) return _itemFacetConstraints;
            var fq = this.solrParams().params.fq;
            if (fq === undefined) { return {} }

            var item_constraints = {};
            fq.forEach( function (s) {
                try {
                    var key = s.split(':')[0];
                    var value_list = s.split(':')[1].split('(')[1].split(')')[0];
                    var values = value_list.split(' OR ');
                    var r = /"/g;
                    values = values.map(function (s) {return s.replace(r, '')});
                    item_constraints[key] = values;
                } catch (e) {
                    ;
                }
            });
            _itemFacetConstraints = item_constraints;
            return _itemFacetConstraints;
        };
        this.moreFacets = function(field, start, count, sortby){
            if (!sortby) sortby = 'count';
            if(!count) count = 20;
            if(!start) start = 0;

            var solrParams = {
                'facet.field' : field,
                'facet.limit' : count,
                'facet.sort' : sortby,
                'facet.offset': start,
                rows : 0,
            };

            if(this.fq()){
                solrParams.fq = this.fq();
                if (userService.acl_fq) {
                    solrParams.fq.push(userService.acl_fq);
                }
            }
            else if (userService.acl_fq) {
                solrParams.fq = [userService.acl_fq];
            }
            // TODO: Cache me.
            return $http.get("/api/opac/"+encodeURIComponent(this.q), { "params" : solrParams })
                .then(function(rsp){
                    var facet = rsp.data.facets[0];
                    facet.values.forEach(function(facetval){
                        if(this.hasLimit(facetval.field,facetval.value)) facetval.applied = true;
                    }, this);
                    return facet;
                }.bind(this), function(err){
                    console.log(err);
                    return $q.reject(err);
                });

        };
        this.go = function(){
            $state.go('search-results.koha', this.stateParams(), { inherit: false } );
        };
        this.toPage = function(pagenum){
            // call without param to use this search's results pager object.
            if(pagenum === undefined && (this.results||{}).pager) pagenum = this.results.pager.page;
            var newSearch = this.clone();
            newSearch.page = pagenum;
            newSearch.go();
        };

        this.reSort = function(sortby){
            var newSearch = this.clone();
            newSearch.sort = sortby;
            newSearch.go();
        };
        this.rawTerms = function(){
            return kwLuceneParser.extractTerms( kwLuceneParser.parse(this.q));
        }
    }
    SearchQuery.prototype.clone = function(){
        var clone = new SearchQuery(this);
        clone.page = 1;
        return clone;
    }

    return SearchQuery;
}])
.factory('SearchResults', function(){
    return function(solrData, searchQuery){
        this.bibs = solrData.hits.map(function(t){
                return { id: parseInt(t.work.substr(10),10),
                    score: parseFloat(t.score).toFixed(3),
                    title: (t._embed) ? null : t.title  // supply title only if embedded bib isn't present.
                } ;
            });
                // FIXME: substr from uri is fragile way to get id.
        this.hits = solrData.total_hits;
        this.start = solrData.start;
        this.end   = solrData.start + solrData.hits.length - 1;

        this.pager = new KOHA.Pager({numResults: this.hits, offset: this.start, numPerPage: solrData._resultsPerPage});
        this.facets = solrData.facets || {};
        this.spellcheck = solrData.spellcheck || [];
        this.has_facets = Object.keys(solrData.facets).length >=1;
        this.facets.forEach(function(facetField){
            facetField.values.forEach(function(facet){
                if(searchQuery.hasLimit(facet.field, facet.value)) facet.applied = true;
            });
            if(facetField.field=='pubyear' && !facetField.interval){
                facetField.values.reverse();
            }
        });
        this.selectedBibs = {};
        this.setSelected = function(bibid){
            if(bibid)
                this.selectedBibs[bibid] = true;
            else
                this.bibs.forEach(function(bib){ this.selectedBibs[bib.id] = true; }.bind(this));
        };
        this.clearSelected = function(bibid){
                if(bibid){
                    delete this.selectedBibs[bibid];
                } else {
                    Object.keys(this.selectedBibs).forEach(function(k){ delete this.selectedBibs[k]; }.bind(this));
                }
        };

        this.isFirst = function(bibid){
            return(this.pager && !this.pager.offset && this.bibs[0].id===parseInt(bibid,10));
        };
        this.isLast = function(bibid){
            return (this.isLastOnPage(bibid) && this.pager.page == this.pager.numPages);
        };
        this.isFirstOnPage = function(bibid){
            return(this.bibs[0].id===parseInt(bibid,10));
        };
        this.isLastOnPage = function(bibid){
            return this.bibs[this.bibs.length-1].id===parseInt(bibid,10);
        };
        this.step = function(bibid, dir){
            //returns next/previous bib in result set.
            var index;
            for(var i = 0; i < this.bibs.length; i++){
                if(parseInt(bibid,10) == this.bibs[i].id) index = i;
            }
            if(index===undefined || (index==this.bibs.length && dir>0) || (index===0 && dir<0)){
                return undefined;
            }
            return this.bibs[index+dir].id;

        };
        this.contains = function(bibid){
            // returns true if this results set contains bibid.
            for (var i = 0; i < this.bibs.length; i++) {
                if(this.bibs[i].id == bibid) return true;
            }
            return false;
        };

    };
});

module.factory('awLogService', ["$uibModal", function($uibModal){
        var svc = {};
        svc.startModal = function(){
            //ptfs
            var bb =             $uibModal.open({
                    templateUrl: '/app/static/partials/awLogView-modal.html',
                    controller: 'AWLogViewerCtrl',
                    windowClass: "modal",
                    backdrop: 'static',
                    resolve: {
                        logID : null
                    }
            });
        
        }
        
        return svc;
    }]);

module.factory('awDailyExportService', ["$uibModal", function($uibModal){
        var svc = {};
        svc.startModal = function(q_str){
console.log(q_str);
            var bb = $uibModal.open({
                    templateUrl: '/app/static/partials/dailyExport-modal.html',
                    controller: 'AWDailyExportCtrl',
                    windowClass: "modal dailyExportModalShell",
                    backdrop: 'static',
                    resolve: {
                        q_str: function () {
                            return q_str;
                        }
                    }
            });
        
        }
        
        return svc;
    }]);

module.provider('modalState', ["$stateProvider", function($stateProvider) {
    var provider = this;
    this.$get = function() {
        return provider;
    }
    this.state = function(stateName, options) {
        var modalInstance;
        $stateProvider.state(stateName, {
            url: options.url,
            onEnter: ["$uibModal", "$state", function($uibModal, $state) {
                modalInstance = $uibModal.open(options);
                modalInstance.result['finally'](function() {
                    modalInstance = null;
                    if ($state.$current.name === stateName) {
                        // FIXME - what to do about nested modals? See app.js run section
                        $state.go($state.previous);
                    }
                });
            }],
            onExit: function() {
                if (modalInstance) {
                    modalInstance.close();
                }
            }
        });
    }
}]);

module.factory('marcTemplateService', ["$http", "$q", "userService", function($http, $q, userService) {

    var svc = {
        list: [],
    };

    svc.getList = function() {
        var deferred = $q.defer();
        var id = userService.id;
        if (id > 0) {
            $http.get('/api/marctemplate/').then(function(resp) {
                svc.list.length = 0;
                resp.data.forEach(function(rec) {
                    svc.list.push(rec);
                });
                deferred.resolve(svc.list);
            }, function(data) {
                deferred.reject(data);
            });
        }
        else {
            deferred.reject("No user");
        }
        return deferred.promise;
    };

    return svc;
}]);

module.service('modalForm', ["$uibModal", "$q", "$http", "$templateCache", function($uibModal, $q, $http, $templateCache) {
    this.open = function(formConfig) {
        var templatePromise;

        var saveText = formConfig.saveText || 'Save';
        var config = angular.extend({
            header:     '<div class="modal-header">' +
                        '<button type="button" class="close" ng-click="$close()">&times;</button>' +
                        '<h3>{{ title }}</h3>' +
                        '</div>' + 
                        '<div class="modal-body"><form name="form" class="css-form" novalidate>\n',
            footer:     '</form></div>\n' +
                        '<div class="modal-footer">' +
                            '<button class="btn btn-small" ng-click="close(formdata)" ng-disabled="form.$invalid"><i class="icon-ok"></i>' + saveText + '</button>' +
                            '<button class="btn btn-small" ng-click="close()"><i class="icon-cancel"></i>Cancel</button>' + 
                        '</div>',
//            wrapper:    '<div class="xmodal-draggable" draggable centered=parent">'
        }, formConfig);
        
        var header = '', footer = '';
        if (config.wrapper) {
            header =  config.wrapper;
            footer = '</div>';
        }

        header = header + config.header;
        footer = config.footer + footer;

        if (config.templateUrl) {
            templatePromise = $http.get(config.templateUrl, {cache: $templateCache})
                .then(function(response) {
                    var s = header + response.data + footer;
                    return s;
                });
        }
        else {
            templatePromise = header + config.template + footer;
        }

        var deferred = $q.defer();
        
        var modalInstance = $uibModal.open({
            template: templatePromise,
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            controller: ["$scope", "model", "$uibModalInstance", function($scope, model, $uibModalInstance) {
                $scope.title = model.title;
                $scope.message = model.message;
                $scope.buttons = model.buttons;
                $scope.formdata = model.formdata;

                if (model.link) {
                    model.link($scope);
                }
                $scope.close = function(res){
                    $uibModalInstance.close(res);
                };
                console.log("Controller built");
            }],

            backdrop:       false, //'static',
            backdropClick:  false,
            scope:          config.scope,
            resolve: {
                model: function() { return config; }
            },
            size: config.size
        });

        modalInstance.result.then(function(result) {
            if (!result) {
                deferred.reject(result);
            }
            else {
                deferred.resolve(result);
            }
        });

        return deferred.promise;
    };
}]);

module.service('kwNoveListSvc', ["configService", "$q", "$http", function(configService, $q, $http){

    if (!configService.NoveListConfig) return {};
// http://novselect.ebscohost.com/Data/ContentByQuery?profile=some_profile_here&password=some_password_here
//    &ClientIdentifier=SomethingMeaningful&UPC=9780441008537&version=2.3

    var noveListApiHost = '//novselect.ebscohost.com/Data/ContentByQuery';
    var noveListParams = {
                version : '2.3',
                profile : configService.NoveListConfig.profile,
                password : configService.NoveListConfig.password
            };

    var contentTypes = this.contentTypes = {
        seriesTitles: {
            backlink: 'isbn',
            objpath: [ 'SeriesInfo', 'series_titles' ]  // jsonpath map to novelist api data.
        },
        similarTitles: {
            backlink: 'isbn',
            objpath: [ 'SimilarTitles', 'titles' ]
        },
        similarSeries: {
            backlink: false,
            objpath: [ 'SimilarSeries', 'series' ]
        },
        goodReads: {
            backlink: false,
            objpath: [ 'GoodReads' ]
        },
        similarAuthors: {
            backlink: false,
            objpath: [ 'SimilarAuthors', 'authors' ]
            },  // we should probably filter by author search here.
        relatedContent: {
            backlink: false,
            objpath: [ 'RelatedContent', 'doc_types' ]
        },
        appeals: {
            objpath: ['Appeals']
        },
        lexile: {
            objpath: ['LexileInfo']
        }
    };

// todo: search by appeals:
//https://novselect.ebscohost.com/api/appeals/ByTerm?profile=s9035634.main.novsel&password=dGJyMOPY8VGxq7YA&appeal=Fast-paced&readingLevel=Teen&isbnToExclude=9780439023481&format=fiction&author=Collins,%20Suzanne&genres=Science%20fiction,Dystopian%20fiction,First%20person%20narratives,Books%20to%20movies,Books%20for%20reluctant%20readers,&callback=novSelect.appealsSearchClbk

    var kohaBibs = {}; // isbn lookup cache for backlinks to local catalog.
    var noveListContentByKohaIsbn = {}; // cache all novelist content.
                            // TODO: limit cache size.

    var findPrimaryIsbnFromManifestation = function(kohaISBNs, novelistISBNs){
        for (var i = 0; i < kohaISBNs.length; i++) {
            for ( var primary_isbn in novelistISBNs ){
                if( novelistISBNs[primary_isbn].indexOf(kohaISBNs[i])>-1 ){
                    // console.log('matched: ' + kohaISBNs[i] + ' -> ' + primary_isbn);
                    return primary_isbn;
                }
            }
        }
    };


    var findBacklinks = function(novelistTitles){
        var novelistISBNs = {};
        var promises = [];

        (novelistTitles||[]).forEach(function(novTitle){
            novelistISBNs[novTitle.primary_isbn] =
                        novTitle.manifestations.map(function(mani){ return mani.ISBN; });
        });

        var searchList = [];  // {isbn,promise}
        // assume primary_isbn is included in manifestations array //

        var solrParams = { facet: false };
        var solrQuery = [];
        for ( var primary_isbn in novelistISBNs ){
            if(primary_isbn in kohaBibs){
                // already searched.
                searchList.push( { isbn: primary_isbn, promise: kohaBibs[primary_isbn].promise });
                continue;
            }
            kohaBibs[primary_isbn] = $q.defer();
            searchList.push( { isbn: primary_isbn, promise: kohaBibs[primary_isbn].promise });

            solrQuery.push( novelistISBNs[primary_isbn].reduce(function(qsofar, isbn, i){
                    qsofar += 'isbn:"' + isbn + '"';
                    if(isbn==primary_isbn) qsofar += '^2';  //boost
                    if(i<novelistISBNs[primary_isbn].length-1) qsofar += " OR ";
                    return qsofar;
                }, '')
            );
        }

        if(solrQuery.length){

            var q = solrQuery.join(' OR ');

            var params = {
                op: 'big-query',
                query: q,
                defType: 'lucene',
                facet: false,
                embed: 0,
                rows: 100
            };
            $http.post("/api/opac/", $.param(params),
                {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                .then(function(rsp){
                    var matched = {};  // take first bibid for each.
                    rsp.data.hits.forEach(function(bib){
                        // bib.isbn is array.
                        var primary_isbn = findPrimaryIsbnFromManifestation(bib.isbn, novelistISBNs);
                        if( !primary_isbn ){
                            console.warn('ISBN MISMATCH. '  + bib.id + ' / ' + bib.isbn);
                            return;
                        }
                        if(!matched[primary_isbn]){
                            matched[primary_isbn] = true;
                            kohaBibs[primary_isbn].resolve(bib.id);
                        }
                    });
                    Object.keys(novelistISBNs).forEach(function(primary_isbn){
                        if(!matched[primary_isbn]){
                            kohaBibs[primary_isbn].resolve(undefined);
                        }
                    });
                }, function(failure){
                    console.warn('Backlink failure in solr search');
                    console.warn(failure);
                    Object.keys(novelistISBNs).forEach(function(primary_isbn){
                            kohaBibs[primary_isbn].resolve(undefined);
                    });
                });
        }
        return $q.all(searchList.map(function(l){return l.promise;}))
                .then(function(bibid_array){
                    var isbn_to_bibid = {};
                    for (var i = 0; i < bibid_array.length; i++) {
                        if(bibid_array[i]){
                            isbn_to_bibid[searchList[i].isbn] = bibid_array[i];
                        }
                    }
                    return isbn_to_bibid;
                 });
    };
    // backlinks novelist data to local catalog.
    // koha bibids are held in kohaBibs, a hash of deferreds
    // with a mapping of novelist manifestation isbns stashed in it.


    var processContent = function(data){
        var retval = {};

        var nContent = data.FeatureContent||{};
        var kohaIsbn = data.ClientIdentifier;
        var contentQs = noveListContentByKohaIsbn[kohaIsbn];
        if(!contentQs)
            throw 'No deferreds.';

        var toBacklink = [];
        var propFromPath = function(obj,path){ return path.reduce(function(o,i){ return o[i]; }, obj);};
        var ourContent;
        for(var contentType in contentTypes){
            ourContent = null;  // hoisted.
            try{
                ourContent = propFromPath(nContent, contentTypes[contentType].objpath);
            } catch (e) {
                console.warn('no content for ' + contentType);
            }
            if(contentTypes[contentType].backlink){
                contentQs[contentType].stashUnlinked(ourContent);
                if(ourContent) toBacklink = toBacklink.concat(ourContent);
            } else {
                contentQs[contentType].resolve(ourContent);
            }
        }
        // fixme: we should only initiate backlinking if this specific content type is requested.
        if(toBacklink.length){
            findBacklinks(toBacklink);
        }
    };

    this.on = configService.NoveListConfig.profile;

    var BacklinkedTitles = function(contentType){ // contentType shouldn't be needed now. (but might want to filter / reorder).
        // this.contentType = contentType;
        var linked = $q.defer();
        var unlinked = $q.defer();
        this.backlinked = false;
        this.ensureBacklinked = function(){

            if(this.backlinked) return linked.promise;
            this.backlinked = true;
            return unlinked.promise.then(function(unlinkedTitles){
                    var resolvedTitles = [];
                    return findBacklinks(unlinkedTitles).then(function(isbn_to_bibid){
                        (unlinkedTitles||[]).forEach(function(title){
                            // if(contentType=='similarTitles' && !isbn_to_bibid[title.primary_isbn]){
                            //     // we filter similarTitles against locally held.
                            //     return;
                            // }
                            var linkedTitle = angular.copy(title);
                            if(isbn_to_bibid[title.primary_isbn]){
                                linkedTitle.kohaBibid = isbn_to_bibid[title.primary_isbn];
                                linkedTitle.workUri = '/app/work/' + isbn_to_bibid[title.primary_isbn];
                            }
                            resolvedTitles.push(linkedTitle);
                        });
                        var resolved = (resolvedTitles.length) ? resolvedTitles : null;
                        linked.resolve(resolved);
                        return resolved;
                    });
                });
        };
        this.stashUnlinked = unlinked.resolve; // triggers backlinking.
        this.promise = linked.promise;

    };
    var NoveListContent = function(koha_isbn){
        this.koha_isbn = koha_isbn;
        for(var cType in contentTypes){
            this[cType] = contentTypes[cType].backlink ?
                new BacklinkedTitles() : $q.defer() ;
        }
    };

    this.fetchIsbns = function( isbn ){
        if(angular.isArray(isbn)) throw('multiple isbns not really implemented.');
        var requested_isbns = (angular.isArray(isbn)) ? isbn : [isbn];
        var fetchlist = [];

        requested_isbns.forEach(function(isbn){
                    if( ! ( isbn in noveListContentByKohaIsbn ) ){
                        fetchlist.push(isbn);
                        noveListContentByKohaIsbn[isbn] = new NoveListContent(isbn);
                    }
                 }, this);
        if(!fetchlist.length) return $q.when();

        var clientId = (fetchlist.length > 1) ? 'Batch' : fetchlist[0];
        var params = { ISBN: fetchlist, ClientIdentifier: clientId };

        return $http.get( noveListApiHost, {
            params: angular.extend( params, noveListParams ),
            cache: true,
            }).then(function(rsp){
                var data;
                if(rsp.data.FeatureContent){
                    data = [ rsp.data ];
                } else if(rsp.data.titles){  // FIXME: UNimplemented !
                    data = rsp.data.titles; // .map(function(d){ return d.FeatureContent; });
                }
                data.forEach(function(novelistContent){
                    processContent(novelistContent);
                } );

            } );
    };

    this.getContent = function(isbn, contentType){
        // returns promises for each content type,
        // or a hash of such promises if no contentType specified.

        if(!( isbn in noveListContentByKohaIsbn ))
            this.fetchIsbns(isbn);

        if(contentType){
            if ( this.contentTypes[contentType].backlink )
                return noveListContentByKohaIsbn[isbn][contentType].ensureBacklinked().promise;
            else
                return noveListContentByKohaIsbn[isbn][contentType].promise;
        } else {
            var allContent = {};
            for (var cType in this.contentTypes) {
                allContent[cType] = noveListContentByKohaIsbn[isbn][cType].promise;
                if(this.contentTypes[cType].backlink) noveListContentByKohaIsbn[isbn][cType].ensureBacklinked();  // this already happens
            }
            return allContent;
        }
    };
}]);


module.service('loading', ["$rootScope", "$q", "alertService", function($rootScope, $q, alertService) {
    //this.pendingCount = 1;        // if we decide to start with a spinner and resolve in KohaCtrl...
    this.globalCount = 0;
    this.activeKeys = {};

    this.add = function(key) {
        if (key)
            this.activeKeys[key] = 1;
        else
            this.globalCount++;

        //$('#loading').show();
        //$rootScope.loading = true;
        // XXX why are broadcasting through the entire scope tree?
        $rootScope.$broadcast('loadingAdd');
    };


    this.resolve = function(key) {
        if (key)
            delete this.activeKeys[key];
        else
            this.globalCount--;

        if ((this.globalCount <= 0) && (Object.keys(this.activeKeys).length == 0)) {
            this.globalCount = 0;
            //$('#loading').hide();
            //$rootScope.loading = false;
            $rootScope.$broadcast('loadingResolve');

        }
    };

    this.wrap = function(p, s) {
        var svc = this;
        svc.add('apiwrap');
        return p.then(function(succ) {
            svc.resolve('apiwrap');
            return succ;
        }, function(err) {
            svc.resolve('apiwrap');
            alertService.addApiError(err,(s || 'Error'));
            return $q.reject(err);
        });
    };

}]);


module.service('Logger', ["$http", function ($http) { // See logClickEvent directive
    return {
        logEvent: function(url) {
            var errorCB = function(e) { console.error(e); };
            $http.get(url).then(undefined, errorCB);
        }
    };
}]);

// TODO: Roll whatever is missing from this into kwDlgSvc as this seems to duplicate functionality.
module.service('messageBox', ["$uibModal", "$q", function($uibModal,$q) {
    this.YESNO = [{result: 1, label: 'Yes'}, {result: undefined, label: 'No'}];
    this.YESNOCANCEL = [{result: 1, label: 'Yes'}, {result: 0, label: 'No'}, {result: undefined, label: 'Cancel'}];

    this.open = function(config) {
        var modalInst = $uibModal.open({
            template:    
                "<div class=\"modal-header\">\n" +
                "   <h3>{{ title }}</h3>\n" +
                "</div>\n" +
                "<div class=\"modal-body modal-alert\">\n" +
                "   <span>{{ message }}</span>\n" +
                "</div>\n" +
                "<div class=\"modal-footer\">\n" +
                "   <a ng-repeat=\"btn in buttons\" ng-click=\"close(btn.result)\" class=\"btn btn-primary\">{{ btn.label }}</a>\n" +
                "</div>\n" ,

            dialogClass:    'modal',
            backdropClass:  'modal-backdrop',
            controller: ["$scope", "model", "$uibModalInstance", function($scope, model, $uibModalInstance) {
                $scope.title = model.title;
                $scope.message = model.message;
                $scope.buttons = model.buttons;
                $scope.close = function(res){
                    $uibModalInstance.close(res);
                };
            }],
            backdrop:       'static',
            backdropClick:  false,
            resolve: {
                model: function() { return config; }
            }
        });
        //Log.ger.dir(modalInst);
        
        return modalInst.result;
    };

    // Returns a promise which is rejected if the user clicks a button with a undefined value.
    this.confirm = function(config) {
        config = angular.extend({}, {buttons: this.YESNO}, config);

        var deferred = $q.defer();
        this.open(config).then(function(result) {
            if (typeof(result) === 'undefined') {
                deferred.reject(result);
            }
            else {
                deferred.resolve(result);
            }
        });
        return deferred.promise;
    };

    // Convenient ways skip a confirmation in a promise chain
    this.resolve = function(val) {
        var deferred = $q.defer();
        deferred.resolve(val);
        return deferred.promise;
    };

    this.reject = function(val) {
        var deferred = $q.defer();
        deferred.reject(val);
        return deferred.promise;
    };
}]);

module.factory('kwFileUploadSvc', ["Upload", "$q", "$uibModal", "$http", "alertService", function( Upload, $q, $uibModal, $http, alertService) {
    var svc = {};

    svc.upload = function(config) {
        var hasFormdata = (config.formdata ? true : false);
        config = angular.extend({
            title: 'Upload File',
            description: 'Select a file to upload',
            formdata: {},
            inputs: [],
            fileRequired: true,
            uploadButtonText: 'Upload',
        }, config);

        if (hasFormdata) {
            angular.forEach(config.inputs, function(v) {
                v.value = config.formdata[v.name];
            });
        }

        
        var deferred = $q.defer();
        var modalInstance = $uibModal.open({
            templateUrl: '/app/static/partials/components/kw-file-upload-modal.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdrop: false,
            backdropClick: false,
            resolve: {
                config: function() {
                    return config;
                }
            },
            controller: ["$scope", "config", "$uibModalInstance", function($scope, config, $uibModalInstance) {
                $scope.config = config;
                $scope.errors = [];
                $scope.progress = {
                    percent: 0,
                    loaded: 0,
                    total: 0
                };
                $scope.upload = function() {
                    angular.forEach(config.inputs, function(v) {
                        config.formdata[v.name] = v.value;
                    });

                    var postdata = {};
                    if (config.json) {
                        postdata.file = config.formdata.file;
                        var encoded = {};
                        angular.forEach(config.formdata, function(val, key) {
                            if (key !== 'file') {
                                encoded[key] = val;
                            }
                        });
                        postdata.json = JSON.stringify(encoded);
                    }
                    else {
                        postdata = config.formdata;
                    }


                    $scope.uploading = true;
                    $scope.errors = [];

                    var postPromise;
                    if (postdata.file) {
                        postPromise = Upload.upload({
                            url: config.url,
                            data: postdata
                        });
                    }
                    else {
                        postPromise = $http.post(config.url, $.param(postdata),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}});
                    }

                    postPromise.then(function(resp) {
                        $scope.uploading = false;
                        if (config.responseValidator) {
                            var rv = config.responseValidator(resp);
                            if (rv) {
                                $uibModalInstance.close(resp);
                            }
                        }
                        else {
                            $uibModalInstance.close(resp);
                        }
                    }, function(err) {
                        if (typeof(err) === 'object') {
                            err = err.data || err.statusText
                        }
                        var matches = err.match(/Upload Error (\[.+)/);
                        if (matches && matches.length == 2) {
                            var errs = JSON.parse(matches[1]);
                            $scope.errors = errs;
                        }
                        else {
                            alertService.add({msg: "Upload failed: " + err, type: "error"});
                        }
                        $scope.uploading = false;
                    }, function(evt) {
                        $scope.progress.loaded = evt.loaded;
                        $scope.progress.total = evt.total;
                        $scope.progress.percent = parseInt(100.0 * evt.loaded / evt.total);
                    });
                };

                if (config.link) {
                    config.link($scope);
                }
            }],

            size: config.size
        });

        modalInstance.result.then(function(result) {
            if (!result) {
                deferred.reject(result);
            }
            else {
                deferred.resolve(result);
            }
        });

        return deferred.promise;
    };
    return svc;
}]);
module.factory('kwImportFactorySvc', ["$q", "kwApi", "loading", "alertService", "configService", "kwFileUploadSvc", "$uibModal", function($q, kwApi, loading, alertService, configService, kwFileUploadSvc, $uibModal) {
    var svc = {};

    svc.executeScript = function(script, vendor, ledger) {
        if (!script.is_approved) {
            return $uibModal.open({
                templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-unapproved.html',
                resolve: {
                    tainted: ["kwApi", function(kwApi) {
                        return kwApi.ImportScript.get({id: script.id}).$promise.then(function(rv) {
                            var tainted = [];
                            rv._embed.scriptlets.forEach(function(s) {
                                if (s.tainted === 1 || s.tained === '1')
                                    tainted.push(s.id);
                            });
                            return tainted.join(' ');
                        });
                    }],
                },
                controller: ["$scope", "tainted", function($scope, tainted) {
                    $scope.tainted = tainted;
                }],
            });
        }

        loading.add();
        
        var scriptsPromise = kwApi.ImportScript.get({id: script.id}).$promise;
        var vendorsPromise = kwApi.Vendor.getList().$promise;
        var ledgersPromise;
        if (vendor) {
            ledgersPromise = kwApi.Vendor.getLedgers({id: vendor}).$promise;
        }
        else {
            ledgersPromise = $q.when(null);
        }

        $q.all([scriptsPromise, vendorsPromise, ledgersPromise]).then(function(promises) {
            var data = promises[0];
            var vendors = [];
            angular.forEach(promises[1], function(v) {
                vendors.push({display: v.branchname, value: v.branchcode});
            });
            var ledgers = [];
            if (promises[2] !== null) {
                angular.forEach(promises[2], function(ledger) {
                    ledgers.push({display: ledger.ledger_code, value: ledger.id});
                });
            }

            loading.resolve();
            var params = [];
            angular.forEach(data._embed.scriptlets, function(s) {
                angular.forEach(s.parameters, function(p) {
                    p.value = s.embed_parameter_values[p.name];
                    params.push(p);
                });
            });

            var isExport = (script.script_type.match('analysis') ? true : false);

            var description = script.script_type.match('marc') ?  'Choose a MARC batch to upload' :
                isExport ? 'You may optionally upload a batch of MARC records to be used as the starting point for analysis' :
                'Choose an Excel spreadsheet to upload. If your data is not in the first tab, specify the tab below';

            var inputs = [];
            var selectConfig = {
                maxItems: 1,
                valueField: 'id',
                labelField: 'name',
                create: true,
            };

            inputs.push({name: 'async', type: 'select', label: 'Run Mode', value: 0, values: [
                {display: 'Synchronous (wait for completion)', value: 0},
                {display: 'Asynchronous (run in background)', value: 1},
            ]});

            if (configService.infomart) {
                inputs.push({name: 'vendor', type: 'select', label: 'Vendor', values: vendors, value: vendor});
                inputs.push({name: 'ledger', type: 'select', label: 'Ledger', values: ledgers, value: ledger});
            }
            inputs.push({name: 'parameter_set', type: 'selectize', config: selectConfig, label: 'Parameter Set', values: []});

            if (isExport) {
                inputs.push({name: 'export_as', type: 'text', label: 'Export as filename'});
                inputs.push({name: 'save_run_log', type: 'select', label: 'Save Run Log', value: 0, values: [
                    {display: 'No', value: 0},
                    {display: 'Yes', value: 1},
                ]});
            }
            else {
                inputs.push({name: 'batch_create', type: 'select', label: 'Create Batch', value: 0, values: [
                    {display: 'No (test only)', value: 0},
                    {display: 'Yes (ignore errors)', value: 1},
                    {display: 'Yes (fail on errors)', value: 'strict'}]});
                if (script.script_type.match('sheet')) {
                    inputs.push({name: 'sheet', type: 'text', label: 'Spreadsheet Tab'}); 
                }
                inputs.push({name: 'bib_action', type: 'select', label: 'Bib Action', value: 'replace', values: [
                    {display: 'From Leader 05', value: 'marc_leader'},
                    {display: 'Replace', value: 'replace'},
                    {display: 'Create New', value: 'create_new'},
                    {display: 'Delete', value: 'delete'}
                ]});
                inputs.push({name: 'item_action', type: 'select', label: 'Item Action', value: 'replace', values: [
                    {display: 'From 952$@', value: 'item_del_field'},
                    {display: 'Replace', value: 'replace'},
                    {display: 'Always Add', value: 'always_add'},
                    {display: 'Delete', value: 'delete'},
                    {display: 'Ignore', value: 'ignore'}
                ]});
                inputs.push({name: 'batch_comments', type: 'text', label: 'Batch Comments'});
                inputs.push({name: 'save_run_log', type: 'select', label: 'Save Run Log', value: 0, values: [
                    {display: 'No', value: 0},
                    {display: 'Yes', value: 1},
                ]});
            }

            angular.forEach(params, function(p) { inputs.push(p); });

            // Deal with script parameters

            kwFileUploadSvc.upload({
                title: 'Run Script: ' + script.name,
                description: description,
                instructions: true,
                inputs: inputs,
                fileRequired: !isExport,
                uploadButtonText: 'Run',
                json: true,
                url: '/api/import-script/' + script.id + '?op=execute',
                link: function(scope) {

                    var vendor_id = (configService.infomart ? scope.config.inputs[1].value : null);
                    var ledger_id = (configService.infomart ? scope.config.inputs[2].value : null);

                    scope.reloadParameterSets = function() {
                        kwApi.ImportScript.getParameters({
                            id: script.id,
                            vendor_id: vendor_id,
                            ledger_id: ledger_id,
                        }).$promise.then(function(rv) {
                            if (configService.infomart)
                                scope.config.inputs[3].values = rv;
                            else
                                scope.config.inputs[1].values = rv;
                        });
                    };
                    scope.reloadParameterSets();

                    scope.applyParameters = function(map) {
                        var n = (configService.infomart ? 3 : 1);
                        for (var i=n+1; i<scope.config.inputs.length; i++) {
                            var name = scope.config.inputs[i].name;
                            if (name in map) {
                                scope.config.inputs[i].value = map[name];
                            }
                        }
                    };

                    scope.saveParameters = function() {
                        var obj = {
                            import_script_id: script.id,
                            vendor_id: (configService.infomart ? scope.config.inputs[1].value : null),
                            ledger_id: (configService.infomart ? scope.config.inputs[2].value : null),
                            parameters: {},
                        };
                        var n = (configService.infomart ? 3 : 1);
                        var p = scope.config.inputs[n];

                        for (var i=n+1; i<scope.config.inputs.length; i++) {
                            var name = scope.config.inputs[i].name;
                            obj.parameters[name] = scope.config.inputs[i].value;
                        }
                        var promise;
                        if (p._id) {
                            obj.id = p._id;
                            promise = kwApi.ImportScriptParameterSet.put({id: obj.id}, obj).$promise;
                        }
                        else if (p._name) {
                            obj.name = p._name;
                            promise = kwApi.ImportScriptParameterSet.save(obj).$promise;
                        }
                        if (promise) {
                            promise.then(function(rv) {
                                scope.reloadParameterSets();
                            });
                        }
                    };


                    if (configService.infomart) {
                        // Vendor
                        scope.$watch('config.inputs[1].value', function(newVal, oldVal) {
                            if (newVal && (newVal !== oldVal)) {
                                scope.config.inputs[2].values.length = 0;
                                scope.config.inputs[2].value = '';
                                kwApi.Vendor.getLedgers({id: newVal}).$promise.then(function(ledgers) {
                                    angular.forEach(ledgers, function(ledger) {
                                        scope.config.inputs[2].values.push({display: ledger.ledger_code, value: ledger.id});
                                    });
                                });
                                scope.reloadParameterSets();
                            }
                        });

                        // Ledger
                        scope.$watch('config.inputs[2].value', function(newVal, oldVal) {
                            if (newVal && (newVal !== oldVal)) {
                                scope.reloadParameterSets();
                            }
                        });

                        // Parameter Set
                        scope.$watch('config.inputs[3].value', function(newVal, oldVal) {
                            scope.config.inputs[3]._id = undefined;
                            if (newVal && (newVal !== oldVal)) {
                                if (/^\d+$/.test(newVal)) {
                                    scope.config.inputs[3]._id = newVal;
                                    scope.config.inputs[3].values.forEach(function(ent) {
                                        if ((ent.id == newVal) && ent.parameters) {
                                            scope.applyParameters(ent.parameters);
                                        }
                                    });
                                }
                                else {
                                    scope.config.inputs[3]._name = newVal;
                                }
                            }
                        });
                    }
                    else {
                        // Parameter Set
                        scope.$watch('config.inputs[1].value', function(newVal, oldVal) {
                            scope.config.inputs[1]._id = undefined;
                            if (newVal && (newVal !== oldVal)) {
                                if (/^\d+$/.test(newVal)) {
                                    scope.config.inputs[1]._id = newVal;
                                    scope.config.inputs[1].values.forEach(function(ent) {
                                        if ((ent.id == newVal) && ent.parameters) {
                                            scope.applyParameters(ent.parameters);
                                        }
                                    });
                                }
                                else {
                                    scope.config.inputs[1]._name = newVal;
                                }
                            }
                        });
                    }

                    var oldUpload = scope.upload;
                    scope.upload = function() {
                        scope.saveParameters();
                        oldUpload();
                    };
                },
                responseValidator: function(s) {
                    if (s.data.async) {
                        alertService.add({msg: "Job scheduled; pick up results in run log", type: "info"});
                        return 1;
                    }

                    $uibModal.open({
                        templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-execute-results.html',
                        dialogClass: 'xmodal',
                        backdropClass: 'modal-backdrop',
                        backdropClick: false,
                        backdrop: 'static',
                        size: 'lg',
                        controller: ["$scope", "data", "$sce", function($scope,data,$sce) {
                            $scope.data = data;
                            if ($scope.data.debug) {
                                angular.forEach($scope.data.debug, function(d) {
                                    $sce.trustAsHtml(d.debug);
                                });
                            }
                            if (data.export_file) {
                                $scope.fileDownloadUrl = '/api/import-script/' + script.id + '?view=download&file=' + encodeURIComponent(data.export_file) + '&as=' + encodeURIComponent(data.export_as);
                            }
                            if (data.async) {
                                $scope.async = true;
                            }
                        }],
                        resolve: {
                            data: function() { return s.data }
                        }
                    });
                    
                    if (s.data.import_batch_id) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                }
            });
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Execute failed');
        });
    };

    return svc;
}]);

module.factory('kwSocialMediaSvc', ["$window", "kwApi", "$q", "alertService", "userService", "configService", "$timeout", "$rootScope", function($window, kwApi, $q, alertService, userService, configService, $timeout, $rootScope) {
    var svc = {
        methods: [
            {type: 'facebook', name: 'Facebook'},
            {type: 'google', name: 'Google'},
        ]
    }

    svc.getLinkedMethods = function(socialMediaAccounts) {
        var has = {};
        angular.forEach(socialMediaAccounts, function(act) {
            has[act.type] = act;
        });

        var rv = [];
        angular.forEach(svc.methods, function(m) {
            rv.push(angular.extend({link: has[m.type]}, m));
        });

        return rv;
    };

    svc.authInit = function(type) {
        kwApi.Login.loginSocialInit({type: type},{}).$promise.then(function(rv) {
            $window.location.href = rv.url;
        });
    };

    svc.authFinalize = function(type, code) {
        var deferred = $q.defer();
        kwApi.Login.loginSocialFinalize({type: type, code: code},{}).$promise.then(function(rv) {
            userService.clear();

            $timeout(function() {
                userService.whenAuthenticatedUserDetails().then(function(u) {
                    if (configService.UserRequiredDetails) {
                        var requiredDetails = configService.UserRequiredDetails;
                        var userDetailsData = userService.details_data;
                        // why isn't this in a service?
                        $rootScope.checkRequiredUserDetails(requiredDetails, userDetailsData);
                    }

                    alertService.add({
                        type: "success",
                        msg: "You've successfully logged in!<p>Go to <a href=\"/app/me/dashboard\">your account</a>.<p>"
                    });
                    deferred.resolve(1);
                }, function(err) {
                    deferred.reject(err);
                });
            }, 500);
        }, function(err) {
            if (typeof(err) === 'object')
                err = err.data;
            if (/not recognized or linked/.test(err)) {
                deferred.reject("You haven't linked your " + type + " account with your library account yet! Log in using your user name and password, then under your user menu go to Account Home, then Social Login. From there, you can link to social media accounts.");
            }
            else {
                alertService.add({
                    type: "error",
                    msg: "Unable to login via " + type + ": " + err
                });
                deferred.reject('');
            }
        });
        return deferred.promise;
    };

    svc.linkAccount = function(type) {
        kwApi.Login.linkSocialInit({type: type},{}).$promise.then(function(rv) {
            $window.location.href = rv.url;
        });
    };

    svc.linkFinalize = function(type, code) {
        var deferred = $q.defer();
        kwApi.Login.linkSocialFinalize({type: type, code: code},{}).$promise.then(function(rv) {
            deferred.resolve(rv);
        }, function(err) {
            if (typeof(err) === 'object')
                err = err.data;

            alertService.add({
                type: "error",
                msg: "Unable to link to " + type + ": " + err
            });
            deferred.reject(err);
        });
        return deferred.promise;
    };
    return svc;
}]);


module.factory('kwHotkeySvc', ["configService", function(configService){

    var keymap = {};
    var action = {};

    // hotkeys are generally registered/deregistered in controllers or directives and stored here.
    // handlers are a stack, allowing overrides in different parts of the application.


    var defaultHotkeyMap = angular.copy(configService.hotkeyDefs);

    var specialKeys = {
            8: "backspace", 9: "tab", 13: "return", 16: "shift", 17: "ctrl", 18: "alt", 19: "pause",
            20: "capslock", 27: "esc", 32: "space", 33: "pageup", 34: "pagedown", 35: "end", 36: "home",
            37: "left", 38: "up", 39: "right", 40: "down", 45: "insert", 46: "del", 59: ";", 61: "=",
            96: "0", 97: "1", 98: "2", 99: "3", 100: "4", 101: "5", 102: "6", 103: "7",
            104: "8", 105: "9", 106: "*", 107: "+", 109: "-", 110: ".", 111 : "/",
            112: "f1", 113: "f2", 114: "f3", 115: "f4", 116: "f5", 117: "f6", 118: "f7", 119: "f8",
            120: "f9", 121: "f10", 122: "f11", 123: "f12", 144: "numlock", 145: "scroll",
            173: "-", 186: ";", 187: "=", 188: ",", 189: "-", 190: ".", 191: "/", 192: "`",
            219: "[", 220: "\\", 221: "]", 222: "'"
        };

    var shiftNums = {
            "`": "~", "1": "!", "2": "@", "3": "#", "4": "$", "5": "%", "6": "^", "7": "&",
            "8": "*", "9": "(", "0": ")", "-": "_", "=": "+", ";": ": ", "'": "\"", ",": "<",
            ".": ">",  "/": "?",  "\\": "|"
        };
    var bareModifier = {
        16: "shift", 17: "ctrl", 18: "alt", 91: 'meta', 92: 'meta'
    }
    function keyHandler ( event ) {

        // allow esc key to trigger blur() [if in input ].
        if($(event.target).is("input,textarea,select")){
            if(event.keyCode== 27 ){
                $(event.target).blur();
                event.stopPropagation();
                event.preventDefault();
            }
            if(!( event.ctrlKey||event.altKey||event.metaKey ))
                return;
        }
        if(bareModifier[event.which])
            return;

        var special = specialKeys[ event.which ],
            character = String.fromCharCode( event.which ).toLowerCase(),
            modif = "", possible = {};

        //  from : github.com/jeresig/jquery.hotkeys

        jQuery.each(["alt", "ctrl", "shift"], function(index, specialKey) {
            if (event[specialKey + 'Key'] && special !== specialKey)
                modif += specialKey + '+';
        });

          // metaKey is triggered off ctrlKey erronously
        if (event.metaKey && !event.ctrlKey && special !== "meta")
            modif += "meta+";

        if (event.metaKey && special !== "meta" && modif.indexOf("alt+ctrl+shift+") > -1)
            modif = modif.replace("alt+ctrl+shift+", "hyper+");

        if (special) {
            possible[modif + special] = true;
        } else if(shiftNums[character]){
            possible[modif + shiftNums[character]] = true;
            if (modif === "shift+") {  // "$" can be triggered as "Shift+4" or "Shift+$" or just "$"
              possible[shiftNums[character]] = true;
            }
        } else {
            possible[modif + character] = true;
        }

        for( var keyspec in possible ){
            if(keymap[keyspec] && action[keymap[keyspec][0]]){
                // $timeout(function(){
                    // console.log('FIRING ACTION FOR ' + keyspec );
                    action[keymap[keyspec][0]].call( event, event );
                // });
                event.preventDefault(); // FIXME: should be optional.
                return false;
            }
        }
    }

    // bind here rather than in directive, since there's nothing extra to do.
    $(document).bind('keydown', keyHandler);

    return {

        registerKey : function(key, actionId){
            // add to hotkeymap.
            if(!key.length) throw new Error('No key');
            //  console.log('Registering: ' + actionId + ' -> ' + key);
            if(!action[actionId]) throw new Error('No action');

            if(keymap[key]){
                var already = keymap[key].indexOf(actionId);
                if(already != -1){
                    console.warn('Duplicate hotkey registration at index: ' + already);
                    keymap[key].splice(already, 1);
                }
                keymap[key].unshift(actionId);
            } else {
                keymap[key] = [actionId];
            }

        },
        register : function(actionId, fn, key){
            // registers an action.  uses default keys if not provided.
            try {
                if(typeof fn === 'function')
                    action[actionId] = fn;

                if(!key) key = defaultHotkeyMap[actionId].key;
                this.registerKey(key, actionId);
                return key;
            } catch (e) {
                console.warn('Hotkey registration failed for: ' + actionId);
            }

        },

        deregister : function(actionId){
            // remove action and any triggering keys.

            delete action[actionId];
            for(var k in keymap){
                keymap[k] = keymap[k].filter(function(a){ return a != actionId; });
                if(!keymap[k].length) delete keymap[k];
            }
        },

        list: function(opt){
            if(!opt) opt={};
            return (opt.active) ? angular.copy(keymap) : angular.copy(defaultHotkeyMap);
        },

        keyAction: function(keySpec){
            return (keymap[keySpec]||[])[0];
        }

    };
}]);

// TODO refactor into a separate module

module.factory('communityContentSvc', ["$resource", "configService", "userService", "$rootScope", function($resource, configService, userService, $rootScope) {
    var svc = {
        siteKey: configService.CommunitySiteKey,
        siteName: configService.CommunitySiteName,
        userIsAuthenticated: false,
        userId: null,
        userRatings: null,
        baseUrl: 'https://g9wczm9itj.execute-api.us-east-1.amazonaws.com/test',
        ratingsCache: {},
    };

    svc.api = {
        userRatings: $resource(svc.baseUrl + '/api/user/:id/ratings', {site_id: svc.siteKey}, {
            getList: {
                method: 'GET',
                isArray: true
            }
        }),
        rating: $resource(svc.baseUrl + '/api/work/:isbn/rating', {site_id: svc.siteKey}, {
            set: {
                method: 'POST',
                url: svc.baseUrl + '/api/work/:isbn/rating?user_id=:user_id&rating=:rating',
            },
        }),
        comments: $resource(svc.baseUrl + '/api/work/:isbn/comments', {site_id: svc.siteKey}, {
            getList: {
                method: 'GET',
                isArray: true
            },
            post: {
                method: 'POST'
            },
        }),
                
    };


    svc.getRating = function(isbn) {
        /*if (isbn in svc.ratingsCache) {
            return $q.when(svc.ratingsCache[isbn]);
        }*/

        return svc.api.rating.get({isbn: isbn}).$promise.then(function(rating) {
            svc.ratingsCache[isbn] = rating;
            return rating;
        });
    };

    svc.getUserRating = function(isbn) {
        return userService.whenGetUserId().then(function(userId) {
            if (userId <= 0) {
                return null;
            }
            else if (svc.userRatings) {
                return svc.userRatings[isbn];
            }
            else {
                return svc.api.userRatings.getList({id: userId}).$promise.then(function(ratings) {
                    svc.userRatings = {};
                    ratings.forEach(function(r) {
                        svc.userRatings[r.work_id] = r.rating;
                    });

                    return svc.userRatings[isbn];
                }, function(err) {
                    console.log("Caught error " + err);
                    console.dir(err);
                    // TODO
                });
            }
        });
    };

    svc.setUserRating = function(isbn, rating) {
        return svc.api.rating.set({isbn: isbn, rating: rating, user_id: userService.id}, {}).$promise;
    };

    svc.getComments = function(isbn) {
        return svc.api.comments.getList({isbn: isbn}).$promise.then(function(comments) {
            return comments.sort(function(a,b) {
                if (a.created_time < b.created_time) return 1;
                else if (a.created_time > b.created_time) return -1;
                else return 0;
            });
        });
    };

    svc.addComment = function(isbn, comment) {
        return userService.whenAnyUserDetails().then(function(details) {
            console.dir(details);
            return svc.api.comments.post({isbn: isbn, user_id: userService.id}, {
                user_name: details.firstname + ' ' + details.surname,
                site_name: svc.siteName,
                text: comment
            }).$promise;
        });
    };
        
    $rootScope.$on('loggedin', function() {
        svc.getUserRating();
    });

    $rootScope.$on('loggedout', function() {
        svc.userRatings = null;
        svc.ratingsCache = {};
    });


    return svc;
}]);

module.factory('bvDownloadSvc', [ () => {
    let svc = {};

    svc.fetch = (conf) => {
        let data = conf.fetchData(),
            octetStreamMime = 'application/octet-stream',
            filename = conf.fileName,
            blob = new Blob([data], { type: octetStreamMime });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        }
        else {
            let urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
            let url;
            if (urlCreator){
                let link = document.createElement('a');
                if ('download' in link) {
                    url = urlCreator.createObjectURL(blob);
                    let event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename);
                    link.dispatchEvent(event);
                }
                else {
                    url = urlCreator.createObjectURL(blob);
                    window.location = url;
                }
            }
            else {
                console.log('Your browser does not support data download: ' + navigator.userAgent);
            }
        }
    };

    return svc;
}]);

module.factory('bvPrintSvc', ["$http", "$rootScope", "$templateCache", "$compile", "$timeout", function($http, $rootScope, $templateCache, $compile, $timeout) {
    var svc = {};

    svc.print = function(def) {
        // Currently only supports controller and templateUrl
        //
        var scope = $rootScope.$new(true);
//        $http.get(def.templateUrl, {cache: $templateCache}).then(function(resp) {
        $http.get(def.templateUrl, {cache: $templateCache}).then(function(resp) {
            def.controller(scope);

            var elem = $compile('<div>' + resp.data + '</div>')(scope);
            // Trigger a digest cycle
            $timeout(function() {
                var html = elem.html();
                var win = window.open('','_blank');
                win.document.write(html);
                win.document.close();
                win.setTimeout(function() {
                    win.print();
                    win.close();
                }, 500);
            });
        });
    };

    return svc;
}]);

module.factory('syncTemplateCache', function() {
    var svc = {
        cache: {}
    };

    svc.put = function(path, template) {
        if (path.substring(0,1) !== '/')
            path = '/' + path;
        path = path.replace(/^\/app\/partials/,'/app/static/partials');
        //console.log("Put template " + path);
        svc.cache[path] = template;
    };

    svc.get = function(path) {
        if (path.substring(0,1) !== '/')
            path = '/' + path;
        //console.log("Get template " + path);
        if (path in svc.cache)
            return svc.cache[path];
        else {
            console.log("Synchronous template " + path + " not found");
            return '<div><b>synchronous template ' + path + ' not found</div>';
        }
    };

    return svc;
});

// For the bvAsyncOnready and bvAsyncOnreadyAwait directives
module.factory('bvAsyncOnreadySvc', function() {
    var svc = {
        stat: {}
    };

    svc.register = function(name) {
        if (!svc.stat[name]) {
            svc.stat[name] = {child: {}};
        }
        svc.stat[name].templateReady = false;
    };

    svc.templateReady = function(name, onReady) {
        svc.stat[name].templateReady = true;
        svc.stat[name].onReady = onReady;
        svc.testIfReady(name);
    };


    svc.child = function(name, childName) {
        if (!svc.stat[name]) {
            svc.stat[name] = {child: {}};
        }

        if (!svc.stat[name].child[childName]) {
            svc.stat[name].child[childName] = 0;
        }
        svc.stat[name].child[childName]++;
    };

    svc.childReady = function(name, childName) {
        if (!svc.stat[name]) {
            svc.stat[name] = {child: {}};
        }

        if (!svc.stat[name].child[childName]) {
            svc.stat[name].child[childName] = 0;
        }
        svc.stat[name].child[childName]--;
        //console.log("Child " + name + '.' + childName + " = " + svc.stat[name].child[childName]);

        svc.testIfReady(name);
    };

    svc.testIfReady = function(name) {
        var isReady = true;
        angular.forEach(svc.stat[name].child, function(val, key) {
            if (val > 0)
                isReady = false;
        });
        if (!isReady) return;
        if (!svc.stat[name].templateReady) return;
        //console.log("Firing " + name + " = " + svc.stat[name].onReady);
        svc.stat[name].onReady();
    };

    return svc;
});

})();

