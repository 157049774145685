(function () {
'use strict';

if (!Array.prototype.replaceWith) {
    Array.prototype.replaceWith = function(array) {
        this.length = 0;
        array.forEach(function(v) { this.push(v)}, this);
    };
}


var SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
function camelCase(name) {
  return name.
    replace(SPECIAL_CHARS_REGEXP, function(_, separator, letter, offset) {
      return offset ? letter.toUpperCase() : letter;
    });
}


var parseBooleanOption = function(s, defaultValue) {
    if (typeof(s) === 'undefined') {
        return defaultValue;
    }
    else if (typeof(s) === 'boolean') {
        return s;
    }
    else if (s == 'true') {
        return true;
    }
    else if (s == 'false') {
        return false;
    }
    else {
        return defaultValue;
    }
};

var buildGridControlScope = function(scope, attrs) {
    /*if (attrs.type) {
        attrs = angular.extend({}, this.standardToolbarTypes[attrs.type] || {}, attrs);
    }*/

    scope.label = attrs.label;
    scope.href  = attrs.href;
    scope.title = attrs.title;
    scope.buttonClass = attrs.class;
    scope.iconClass = attrs.icon;

    if (attrs.selectionAction) {
        scope.action = function() {
            var actionFunction = scope.$eval(attrs.selectionAction);
            if (scope.isEnabled && actionFunction) {
                actionFunction(scope.getSelection());
            }
        };
    }
    else if (attrs.action) {
        scope.action = function() {
            var actionFunction = scope.$eval(attrs.action);
            if (scope.isEnabled && actionFunction) {
                actionFunction();
            }
        };
    }

    var lengthConstraint, valueConstraint;
    if (attrs.selectionRange) {
        switch (attrs.selectionRange) {
            case '0': 
                lengthConstraint = function(sel) { return sel.length == 0 };
                break;
            case '0+': 
                lengthConstraint = function(sel) { return true };
                break;
            case '1':
                lengthConstraint = function(sel) { return sel.length == 1 };
                break;
            case '1+':
                lengthConstraint = function(sel) { return sel.length >= 1 };
                break;
            case '2+':
                lengthConstraint = function(sel) { return sel.length >= 2 };
                break;
            default:
                throw "grid-selection-control selection-range not valid";
        }
    }

    if (attrs.selectionConstraint) {
        valueConstraint = function(sel) {
            for (var i=0; i<sel.length; i++) {
                scope.row = sel[i];
                if (!scope.$eval(attrs.selectionConstraint))
                    return false;
            }
            return true;
        }
    }
    //Compares all values have the same property
    else if (attrs.whereAll) {
        valueConstraint = function(sel) {
            scope.entities = sel;
            var original = "";
            for(var i=0; i < sel.length; i++){
                if(!original)
                    original = sel[i][attrs.whereAll];
                else{
                    if(original != sel[i][attrs.whereAll])
                        return false;
                }
            }  
            return true;
        }
    } 
 

    if (valueConstraint || lengthConstraint) {
        var dereg = scope.$watch(function() {
            return scope.getSelection();
        }, function(sel) {
            if (lengthConstraint && !lengthConstraint(sel)) {
                scope.isEnabled = false;
            }
            else if (valueConstraint && !valueConstraint(sel)) {
                scope.isEnabled = false;
            }
            else {
                scope.isEnabled = true;
            }

            if (scope.isEnabled) {
                if (typeof(attrs.enabledTitle) !== 'undefined') {
                    scope.title = attrs.enabledTitle;
                }
            }
            else {
                if (typeof(attrs.disabledTitle) !== 'undefined') {
                    scope.title = attrs.disabledTitle;
                }
            }
        }, true);

        scope.$on('$destroy', dereg);
    }
    else if (attrs.fixedConstraint) {
        dereg = scope.$watch(function() {
            return scope.$eval(attrs.fixedConstraint);
        }, function(bool) {
            scope.isEnabled = bool;
        }, true);
    }
    else {
        scope.isEnabled = true;
    }
};



angular.module('kohapac.ui-grid-glue', ['ui.grid', 'ui.grid.resizeColumns', 'ui.grid.pagination', 'ui.grid.selection', 'ui.grid.exporter', 'ui.grid.edit', 'ui.grid.rowEdit', 'ui.grid.saveState'])
.directive('frontendGrid', function() {
    return {
        scope: false,
        restrict: 'E',
        //priority: 500,
        transclude: true,
        template: "<div><span ng-transclude></span><div class='gridStyle' style='{{grid.style}}' ui-grid='grid' ui-grid-selection ui-grid-pagination ui-grid-edit ui-grid-row-edit ui-grid-resize-columns ui-grid-save-state resizable='{\"handles\": \"s\"}' ></div></div>",
        compile: function(tElem,tAttrs,transcludeFn) {
            return {
                pre: function(scope,elem,attrs) {
                    var multiSelect = parseBooleanOption(attrs.multiSelect, true);
                    var ctrlMultiSelect = parseBooleanOption(attrs.multiSelectWithCtrl, true);
                    var selectCheckbox = multiSelect && !ctrlMultiSelect;

                    scope.grid = {
                        data: scope.$eval(attrs.data),
                        columnDefs: [],

                        // Filtering is awful.
                        enableFiltering: parseBooleanOption(attrs.enableFiltering, false),
                        enableSorting: parseBooleanOption(attrs.enableSorting, true),
                        flatEntityAccess: true,
                        enableColumnResizing: parseBooleanOption(attrs.enableColumnResizing, true),
                        enablePagination: parseBooleanOption(attrs.enablePagination, true),
                        enablePaginationControls: parseBooleanOption(attrs.enablePagination, true),
                        paginationPageSizes: [15, 25, 50, 100, 500, 1000, 5000],
                        paginationPageSize: attrs.pageSize ? 1*attrs.pageSize : 1000,
                        enableColumnMenus: parseBooleanOption(attrs.enableColumnMenus, true),


                        enableRowSelection: true,
                        enableRowHeaderSelection: selectCheckbox,
                        showGridFooter: parseBooleanOption(attrs.showGridFooter, true),
                        enableFooterTotalSelected: true,
                        multiSelect: multiSelect,
                        modifierKeysToMultiSelect: ctrlMultiSelect,

                        enableGridMenu: true,

                        //footerRowHeight: 45,
                        //headerRowHeight: 25,
                        //rowHeight: 20,
                        //rowEditHeight: attrs.rowEditHeight || 24,
                        //rowEditPanelHeight: 50,
                        //showSelectionCheckbox: parseBooleanOption(attrs.showSelectionCheckbox, false),
                        style: attrs.style,

                        onRegisterApi: function(gridApi) {
                            scope.gridApi = gridApi;
                        }
                    };

                    scope.getSelection = function() {
                        return scope.gridApi ? scope.gridApi.selection.getSelectedRows() : [];
                    };
                }
            }
        }
    }
})

.directive('gridTemplate', function() {
    return {
        scope: false,
        restrict: 'E',
        //priority: 1900,
        compile: function(tElem, tAttrs) {
            return {
                pre: function(scope, elem, attrs) {
                    var type = camelCase(attrs.type);
                    var template = elem[0].innerHTML;
                    template = template.replace(/{ {/g,'{{');
                    template = template.replace(/} }/g,'}}');
                    template = template.replace(/\s*\n\s*/g,'');
                    template = template.replace(/.*<!--\s*/,'<');
                    template = template.replace(/\s*-->.*/,'>');

                    scope.grid[type] = template;
                    elem.innerHtml = '';
                    //tmpl.remove();
                }
            }
        }
    }
})


.directive('gridColumnTemplate', function() {
    return {
        scope: false,
        restrict: 'E',
        //priority: 1900,
        compile: function(tElem, tAttrs) {
            return {
                pre: function(scope, elem, attrs) {
                    var type = camelCase(attrs.type);
                    var template = elem[0].innerHTML;
                    template = template.replace(/{ {/g,'{{');
                    template = template.replace(/} }/g,'}}');
                    template = template.replace(/\s*\n\s*/g,'');
                    template = template.replace(/.*<!--\s*/,'<');
                    template = template.replace(/\s*-->.*/,'>');

                    scope.grid.columnDefs[scope.grid.columnDefs.length - 1][type] = template;
                    elem.innerHtml = '';
                    //tmpl.remove();
                }
            }
        }
    }
})

.directive('gridColumn', function() {
    return {
        scope: false,
        restrict: 'E',
        //priority: 1900,
        compile: function(tElem, tAttrs) {
            return {
                pre: function(scope, elem, attrs, transcludeFn) {
                    if (typeof(scope.grid.columnDefs) == 'undefined') {
                        scope.grid.columnDefs = [];
                    }
                    
                    var column = {
                        field:          attrs.field,
                        sortable:       (attrs.sortable === 'false' ? false : true),
                        displayName:    attrs.displayName,
                        cellFilter:     attrs.cellFilter,
                        width:          attrs.width
                        // Todo other fields
                    };
                    var doAdd = true;
                    if (attrs.gcIf) {
                        doAdd = scope.$eval(attrs.gcIf);
                    }
                    if (doAdd) {
                        scope.grid.columnDefs.push(column);
                        if (typeof(scope.grid.sortInfo) === 'undefined') {
                            scope.grid.sortInfo = {fields:[], directions:[]};
                        }

                        if ((attrs.sort === 'asc') || (attrs.sort === 'desc')) {
                            scope.grid.sortInfo.fields.push(attrs.field);
                            scope.grid.sortInfo.directions.push(attrs.sort);
                            //Log.ger.dir(scope.grid.sortInfo);
                        }
                    }
                }
            }
        }
    }
})


.directive('toolButton', function() {
    return {
        restrict: 'E',
        transclude: true,
        replace: false,
        template: '<button class="btn btn-outline-secondary toolbutton" ng-class="buttonClass" ng-click="action()" ng-disabled="!isEnabled" title="{{title}}"><i ng-class="iconClass"></i> <span ng-transclude></span></button>',
        scope: true,
        link: function(scope, elem, attrs) {
            buildGridControlScope(scope, attrs);
        }
    }
})

.directive('toolMenuButton', function() {
    return {
        restrict: 'E',
        transclude: true,
        template: '<div class="btn-group" uib-dropdown>' +
                    '<button type="button" class="btn btn-outline-secondary toolbutton" ng-disabled="!isEnabled" uib-dropdown-toggle>' +
                      '<i ngclass="iconClass"></i> {{label}}  ' +
                    '</button>' +
                    '<ul class="dropdown-menu" uib-dropdown-menu role="menu" ng-transclude></ul>' +
                  '</div>',
        scope: true,
        link: function(scope, elem, attrs) {
            buildGridControlScope(scope, attrs);
        }
    }
})

.directive('toolMenuEntry', function() {
    return {
        restrict: 'E',
        transclude: true,
        template:   '<li role="menuitem"><a href="#" ng-click="action()" ng-class="{\'dropdown-entry-disabled\': !isEnabled}" ng-disabled="!isEnabled"><i ng-class="iconClass"></i> <span ng-transclude></span></a></li>',
        scope: true,
        replace: true,
        link: function(scope, elem, attrs) {
            buildGridControlScope(scope, attrs);
        }
    }
})




;

})();
