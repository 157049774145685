(function () {
    "use strict";

var module = angular.module('koha.validSession', [])
.factory('kohaHttpResendQueue', ["$injector", function($injector){
    var queue = [];
    var $http;  // to avoid circular dep.

    return {
        enqueue : function(config, deferred){
            queue.push({
              config: config,
              deferred: deferred
            });
        },
        resendAll : function(){
            $http = $http || $injector.get('$http');
                var successCB = function(r){  console.log(r); request.deferred.resolve(r); return request.deferred.promise; };
                var errorCB = function(r){  console.log(r); request.deferred.reject(r); return request.deferred.promise; };

            for(var i=0; i<queue.length; i++){
                var request = queue.shift();
                $http(request.config).then( successCB, errorCB );
            }
        },
        rejectAll : function(reason){
            for(var i=0; i<queue.length; i++){
                var request = queue.pop();
                if (reason) request.deferred.reject(reason);
            }
        },
        echo : function(config){
            $http = $http || $injector.get('$http');
            return $http(config);
        }
    };

}]);

module.provider('kohaHttpSessInterceptor', function kohaHttpSessInterceptorProvider(){

    // Configurable $http interceptor for requests and responses.

    // queues all auth-required http requests if not logged in.
    // the request must have an 'authRequired' property on its config option,
    // or the service can be configured to globally require auth.
    // configure with a function that determines authentication status (returns true if authenticated)

    // We also queue and resend a request that gets a 403 - session expired response.
    // This is prone to failure in the edge case:
    // If two requests are made at the same time, but the one that has the session expired message
    // returns AFTER the one that just has a plain 403, it won't be queued, but will just fail.
    // The only way to correct this would be to have the api response distinguish between a 403
    // with anonymous user vs a 403 with authenticated user.
    // This may not happen in the real world though.


    // configuration

    var authRequired = function(config){
        return config.authRequired;  // default: add an authRequired prop to $http config object.
    };
    this.authRequired = function(val){
        authRequired = val;
        return this;
    };

    var authenticated = null;
    this.authenticated = function(fn){
        authenticated = fn;
        // should return a promise resolving to 
        // true : application believes the user is authenticated
        //        -- it could be wrong if the session has expired.
        // false : application knows that the user is not authenticated.

    // NOTE: authenticated is called with $injector so user
    // can access services to test auth state in config block.
        return this;
    };

    var reauthenticated = null;
    this.reauthenticated = function(fn){
        // should return a promise that resolves upon successful login.
        reauthenticated = fn;
        return this;
    };

    var autoReauthenticate = false;  // triggers loginRequired event on all session expiry.
        // if false, loginRequired is only broadcast if authRequired returns true.
    this.autoReauthenticate = function(val){
        autoReauthenticate = !!val;
        return this;
    };

    var events = {
        rejectAll: 'loginAborted',
        resendAll: 'loggedin',
        loginRequired: 'loginRequired',
        sessionExpired: 'sessionExpired'
    };
    this.events = function(cfg){
        angular.extend(events, cfg);
        return this;
    };


    this.$get = ['$q', '$rootScope', '$injector', 'kohaHttpResendQueue', function kohaHttpSessInterceptorFactory($q, $rootScope, $injector, kohaHttpResendQueue){

        // Set up event listeners.
        if(events.resendAll){
            $rootScope.$on(events.resendAll, function(){
                kohaHttpResendQueue.resendAll();
            });
        }
        if(events.rejectAll){
            $rootScope.$on(events.rejectAll, function(){
                kohaHttpResendQueue.rejectAll();
            });
        }

        return {
            /*request: function(config){

// console.log(config);

                if(authenticated && authRequired(config)){
                    var deferred = $q.defer();
                    authenticated($injector).then(function(userid){
                        if(userid){
                            deferred.resolve(config);
                        } else {
                            console.log("Not authenticated...");
                            $rootScope.$broadcast(events.loginRequired);
                            reauthenticated($injector).then(function(){
                                console.log('getUser resolved...');
                                deferred.resolve(config);
                            });
                        }
                    });
                    return deferred.promise;
                } else {
                    return config;
                }
            },*/

            responseError : function(response){
                if (response.status === 403 &&
                        (response.data == "403 Session has expired" ||
                            response.data == "403 Unknown session")){
                    // the 'unknown session' response usually indicates an expired session
                    // (the request was made before we got a 'session expired' response)
                    var needsAuth = authRequired(response.config);
                    if(autoReauthenticate || needsAuth) $rootScope.$broadcast(events.loginRequired, { sessionExpired: true });
                    if(needsAuth){
                        var deferred = $q.defer();
                        kohaHttpResendQueue.enqueue(response.config, deferred);
                        return deferred.promise;
                    } else {
                        // Don't enqueue, just ensure logout and resend request.
                        $rootScope.$broadcast(events.sessionExpired);
                        return kohaHttpResendQueue.echo(response.config);
                    }
                } else {
                    // $http interceptors should return the response object
                    return $q.reject(response);
                }
            }
        };
    }];

});




})();
