'use strict';

/* Filters */

angular.module('kohapac.filters', [])

.filter('chop', function(){
    return function(text) {
      return String(text).replace(/\s*[\/:;,\.]?\s*$/, '');
    };
})
.filter('.', function(){
    return function(text) {
      return String(text).replace(/\s*[\/:;,\.]?\s*$/, '') + '.';
    };
})

.filter('ucfirst', function(){
    return function(text) {
        if(!text) return '';
        var i = text.search(/[^\d\s[({\-\/\*<\.,:;]/); // meh //
        if(i>0)
            return text.slice(0,i) + text.charAt(i).toUpperCase() + text.slice(i+1);
        else
            return text.charAt(0).toUpperCase() + text.slice(1);
    };
})
.filter('uc', function(){
    return function(text){
        return text.toUpperCase();
    }
})
.filter('displayName', ["configService", function(configService){
    return function(input, type){
        return configService.display(input,type);
    };
}])

.filter('ifempty', function(){
    return function(text, defaultText){
        return (text || text === 0) ? text : defaultText;
    };
})
.filter('boolText', function(){
    return function(bool){
        return (bool && (bool !== '0') && (bool !== 'false')) ? 'Yes' : 'No';
    };
})
.filter('wrap', function(){
    return function(input, wrapper){
        if(wrapper.length % 2)
            return wrapper + input + wrapper;
        else
            return wrapper.substring(0,(wrapper.length/2)) + input +
                    wrapper.substring(wrapper.length/2, wrapper.length);
    };
})
.filter('filesize', function(){
    var units = ['B', 'K', 'M', 'G', 'T', 'P'];
    return function(input){
        for (var i = 0; i < 5; i++) {
            if(input > 1000) input = input / 1024;
            else break;
        }
        return input.toFixed(( i > 0 && input > 10 ) ? 0 : 1 ) + units[i];
    };
})
.filter('kohaDate', ["configService", function(configService){
    // angular's stock date filter doesn't handle date string like '2013-08-11 00:00:00'.

    return function(input, withTime){
        if(!input) return '';
        if (input === '0000-00-00 00:00:00') return '';
        // using dayjs formatter, not angular's.
        var formatStr = configService.dateFormatString.toUpperCase();
        if(withTime)
            formatStr += '  H:mm:ss';
        return dayjs(input).format(formatStr);

    };
}])
.filter('kohaDateOnly', ["configService", function(configService){
    // angular's stock date filter doesn't handle date string like '2013-08-11 00:00:00'.

    return function(input){
        if(!input) return '';
        if (input === '0000-00-00 00:00:00') return '';
        // using dayjs formatter, not angular's.
        var formatStr = configService.dateFormatString.toUpperCase();
        if (typeof(input) === 'string')
            input = input.replace(/[T ].*/, '');
        return dayjs(input).format(formatStr);
    };
}])
.filter('dateFmt', function() {
    return function(input, dateFormat) {
        if (!dateFormat) dateFormat = 'M/DD/YYYY H:mm:ss';
        return dayjs(input).format(dateFormat);
    }
})

.filter('kohaMoney', ["configService", function(configService){
    return function(num, option) {
        var opt = angular.extend(
                { symbol: false, zero: true, abs: false },
                angular.isObject( option ) ? option : { symbol: !!option } );

        if( !opt.zero && ( num === '' || num === null ) )
            return '';
        if (typeof(num) == 'string' && !num.match(/^\s*\-?[0-9\.]/))
            return num;
        if(opt.abs)
            num = Math.abs(num);

        var out = parseFloat(num).toFixed(2);
        if(out == 'NaN') return '';
        if(opt.symbol){
            return configService.locale.currency_symbol + out;
        } else {
            return out;
        }
    };
}])
.filter('kohaMoneyOrStr', ["$filter", function( $filter){
    return function(num, symbol) {
        // FIXME: Get rid of this one and just use above.
        return $filter('kohaMoney')(num, { symbol: symbol, zero: false } );
    };
}])
.filter('percent', function(){
    return function(text,scale){
        if (typeof(text) == 'string' && !text.match(/^\s*\-?[0-9\.]/)) {
            return text;
        }

        if (!scale) scale =1;
        text = 1*text;
        return '' + Math.floor(text*100*scale)/scale + '%';
    };
})
.filter('percentrange', function(){
    return function(text,scale){
        if (typeof(text) == 'string') {
            var m = text.match(/^([^\-]+) - ([^\-]+)$/);
            if (m && m.length > 1) {
                var p1 = 1*m[1];
                var p2 = 1*m[2];
                p1 = '' + Math.floor(p1*100*scale)/scale + '%';
                p2 = '' + Math.floor(p2*100*scale)/scale + '%';
                return '' + p1 + ' - ' + p2;
            }
        }

        if (typeof(text) == 'string' && !text.match(/^\s*\-?[0-9\.]/)) {
            return text;
        }

        if (!scale) scale =1;
        text = 1*text;
        return '' + Math.floor(text*100*scale)/scale + '%';
    };
})
.filter('urlescape', function(){
    return function(text){
        return encodeURIComponent(text);
    };
})
.filter('ternary', function(){
    return function(boolstring,truestring,falsestring){
        return (!(boolstring)) ? falsestring : truestring;
    };
})
.filter('ternaryTruthy', function(){
    return function(boolstring,truestring,falsestring){
        return (boolstring === 0 || boolstring === '0' || boolstring === '' || boolstring === false || 
            (typeof(boolstring) == 'string' && boolstring.match(/^(no|false)$/i))) ? falsestring : truestring;
    };
})
.filter('unique', function () {
    /* https://github.com/angular-ui/angular-ui-OLDREPO/blob/master/modules/filters/unique/unique.js
    * Filters out all duplicate items from an array by checking the specified key
    * @param [key] {string} the name of the attribute of each object to compare for uniqueness
    * if the key is empty, the entire object will be compared
    * if the key === false then no filtering will be performed
    * @return {array}
    */

    return function (items, filterOn) {

        if (filterOn === false) {
            return items;
        }

        if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
            var hashCheck = {}, newItems = [];

            var extractValueToCompare = function (item) {
                if (angular.isObject(item) && angular.isString(filterOn)) {
                    return item[filterOn];
                } else {
                    return item;
                }
            };

            angular.forEach(items, function (item) {
                var valueToCheck, isDuplicate = false;

                for (var i = 0; i < newItems.length; i++) {
                    if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                    isDuplicate = true;
                    break;
                    }
                }

                if (!isDuplicate) {
                    newItems.push(item);
                }
            });

            items = newItems;
        }

        return items;
    };
})

// TODO move to module
.filter('communityContentDate', ["configService", "dateFilter", function(configService, dateFilter){
    // angular's stock date filter doesn't handle date string like '2013-08-11 00:00:00'.

    return function(input, withTime){

        var timestr = '';
        if(typeof input == 'string'){
            if(withTime) timestr = ' ' +input.substring(11,11+8);
            input = input.substring(0,10);
            if(input == '000-00-00') return '';
        }
        return dateFilter(input, configService.dateFormatString) + timestr;
    };
}])
.filter('communityContentDecimal', function() {
    return function(input, prec) {
        if (!prec) prec = 1;
        return (1*input).toFixed(prec);
    };
})
.filter('bvDateRecur', function() {
    var month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var wday = ['Mo','Tu','We','Th','Fr','Sa','Su'];

    var names = function(x,what) {
        var xx = x.split(',');
        xx = xx.map(function(e) { return what[e-1] });
        if (xx.length == 1) {
            return xx[0];
        }
        else if (xx.length == 2) {
            return xx[0] + ' and ' + xx[1];
        }
        else {
            var xlast = xx.pop();
            return xx.join(', ') + ' and ' + xlast;
        }
    };

    var ordname = function(n) {
        if (n == 1 || n == 21 || n == 31) {
            return '' + n + 'st';
        }
        else if (n == 2 || n == 22) {
            return '' + n + 'nd';
        }
        else if (n == 3 || n == 23) {
            return '' + n + 'rd';
        }
        else {
            return '' + n + 'th';
        }
    };

    var ordnames = function(x) {
        var xx = x.split(',');
        xx = xx.map(function(e) { return ordname(e) });
        if (xx.length == 1) {
            return xx[0];
        }
        else if (xx.length == 2) {
            return xx[0] + ' and ' + xx[1];
        }
        else {
            var xlast = xx.pop();
            return xx.join(', ') + ' and ' + xlast;
        }
    };

    var pl = function(n,s,omitSingleton) {
        if (n == 1 && omitSingleton)
            return s;
        else if (n == 1)
            return '' + n + ' ' + s;
        else
            return '' + n + ' ' + s + 's';
    };
        



    return function(input, withTime) {
        if (!input) return input;
        if (input === 'Advanced Pattern') return input;
        var halves = input.split('*');
        if (halves.length != 2) return "[Invalid Pattern]";
        var lhs = halves[0].split(':');
        var rhs = halves[1].split(':');
        var hms = [rhs.pop(),rhs.pop(),rhs.pop()].filter(function(s) {
            return s !== null && s !== undefined;
        }).reverse().map(function(s) {
            return (s.length==1 ? ('0'+s) : s);
        });

        var every, on;
        if (lhs.length == 1) {
            every = 'every ' + pl(lhs[0],'year', true);
            if (rhs[0]!=0) {
                on = 'on ' + names(rhs[0],month);
                if (rhs[1]!=0) {
                    on = on + ' week(s) ' + rhs[1] + ' of the month';
                    if (rhs[2]!=0) {
                        on = on + ' on ' + names(rhs[2],wday);
                    }
                }
                else if (rhs[2]!=0) {
                    on = on + ' ' + ordnames(rhs[2]);
                }
            }
            else if (rhs[1]!=0) {
                on = 'on week(s) ' + rhs[1] + ' of the year';
                if (rhs[2]!=0) {
                    on = on + ' on ' + names(rhs[2],wday);
                }
            }
            else if (rhs[2]!=0) {
                on = 'on day(s) ' + rhs[2] + ' of the year';
            }
        }

        else if (lhs.length == 2) {
            if (lhs[0]!=0 && lhs[1]!=0) {
                every = 'every ' + pl(lhs[0],'year') + ' ' + pl(lhs[1],'month');
            }
            else if (lhs[0]!=0) {
                every = 'every ' + pl(lhs[0],'year',true);
            }
            else {
                every = 'every ' + pl(lhs[1],'month',true);
            }

            if (lhs[1]!=0) {
                if (rhs[0]!=0) {
                    on = 'on week(s) ' + rhs[0] + ' of the month';
                    if (rhs[1]!=0) {
                        on = on + ' on ' + names(rhs[1],wday);
                    }
                }
                else if (rhs[1]!=0) {
                    on = 'on the ' + ordnames(rhs[1]);
                }
            }
            else {
                if (rhs[0]!=0) {
                    on = 'on week(s) ' + rhs[0] + ' of the year';
                    if (rhs[1]!=0) {
                        on = on + ' on ' + names(rhs[1],wday);
                    }
                }
                else if (rhs[1]!=0) {
                    on = 'on the ' + ordnames(rhs[1]) + ' day(s) of the year';
                }
            }
        }
        else if (lhs.length == 3) {
            every = 'every';
            if (lhs[0]!=0) {
                every = every + ' ' + pl(lhs[0],'year');
            }
            if (lhs[1]!=0) {
                every = every + ' ' + pl(lhs[1],'month');
            }
            if (lhs[2]!=0) {
                every = every + ' ' + pl(lhs[2],'week');
            }

            if (rhs[0]!=0) {
                if (lhs[2]!=0) {
                    on = 'on ' + names(rhs[0],wday);
                }
                else if (lhs[1]!=0) {
                    on = 'on the ' + ordnames(rhs[0]);
                }
                else if (lhs[0]!=0) {
                    on = 'on the ' + ordnames(rhs[0]) + ' day(s) of the year';
                }
            }
        }

        if (lhs.length <= 3) {
            if (withTime) 
                return every + ', ' + on + ' at ' + hms.join(':');
            else
                return every + ', ' + on;
        }


        if (lhs.length == 4) {
            every = 'every';
            if (lhs[0]!=0) {
                every = every + ' ' + pl(lhs[0],'year');
            }
            if (lhs[1]!=0) {
                every = every + ' ' + pl(lhs[1],'month');
            }
            if (lhs[2]!=0) {
                every = every + ' ' + pl(lhs[2],'week');
            }
            if (lhs[3]!=0) {
                every = every + ' ' + pl(lhs[3],'day');
            }

            return every + ' at ' + hms.join(':');
        }
        else if (lhs.length == 5) {
            every = 'every';
            if (lhs[0]!=0) {
                every = every + ' ' + pl(lhs[0],'year');
            }
            if (lhs[1]!=0) {
                every = every + ' ' + pl(lhs[1],'month');
            }
            if (lhs[2]!=0) {
                every = every + ' ' + pl(lhs[2],'week');
            }
            if (lhs[3]!=0) {
                every = every + ' ' + pl(lhs[3],'day');
            }
            if (lhs[4]!=0) {
                every = every + ' ' + pl(lhs[4],'hour');
            }

            return every + ' at ' + hms[0] + ' min ' + hms[1] + ' sec';
        }
        else if (lhs.length == 6) {
            every = 'every';
            if (lhs[0]!=0) {
                every = every + ' ' + pl(lhs[0],'year');
            }
            if (lhs[1]!=0) {
                every = every + ' ' + pl(lhs[1],'month');
            }
            if (lhs[2]!=0) {
                every = every + ' ' + pl(lhs[2],'week');
            }
            if (lhs[3]!=0) {
                every = every + ' ' + pl(lhs[3],'day');
            }
            if (lhs[4]!=0) {
                every = every + ' ' + pl(lhs[4],'hour');
            }
            if (lhs[5]!=0) {
                every = every + ' ' + pl(lhs[5],'min');
            }

            return every + ' at ' + hms[0] + ' sec';
        }

    };
})

.filter('sortItems', ["bvItemSvc", function(bvItemSvc) {
    return bvItemSvc.sort;
}])

// item in array|orderByDisplay:sortField:reverse:mapDef
// (or if you're using sortable="order" where order is {field:"foo",reverse:false,map:{f:v}})
// item in array|orderByDisplay:order
//
// note, this also supports [foo,bar,baz] syntax, which orderBy *does not*

.filter('orderByDisplay', ["configService", "$parse", function(configService, $parse) {
    var mapBranchCode = function(input) { return configService.display(input,'branch'); };
    var mapTitle = function(input) {
        if (typeof(input) !== 'string') return input;
        return input.replace(/^(a|an|the) /i,'');
    };

    var defaultMap = {
        branch: mapBranchCode,
        branchcode: mapBranchCode,
        homebranch: mapBranchCode,
        holdingbranch: mapBranchCode,
        location: function(input) { return configService.display(input,'loc'); },
        ccode: function(input) { return configService.display(input,'ccode'); },
        itemtype: function(input) { return configService.display(input,'bv-itemtype')},
        'bib.title_ext': mapTitle,
        title: mapTitle,
    };
    var fallbackVal = {
        enumchron: 'serial.publication_date||enumchron'
            //item-specific.  Allows serial data to override if present.
    };
    // Modified from Angular
  return function(array, sortPredicate, reverseOrder, mapDef) {
    if (!(angular.isArray(array))) return array;
    function identity($) {return $;}
    identity.$inject = [];

    if (typeof(sortPredicate) == 'object' && 'field' in sortPredicate) {
      mapDef = sortPredicate.map;
      reverseOrder = sortPredicate.reverse;
      sortPredicate = sortPredicate.field;
    }
    if (!mapDef) mapDef = defaultMap;

    var m = sortPredicate.match(/^\[(.+)\]$/);
    if (m && m.length == 2) {
      sortPredicate = m[1].split(',');
    }

    sortPredicate = angular.isArray(sortPredicate) ? sortPredicate : [sortPredicate];
    if (sortPredicate.length === 0) { sortPredicate = ['+']; }
    sortPredicate = sortPredicate.map(function(predicate) {
      var descending = false, get = predicate || identity;
      if (angular.isString(predicate)) {
        if ((predicate.charAt(0) == '+' || predicate.charAt(0) == '-')) {
          descending = predicate.charAt(0) == '-';
          predicate = predicate.substring(1);
        }
        if (predicate === '') {
          // Effectively no predicate was passed so we compare identity
          return reverseComparator(compare, descending);
        }
        get = (fallbackVal[predicate]) ?
                $parse(fallbackVal[predicate]) : $parse(predicate);
        if (get.constant) {
          var key = get();
          return reverseComparator(function(a, b) {
            return compare(a[key], b[key], mapDef[key]);
          }, descending);
        }
      }

      return reverseComparator(function(a, b) {
        return compare(get(a),get(b), mapDef[predicate]);
      }, descending);

//unreachable...
      // if ((predicate.charAt(0) == '+' || predicate.charAt(0) == '-')) {
      //   descending = predicate.charAt(0) == '-';
      //   predicate = predicate.substring(1);
      // }
      // if (predicate === '') {
      //   // Effectively no predicate was passed so we compare identity
      //   return reverseComparator(compare, descending);
      // }
      // var key = predicate;    // No $parse
      // return reverseComparator(function(a, b) {
      //   return compare(a[key], b[key], mapDef[key]);
      // }, descending);
    });
    return [].slice.call(array).sort(reverseComparator(comparator, reverseOrder));

    function comparator(o1, o2) {
      for (var i = 0; i < sortPredicate.length; i++) {
        var comp = sortPredicate[i](o1, o2);
        if (comp !== 0) return comp;
      }
      return 0;
    }
    function reverseComparator(comp, descending) {
      return descending
          ? function(a, b) {return comp(b,a);}
          : comp;
    }

    function isPrimitive(value) {
      switch (typeof value) {
        case 'number': /* falls through */
        case 'boolean': /* falls through */
        case 'string':
          return true;
        default:
          return false;
      }
    }

    function objectToString(value) {
      if (value === null) return 'null';
      if (typeof value.valueOf === 'function') {
        value = value.valueOf();
        if (isPrimitive(value)) return value;
      }
      if (typeof value.toString === 'function') {
        value = value.toString();
        if (isPrimitive(value)) return value;
      }
      return '';
    }

    function compare(v1, v2, map) {
      if (map) {
          v1 = map(v1);
          v2 = map(v2);
      }
      var t1 = typeof v1;
      var t2 = typeof v2;
      if (t1 === t2 && t1 === "object") {
        v1 = objectToString(v1);
        v2 = objectToString(v2);
      }
      if (t1 === t2) {
        if (t1 === "string") {
           v1 = v1.toLowerCase();
           v2 = v2.toLowerCase();
        }
        if (v1 === v2) return 0;
        return v1 < v2 ? -1 : 1;
      } else {
        return t1 < t2 ? -1 : 1;
      }
    }
  };
}])
.filter('msTime', function() {
    return function(input, prec) {
        if (!prec) prec = 2;
        return (1000*input).toFixed(prec);
    };
})
.filter('toArray', function() {
    return function (obj) {
        var result = [];
        angular.forEach(obj, function (val, key) {
            result.push(val);
        });
        return result;
    };
})
.filter('ellipses', function() {
    return function(input, length) {
        if (typeof(input) !== 'string') return input;
        if (!length) length = 32;
        if (input.length > length)
            return input.substr(0,length) + '...';
        else
            return input;
    };
})

.filter('wcCoverage', function() {
    var parsedate = function(s) {
        var rv = s.match(/^P(\d+)M$/);
        if (rv && rv.length > 1)
            return rv[1] + " months ago";

        rv = s.match(/^P(\d+)Y$/);
        if (rv && rv.length > 1)
            return rv[1] + " years ago";

        rv = s.match(/^(\d+)\-(\d+)\-(\d+)$/);
        if (rv && rv.length > 1)
            return rv[2] + '/' + rv[3] + '/' + rv[1];

        rv = s.match(/^(\d+)$/);
        if (rv && rv.length > 1)
            return rv[1];

        return s;
    };

    return function(s) {
        if (!s) return s;
        var rv = '';
        var tc = s.split('@');
        if (tc[0] == 'fulltext')
            rv = 'Full Text ';
        else if (tc[0] == 'abstracts') 
            rv = 'Abstracts ';
        else
            rv = tc[0] + ' ';

        if (!tc[1] || tc[1] == '') 
            return rv + '- no start/end dates available';

        var se = tc[1].split('~');
        if (se[0] && !se[1]) 
            return rv + 'Starting ' + parsedate(se[0]);
        else if (se[1] && !se[0])
            return rv + 'Ending ' + parsedate(se[1]);
        else
            return rv + 'From ' + parsedate(se[0]) + ' to ' + parsedate(se[1]);
    };
})
.filter('wcCoverageEnum', function() {
    var parsevi = function(s) {
        var re = /[;:]/g;
        return s.replace(re, ' ');
    };

    return function(s) {
        if (!s) return s;
        var rv = '';
        var tc = s.split('@');
        if (tc[0] == 'fulltext')
            rv = 'Full Text ';
        else if (tc[0] == 'abstracts') 
            rv = 'Abstracts ';
        else
            rv = tc[0] + ' ';

        if (!tc[1] || tc[1] == '') 
            return rv + '- no start/end enumeration available';

        var se = tc[1].split('~');
        if (se[0] && !se[1]) 
            return rv + 'Starting ' + parsevi(se[0]);
        else if (se[1] && !se[0])
            return rv + 'Ending ' + parsevi(se[1]);
        else
            return rv + 'From ' + parsevi(se[0]) + ' to ' + parsevi(se[1]);
    };
})
.filter('circPolicyDescription', ["$filter", function($filter) {
    var ifNull = function(s,t) {
        return (s === undefined || s === null) ? (t || '(null)') : s;
    };
    var money = $filter('kohaMoney');

    return function(p) {
        if (typeof(p) !== 'object') return p;

        p = angular.copy(p);

        var atoms = [];

        // Checkouts

        var isHourly = (p.loan_type === 'hourly');
        if ('loan_type' in p) {
            atoms.push('Loan type: ' + ifNull(p.loan_type,'daily'));
            delete p.loan_type;
        }
        if (('issue_length' in p) && ('issue_length_unit' in p)) {
            if (p.issue_length === undefined || p.issue_length === null)
                atoms.push("Issue length: unlimited");
            else
                atoms.push("Issue length: " + ifNull(p.issue_length,'unlimited') + " " + ifNull(p.issue_length_unit,'days'));
            delete p.issue_length;
            delete p.issue_length_unit;
        }
        else if ('issue_length' in p) {
            if (p.issue_length === undefined || p.issue_length === null)
                atoms.push("Issue length: unlimited");
            else
                atoms.push("Issue length: " + ifNull(p.issue_length) + " (no units)");
            delete p.issue_length;
        }
        else if ('issue_length_unit' in p) {
            atoms.push("Issue length units: " + ifNull(p.issue_length_unit));
            delete p.issue_length_unit;
        }

        if (isHourly) {
            if ('hourly_incr' in p) {
                atoms.push('Hourly loan interval: ' + ifNull(p.hourly_incr) + ' min');
                delete p.hourly_incr;
            }

            if ('allow_overnight' in p) {
                atoms.push('Overnight loans: ' + (p.allow_overnight ? 'allowed' : 'not allowed'));
                delete p.allow_overnight;
            }

            if ('allow_over_closed' in p) {
                atoms.push('Loans over closed days: ' + (p.allow_over_closed ? 'allowed' : 'not allowed'));
                delete p.allow_over_closed;
            }

            if ('overnight_due' in p) {
                atoms.push('Time due:' + ifNull(p.overnight_due) + ' min before close');
                delete p.overnight_due;
            }

            if ('overnight_window' in p) {
                atoms.push('Overnight window: ' + ifNull(p.allow_over_closed) + ' min before close');
                delete p.overnight_window;
            }
        }
        else {
            delete p.hourly_incr;
            delete p.allow_overnight;
            delete p.allow_over_closed;
            delete p.overnight_due;
            delete p.overnight_window;
        }

        if (('grace_period' in p) && ('grace_period_unit' in p)) {
            atoms.push("Grace period: " + ifNull(p.grace_period) + " " + ifNull(p.grace_period_unit));
            delete p.grace_period;
            delete p.grace_period_unit;
        }
        else if ('grace_period' in p) {
            atoms.push("Grace period: " + ifNull(p.grace_period) + " (no units)");
            delete p.grace_period;
        }
        else if ('grace_period_unit' in p) {
            atoms.push("Grace period units: " + ifNull(p.grace_period_unit));
            delete p.grace_period_unit;
        }

        if (('fine_period' in p) && ('fine_period_unit' in p)) {
            atoms.push("Fine period: " + ifNull(p.fine_period) + " " + ifNull(p.fine_period_unit));
            delete p.fine_period;
            delete p.fine_period_unit;
        }
        else if ('fine_period' in p) {
            atoms.push("Fine period: " + ifNull(p.fine_period) + " (no units)");
            delete p.fine_period;
        }
        else if ('fine_period_unit' in p) {
            atoms.push("Fine period units: " + ifNull(p.fine_period_unit));
            delete p.fine_period_unit;
        }

        if ('maxissueqty' in p) {
            console.dir(p.maxissueqty_scope);
            if ('maxissueqty_scope' in p) {
                if (p.maxissueqty_scope === undefined || p.maxissueqty_scope === null)
                    atoms.push('Max checkouts: ' + ifNull(p.maxissueqty,'Unlimited'));
                else if (p.maxissueqty_scope === '')
                    atoms.push('Max checkouts: ' + ifNull(p.maxissueqty,'Unlimited') + ' overall');
                else
                    atoms.push('Max checkouts: ' + ifNull(p.maxissueqty,'Unlimited') + ' per ' + p.maxissueqty_scope);
            }
            else {
                atoms.push('Max checkouts: ' + ifNull(p.maxissueqty,'Unlimited') + ' per (scope from rule)');
            }
            delete p.maxissueqty;
            delete p.maxissueqty_scope;
        }

        // Renewals

        if ('maxrenewals' in p) {
            atoms.push('Max renewals: ' + ifNull(p.maxrenewals,"unlimited"));
            delete p.maxrenewals;
        }

        if (('recall_interval' in p) && ('recall_interval_unit' in p)) {
            atoms.push("Recall period: " + ifNull(p.recall_interval) + " " + ifNull(p.recall_interval_unit));
            delete p.recall_interval;
            delete p.recall_interval_unit;
        }
        else if ('recall_interval' in p) {
            atoms.push("Recall period:  " + ifNull(p.recall_interval) + " (no units)");
            delete p.recall_interval;
        }
        else if ('recall_interval_unit' in p) {
            atoms.push("Recall period units: " + ifNull(p.grecall_intervalunit));
            delete p.recall_interval_unit;
        }

        // Holds
        if ('holdallowed' in p) {
            if (p.holdallowed === 2 || p.holdallowed === "2") {
                atoms.push('Holds allowed from any branch in group');
            }
            else if (p.holdallowed === 1 || p.holdallowed === '1') {
                atoms.push('Holds allowed from owning branch only');
            }
            else {
                atoms.push('Holds not allowed');
            }
            delete p.holdallowed;
        }

        // Charges

        if ('rentalcharge' in p) {
            atoms.push('Rental charge: ' + money(p.rentalcharge));
            delete p.rentalcharge;
        }
        if ('replacement_fee' in p) {
            atoms.push('Replacement charge: ' + money(p.replacement_fee));
            delete p.replacement_fee;
        }
        if ('overdue_fine' in p) {
            atoms.push('Overdue charge: ' + money(p.overdue_fine));
            delete p.overdue_fine;
        }
        if ('maxfine' in p) {
            atoms.push('Max fine: ' + money(p.maxfine));
            delete p.maxfine;
        }

        if ('skip_fine_grace' in p) {
            atoms.push(p.skip_fine_grace ? 'Skip fine grace period' : 'Do not skip fine grace period');
            delete p.skip_fine_grace;
        }

        // Other
        if ('allow_callslip' in p) {
            atoms.push('Callslips: ' + (p.allow_callslip ? 'allowed' : 'not allowed'));
            delete p.allow_callslip;
        }
        
        if ('allow_doc_del' in p) {
            atoms.push('Document delivery requests: ' + (p.allow_doc_del ? 'allowed' : 'not allowed'));
            delete p.allow_doc_del;
        }
        

        if ('circ_termset_id' in p) {
            if (p.circ_termset_id !== null) {
                if (p.circ_termset_name) {
                    atoms.push('Termset: ' + p.circ_termset_name);
                    delete p.circ_termset_name;
                }
                else {
                    atoms.push('Termset #: ' + p.circ_termset_id);
                }
            }
            delete p.circ_termset_id;
        }

        delete p.policy_name;
        
        angular.forEach(p, function(val, key) {
            atoms.push("" + key + ": " + ifNull(val));
        });

        return atoms.join(', ');
    };
}]);


;
