/* global murmurhash3_32_gc */
(function() {
'use strict';

angular.module('kohastaff.services', [])
.provider('staffLegacyApp', ["$stateProvider", function staffLegacyAppProvider($stateProvider) {
    var self = this;

    this.widgets = {};
    this.widgetDock = [];
    this.widgetGroup = {};

    this.routeMatcher = {};
    this.routeMatcherSorted = false;

    this.defaultRouteEntered = false;

    this.appParameters = {};

    var registerRouteMatcher = function(path, def) {
        if (!self.routeMatcher[path])
            self.routeMatcher[path] = [];
        self.routeMatcher[path].push(def);
    };

    var parseUrlArgs = function(url) {
        var args = [];
        var paths = url.split('?');
        if (paths.length > 1) {
            angular.forEach(paths[1].split('&'), function(p) { args.push(p); });
        }
        // note: only gets `:param` syntax, misses `{param:type}` and others.
        paths[0].replace(/\/:\w+/g, function(p) { args.push(p.substring(2)); });

        return args;
    };

    var makeIframe = function(script, attrs, $stateParams, params) {
        var attrsStr = (attrs||[]).join(' ');
        if (script == '/acqui/getit.pl') {
            attrsStr = attrsStr + ' height-tracks-header';
        }
        var s = '<iframe id="staff-iframe" ' + attrsStr + ' seamless="seamless" src="/bvcgi' + script;
        var qp = [];
        angular.forEach(params, function(p) {
            if (typeof($stateParams[p]) != 'undefined' && ($stateParams[p] !== null)) {
                qp.push('' + p + '=' + encodeURIComponent($stateParams[p]));
            }
        });

        if (qp.length) {
            if (script.match(/\?/))
                s = s + '&';
            else
                s = s + '?';
            s = s + qp.join('&');
        }
        s = s + '" style="border: none; width: 100%; height: 80vh" ng-style="style()"></iframe>';
        // console.warn(s);
        return s;
    };

    this.widget = function(widgetName, widgetDef) {
        return this.appState(widgetName, {
            title: widgetDef.title,
            permissions: widgetDef.permissions,
            widget: widgetDef,
            registerState: false,
            matchRoute: false
        });
    };

    this.appState = function(appName, appDef) {

        var stateParameters = parseUrlArgs(appDef.url || '');

        var matches = appName.match(/^(.+)\./);
        if (matches && matches.length == 2) {
            var parentState = matches[1];
            if (this.appParameters[parentState]) {
                angular.forEach(this.appParameters[parentState], function(p) {
                    stateParameters.push(p);
                });
            }
        }

        this.appParameters[appName] = stateParameters;


        // ui-router state definition
        if (appDef.registerState !== false) {
            var permissions = appDef.permissions || {catalogue: {access: '*'}};
            var title = appDef.title || 'Staff App';

            var stateDef = {
                url: appDef.url,
                data: {
                    pageSubTitle: title
                },
                resolve: {
                    base: function() { return {} },
                    checkPermissions: ["userService", function(userService) {
                        return userService.whenAuthenticatedUserCan(permissions, null, {redirectOnFail: '/'});
                    }]
                }
            };

            if (appDef.resolve)
                angular.extend(stateDef.resolve, appDef.resolve);

            if (appDef.controller)
                stateDef.controller = appDef.controller;

            if (appDef.params)
                stateDef.params = appDef.params;

            if (appDef.reloadOnSearch)
                stateDef.reloadOnSearch = appDef.reloadOnSearch;

            if (appDef.templateProvider) {
                stateDef.templateProvider = appDef.templateProvider;
            }
            else if (appDef.templateUrl) {
                stateDef.templateUrl = '/app/static/partials/staff/' + appDef.templateUrl;
            }
            else if (appDef.template) {
                stateDef.template = appDef.template;
            }
            else if (appDef.legacyUrl) {
                stateDef.templateProvider = ["$stateParams", function($stateParams) {
                    //console.log("STATE PARAMS =");
                    //console.dir(stateParameters);
                    return makeIframe(appDef.legacyUrl, appDef.iframeAttrs, $stateParams, stateParameters);
                }];
            }
            else if (appDef.modal) {
                // ...
            }
            else {
                throw "staffLegacyAppProvider " + appName + ": app must provide a templateProvider, templateUrl, template, legacyUrl, or declare modal=true";
            }
            $stateProvider.state("staff."+appName, stateDef);
        }

        // widget and menu
        if (appDef.widget) {
            var widgetDef = angular.extend({}, {
                state: "staff." + appName,
                title: appDef.title,
                permissions: appDef.permissions,
                outerClass: 'staff-widget-outer',
            }, appDef.widget);

            widgetDef.addToSidebar = (widgetDef.addToSidebar === false ? false : true);
            widgetDef.widgetOuterClass = widgetDef.widgetOuterClass || 'staff-widget-outer';

            self.widgets["staff." + appName] = widgetDef;

            var base = appName.replace(/\..*/, '');
            //console.log("Appname " + appName + " => base " + base);
            if (!self.widgetGroup[base])
                self.widgetGroup[base] = [];
            self.widgetGroup[base].push(widgetDef);
            //console.dir(self.widgetGroup);

            if (appDef.widget.addToDock !== false)
                self.widgetDock.push("staff." + appName);
        }

        // route matcher
        if (appDef.matchRoute) {
            var path;
            if (typeof(appDef.matchRoute) == 'string') {
                registerRouteMatcher(appDef.matchRoute, {path: appDef.matchRoute, state: 'staff.'+appName});
            }
            else if (typeof(appDef.matchRoute) == 'object') {
                registerRouteMatcher(appDef.matchRoute.path, appDef.matchRoute);
            }

            else if (typeof(appDef.matchRoute) == 'function') {
                if (!appDef.legacyUrl) {
                    throw "staffLegacyAppProvider " + appName + ": function matchRoute must also provide legacyUrl";
                }
                path = '/bvcgi' + appDef.legacyUrl;
                registerRouteMatcher(path, {
                    priority: 10,
                    state: 'staff.'+appName,
                    searchFilter: appDef.matchRoute,
                    stateParameters: stateParameters,
                });
            }

            else if (appDef.legacyUrl && stateParameters.length) {
                path = '/bvcgi' + appDef.legacyUrl.replace(/\?.*/,'');

                registerRouteMatcher(path, {
                    priority: 10,
                    state: 'staff.'+appName,
                    searchFilter: function() { return true; },
                    stateParameters: stateParameters
                });
            }
            else if (appDef.legacyUrl) {
                path = '/bvcgi' + appDef.legacyUrl.replace(/\?.*/,'');

                registerRouteMatcher(path, {
                    priority: 0,
                    state: 'staff.'+appName
                });
            }
            else {
                throw "staffLegacyAppProvider " + appName + ": matchRoute requires explicit matcher object or legacyUrl";
            }
        }
        else if (appDef.matchRoute !== false) {
            throw "staffLegacyAppProvider " + appName + ": matchRoute is required";
        }

        if (!this.defaultRouteEntered) {
            this.defaultRouteEntered = true;
    
            $stateProvider.state('staff.default', {
                url: '/default?path&search',
                data: { pageSubTitle: 'Staff Application' },
                resolve: {
                    base: ["resolvedPermissions", function(resolvedPermissions) { return {} }],
                    checkPermissions: ["userService", function(userService) {
                        return userService.whenAuthenticatedUserCan({catalogue: {access: '*'}}, null, {redirectOnFail: '/'});
                    }]
                },
                //controller: something,
                templateProvider: ["$stateParams", function($stateParams) {
                    // Double encoded due to ui-router bug
                    var decodedPath = $stateParams.path.replace(/\%2F/, '/');
                    var s = '<div class="alert alert-warning">You have reached a legacy staff application which '
                          + 'has not been registered in the route table. The legacy app path is '
                          + $stateParams.path + ' and the query arguments are ' + $stateParams.search + '</div>'
                          + '<iframe height-tracks-header seamless="seamless" src="/bvcgi/' + $stateParams.path;
                    if ($stateParams.search)
                        s = s + $stateParams.search;
                    s = s + '" style="border: none; width: 100%; height: 80vh" ng-style="style()"></iframe>';
                    return s;
                }]
            });

        }

        return self;
    };


    this.$get = ["$state", "$location", "$rootScope", "$timeout", "staffRedirectService", function staffLegacyAppService($state, $location, $rootScope, $timeout, staffRedirectService) {
        var svc = {};

        svc.getWidgets = function(module) {
            return self.widgetGroup[module];
        };

        svc.getWidgetSidebar = function(module) {
            var widgets = self.widgetGroup[module];
            return jQuery.grep(widgets, function(w) { return (w.addToSidebar !== false); });
        };

        svc.getWidgetGroup = function(module, group) {
            var widgets = self.widgetGroup[module];
            return jQuery.grep(widgets, function(w) { return (w.group == group) });
        };

        svc.getWidgetGroups = function(module) {
            var widgets = self.widgetGroup[module];
            //console.log("Get widget groups");
            //console.dir(widgets);
            var grouped = {};
            angular.forEach(widgets, function(w) {
                if (!grouped[w.group])
                    grouped[w.group] = [];
                grouped[w.group].push(w);
            });
            return grouped;
        };

        svc._matchDefaultRoute = function(evnt, doStateChange) {
            var path = evnt.path;
            if (!path.match(/^\/cgi-bin\/koha/))
                return false;

            path = path.replace(/^\/cgi-bin\/koha\//, '');

            // We shouldn't have to do this, but apparently there's a subtle bug in ui-router which double-encodes slashes
            path = encodeURIComponent(path);

            //console.dir(path);
            //console.dir(evnt.search);
            var newParams = {search: evnt.search, path: path};
            //console.log("Comparing");
            //console.dir(newParams);
            //console.dir($state.params);

            if (('staff.default' != $state.current.name) || !angular.equals(newParams, ($state.params||{}))) {
                if (doStateChange) {
                    //console.log("================================");
                    //console.dir(angular.copy(path));
                    $state.go('staff.default', newParams, {inherit: false, lossy: false});
                    //console.dir(angular.copy($state.params));
                    //console.log("=------------------------------=");
                }
                else {
                    var url = $state.href('staff.default', {search: evnt.search, path: path}, {inherit: false, lossy: false});
                    //console.log("Silent transition from " + $location.url() + " to url=" + url);
                    if (/batch\-manager.*op=add\&/.test(url)) {
                        console.log("Suppressing rewrite for batch-manager mutating GET");
                    }
                    else {
                        staffRedirectService.rewriteLocation(url);
                    }
                }
                return true;
            } else if (doStateChange) {
                $state.reload();
            }
            else {
                //console.log("No state change, and no need to update history");
            }
        };

        // Route matcher. If we match a registered route, change state and return true.
        svc.matchRoute = function(evnt, doStateChange, withKludge) {
            if (!svc.routeMatcherSorted) {
                angular.forEach(svc.routeMatcher, function(def, key) {
                    svc.routeMatcher[key] = def.sort(function(a,b) { return (b.priority - a.priority) });
                });
                svc.routeMatcherSorted = true;
            }

            var matches = self.routeMatcher[evnt.path];

            if (!matches)
                return svc._matchDefaultRoute(evnt, doStateChange);

            var queryParams = {};
            if (evnt.search) {
                //should handle params delimited by semicolon,as is the case with CGI's url()
                var components = evnt.search.substr(1).split(/&|;/);
                angular.forEach(components, function(c) {
                    var fv = c.split('=');
                    queryParams[fv[0]] = decodeURIComponent(fv[1]);
                });
            }

            var matched;

            function cleanParams (params) {
                var newParams = {};
                Object.keys( params ).forEach(function(p){
                    if( p != '#' && params[p] !== undefined)
                        newParams[p] = params[p];
                });
                return newParams;
            }

            for (var i=0; i<matches.length && !matched; i++) {
                if (matches[i].searchFilter) {
                    var qp = angular.copy(queryParams);
                    //console.log("Calling match function with state = " + matches[i].state + " and qp = " + JSON.stringify(qp));
                    if (qp._signal) {
                        $rootScope.$emit(qp._signal);
                        delete qp._signal;
                    }

                    matched = matches[i].searchFilter(qp);
                    // console.dir(matched);
                    if (matched === true) {
                        var params = {};
                        angular.forEach(matches[i].stateParameters, function(param) {
                            //console.log("Attempting to consume " + param);
                            if (param in qp) {
                                params[param] = qp[param];
                                delete qp[param];
                            }

                        });
                        matched = {
                            state: matches[i].state,
                            params: params
                        };
                        // console.log("Got a match");
                        // console.dir(matched);

                        if (Object.keys(qp).length) {
                            // console.log("Unconsumed parameters remain in query parameters");
                            // console.dir(qp);
                            matched = false;
                        }
                    }
                }
                else if (matches[i].state && !evnt.search) {
                    matched = {state: matches[i].state};
                }

                if (matched) {
                    var stateParams = cleanParams( $state.params );
                    if ((matched.state != $state.current.name) || !angular.equals((matched.params||{}), (stateParams||{}))) {
                        console.log("Update state: " + $state.current.name + " => " + matched.state);
                        console.log("Update params: " + JSON.stringify(stateParams) + " => " + JSON.stringify(matched.params));
                        // Force state change if we need to change the staff sidebar
                        var s1 = $state.current.name.split('.');
                        var s2 = matched.state.split('.');
                        if (s1[0] != s2[0] || s1[1] != s2[1])
                            doStateChange = true;

                        if (doStateChange) {
                            // console.log("Explicit state change to " + matched.state + " with params...");
                            // console.dir(matched.params);
                            // console.log($state.href(matched.state, matched.params, {inherit: false}));
                            $state.go(matched.state, matched.params, {inherit: false});
                        }
                        else {
                            // if current state PATH params are missing, restore them.
                            // this can happen on POSTs (specifically circulation.pl)
                            if(matched.state == $state.current.name){
                                angular.forEach(stateParams, function(paramval, param){
                                    if((!$state.$current.params[param] || $state.$current.params[param].location == 'path') && !matched.params[param] ){
                                        console.warn("restoring path param " + param + " as " + paramval);
                                        matched.params[param] = paramval;  // might be preferable to just not do transition ?
                                    }
                                });
                            }
                            var paramMap = {};
                            angular.forEach(matched.params, function(value,key) { paramMap[key] = value; });
                            // console.dir(paramMap);
                            var url = $state.href(matched.state, paramMap, {inherit: false, lossy: false});
                            console.log("Silent transition from " + $location.url() + " in state " + matched.state + " to url=" + url);
                            if (/batch\-manager.*op=add\&/.test(url)) {
                                console.log("Suppressing rewrite for batch-manager mutating GET");
                            }
                            else if (/patron\-lists\-bulkedit\-confirm/.test(url)) {
                                console.log("Suppressing rewrite for patron-lists-bulkedit-confirm GET");
                            }
                            else if (/\/app\/staff\/lists\?display\=privateshelves/.test($location.url())) {
                                console.log('Suppressing rewrite for /app/staff/lists?display=privateshelves');
                            }
                            else if (/\/app\/staff\/patrons\/bulk-edit\?param_borrower_list\=/.test(url)) {
                                console.log('Suppressing rewrite for /app/staff/patron/bulk-edit?patram_borrower_list=...');
                            }
                            else if (/\/app\/staff\/bib\/\d+\/merge\?confirm_bib_merge\=Accept/.test(url)) {
                                console.log('Suppressing rewrite for /app/staff/bib/.../merge?confirm_bib_merge=Accept...');
                            }
                            else {
                                staffRedirectService.rewriteLocation(url);
                            }
                        }
                    } else if (doStateChange) {
                        $state.reload();
                    }
                    else {
                        console.log("No state change, and no need to update history");
                    }
                    return true;
                }
            }

            if (!withKludge && evnt.search.match(/\&amp;/)) {
                console.log("========== Repeating match attempt with kludge =========");
                var newSearch = evnt.search.replace(/\&amp;/g,'&');
                return svc.matchRoute({path:evnt.path, search: newSearch}, doStateChange, true);
            }
            return svc._matchDefaultRoute(evnt, doStateChange);
        };

        return svc;
    }];

    return this;
}])

.factory('staffRedirectService', ["$location", "$timeout", function($location, $timeout) {
    var svc = {
        tickets: {},
        ticketCount: 0,
        debug: false
    };


    svc.getTicket = function(mask) {
        if (!mask) mask=1;
        svc.ticketCount++;
        svc.tickets[svc.ticketCount] = mask;
        return svc.ticketCount;
    };

    svc.consumeTicket = function(ticketId, mask) {
        if (!mask) mask=1;
        if (!(ticketId in svc.tickets))
            return false;
        if ((svc.tickets[ticketId] & mask) != mask) {
            delete svc.tickets[ticketId];
            return false;
        }
        svc.tickets[ticketId] = svc.tickets[ticketId] & (~mask);
        if (svc.tickets[ticketId] == 0)
            delete svc.tickets[ticketId];

        return true;
    };

    svc.hash = function(s) {
        return murmurhash3_32_gc(s,0);
    };

    svc.suppressLocationChange = function(cur,next) {
        //console.log("SPR: attempt to suppress location " + cur + " => " + next);

        var m = cur.match(/_spr=(\d+)\.([^\&]+)/);
        if (!m) {
            //console.log("SPR: false (no URI match)");
            return false;
        }

        var ticket = m[1];
        var referer = decodeURIComponent(m[2]);

        if (!svc.consumeTicket(ticket, 2)) {
            //console.log("SPR: false (ticket already consumed)");
            return false;
        }

        if (referer == svc.hash($location.url(),0)) {
            //console.log("SPR: true (hash match)");
            return true;
        }
        else {
            //console.log("SPR: false (hash mismatch)");
            return false;
        }
    };

    svc.suppressStateChange = function(fromState, fromParams, toState, toParams) {
        if(svc.debug) console.log("SPR: attempt to suppress state " + fromState.name + ':' + JSON.stringify(fromParams)
                    + " => " + toState.name + ':' + JSON.stringify(toParams));

        var url = $location.url();
        var m = url.match(/_spr=(\d+)\.([^\&]+)/);
        if (!m) {
            if(svc.debug) console.log("SPR: false (no URI match)");
            return false;
        }

        var ticket = m[1];
        if (svc.consumeTicket(ticket, 1)) {
            if (!angular.equals(fromParams,toParams)) {
                 if(svc.debug) console.log("SPR: false (parameter mismatch)");
                return false;
            } else {
                 if(svc.debug) console.log("SPR: true (ticket OK)");
                return true;
            }
        }
        else {
            if(svc.debug) console.log("SPR: false (ticket already consumed)");
            return false;
        }
    };

    svc.rewriteLocation = function(url) {
        if(svc.debug) console.log("RL URL = " + url);

        url = url + (url.match(/\?/) ? '&' : '?');
        var ticket = svc.getTicket(3);

        var referer = encodeURIComponent(svc.hash($location.url()));

        url = url + '_spr=' + ticket + '.' + referer;
        if(svc.debug) console.log("NEW URL + " + url);
        // return;

        $timeout(function() {
            if(svc.debug) console.log("SPR: pushing state " + url);
            if (window.history && window.history.replaceState)
                window.history.replaceState({postRoute: true}, "Transient POST state", url);
            else
                History.replaceState({postRoute: true}, "Transient POST state", url);

        }, 0);
    };

    return svc;
}])


// This is mostly taken from angular ngInclude source, which it resembles
// Note the use of two functions on the same directive

.directive('staffWidget', ["$sce", "$templateRequest", "$animate", function($sce, $templateRequest, $animate) {
    return {
        priority: 400,
        terminal: true,
        transclude: 'element',
        controller: angular.noop,
        compile: function(elem, attr) {
            var widgetExp = attr.staffWidget;

            return function(scope, $element, $attr, ctrl, $transclude) {
                var changeCounter = 0,
                    currentScope,
                    previousElem,
                    currentElem;

                // Maybe not necessary in our use case
                var cleanupLastIncludeContent = function() {
                    if (previousElem) {
                        previousElem.remove();
                        previousElem = null;
                    }
                    if (currentScope) {
                        currentScope.$destroy();
                        currentScope = null;
                    }
                    if (currentElem) {
                        $animate.leave(currentElem).then(function() {
                            previousElem = null;
                        });
                        previousElem = currentElem;
                        currentElem = null;
                    }
                };

                scope.$watch(widgetExp, function(widget) {
                    var thisChangeId = ++changeCounter;
                    if (!widget) {
                        //console.log("Request for unrecognized staff widget " + scope.$eval('w'));
                        return;
                    }

                    var titleClass = widget.titleClass || 'staff-widget-title';
                    if (widget.templateUrl) {
                        $templateRequest($sce.trustAsResourceUrl(widget.templateUrl)).then(function(resp) {
                            var newScope = scope.$new();
                            if (widget.addTitle) {
                                ctrl.template = '<div class="' + titleClass + '">{{' + widgetExp + '.title}}</div>' + resp;
                            }
                            else {
                                ctrl.template = resp;
                            }
                            
                            // See comment about scope pollution in angular ngInclude source
                            var clone = $transclude(newScope, function(clone) {
                                cleanupLastIncludeContent();
                                $animate.enter(clone, null, $element);
                            });
                            currentScope = newScope;
                            currentElem = clone;
                            
                            //currentScope.$emit($includeContentLoaded, widget.templateUrl);
                        }, function() {
                            if (thisChangeId === changeCounter) {
                                cleanupLastIncludeContent();
                            }
                        });
                    }
                    else {
                        var newScope = scope.$new();
                        var tmpl = widget.template || 
                            '<div class="' + titleClass + '">'
                            + '<a ui-sref="{{' + widgetExp + '.state}}" ui-sref-opts="{inherit: false, reload: true}" >'
                            + '{{' + widgetExp + '.title}}</a></div>'
                            + '<div class="staff-widget-description">{{' + widgetExp + '.description}}</div>';
                        if (widget.addTitle) {
                            ctrl.template = '<div class="' + titleClass + '">{{' + widgetExp + '.title}}</div>' + tmpl;
                        }
                        else {
                            ctrl.template = tmpl;
                        }
                        var clone = $transclude(newScope, function(clone) {
                            cleanupLastIncludeContent();
                            $animate.enter(clone, null, $element);
                        });
                        currentScope = newScope;
                        currentElem = clone;
                    }
                });
            };
        }
    };
}])

.directive('staffWidget', ["$compile", function ($compile) {
    return {
        priority: -400,
        require: 'staffWidget',
        link: function(scope, $element, $attr, ctrl) {
            if (/SVG/.test($element[0].toString())) {
                // WebKit: https://bugs.webkit.org/show_bug.cgi?id=135698
                // support innerHTML, so detect this here and try to generate the contents
                // specially.
                $element.empty();
                $compile(jqLiteBuildFragment(ctrl.template, document).childNodes)(scope,
                    function namespaceAdaptedClone(clone) {
                        $element.append(clone);
                    }, {futureParentElement: $element});
                  return;
            }

            $element.html(ctrl.template);
            $compile($element.contents())(scope);
        }
    };
}])

.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];
        if (angular.isArray(items)) {
            items.forEach(function(item) {
                var itemMatches = false;
                var keys = Object.keys(props);
                for (var i=0; i<keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }
                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            out = items;
        }
        return out;
    }
});


function jqLiteBuildFragment(html, context) {
 
/* eslint-disable no-undef */
// fixme: test undefs.
  var tmp, tag, wrap,
      fragment = context.createDocumentFragment(),
      nodes = [], i;

  if (jqLiteIsTextNode(html)) {
    // Convert non-html into a text node
    nodes.push(context.createTextNode(html));
  } else {
    // Convert html into DOM nodes
    tmp = tmp || fragment.appendChild(context.createElement("div"));
    tag = (TAG_NAME_REGEXP.exec(html) || ["", ""])[1].toLowerCase();
    wrap = wrapMap[tag] || wrapMap._default;
    tmp.innerHTML = wrap[1] + html.replace(XHTML_TAG_REGEXP, "<$1></$2>") + wrap[2];

    // Descend through wrappers to the right content
    i = wrap[0];
    while (i--) {
      tmp = tmp.lastChild;
    }

    nodes = concat(nodes, tmp.childNodes);

    tmp = fragment.firstChild;
    tmp.textContent = "";
  }

  // Remove wrapper from fragment
  fragment.textContent = "";
  fragment.innerHTML = ""; // Clear inner HTML
  forEach(nodes, function(node) {
    fragment.appendChild(node);
  });

  return fragment;
}
                        

// XXX - we chould probably push an intermediate warning state after a POST operation

/*
window.addEventListener('popstate', function(event) {
    if (event.state && event.state.postRoute) {
        // do something useful
    }
}, false);
*/

})();
