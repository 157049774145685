 
"use strict";

angular.module('kohapac.kwLuceneParser', [])

.service('kwLuceneParser', function(){

    if(!window.kwLuceneParser)
      throw( "No query parser found.");

    var parser = window.kwLuceneParser;
    // kwLuceneParser is generated from lucene-query-parser.pegjs grammar def.
    // use scripts/make-lucene-parser.sh

    this.parse = function(q){
      try {
        return parser.parse(q);
      } catch(e) {
        console.warn(e);
        return [{
          field: null,
          term: q
        }]
      }
    }
    this.SyntaxError = parser.SyntaxError;

    var self = this;

    this.stringify = function(parsed_q){
            if(!Array.isArray(parsed_q)) throw "Invalid parsed query object";
            return parsed_q.map(function(q){ return stringifyNode(q); }).join(' ');
        };

    this.extractSubqueries = function(q_str){
            // simplified subquery parsing.  returns a non-nested array of field queries with optional operator.
            var parsed;
            try{
                parsed = this.parse(q_str);
            } catch (e){
                console.warn(e);
                return {
                  op: 'OR',
                  subqueries: [ { field: null, q: q_str }]
                };
            }
            var op;
            var qterms;
            if(parsed.length==1 && parsed[0].op){
                op = parsed[0].op;
                qterms = parsed[0].terms;
            } else {
                qterms = parsed;
            }
            var subqueries = [];

            return {
                op: op,
                subqueries: qterms.map(function(sq){
                    var as_str = stringifyNode(sq);
                    as_str = as_str.replace(sq.field + ':','');
                    return { field: sq.field, q: as_str };
                })
            };

        };
    this.composeSubqueries = function(subqueries){
            // we ignore the op, assuming we pass it as a query param for now.
            var query_array = [];

            subqueries.forEach(function(sq){
                if(sq.q){
                    try{
                        query_array.push( { field: sq.field, q: self.parse(sq.q) } );
                    } catch(e) {
                        console.warn(e);
                    }
                }
            });
            return self.stringify(query_array);
        };

    this.extractTerms = function(parsed_q){
          if(!Array.isArray(parsed_q)) throw "Invalid parsed query object";
          var terms = [];
          parsed_q.forEach(function(q){ getTerms(q, terms); });
          return terms;
      };

    function stringifyNode(node){
        // a node is either a field expr (or set of them)
        // or a term expr.
        var n = angular.copy(node);

        var str = '';
        if('q' in n){

            // field expression.
            if(n.field){
                str = (n.prefix||'') + n.field + ':';
            }

            var parens = true;
            if( ! Array.isArray(n.q)){
                parens = false;
                n.q = [ n.q ];
            }

            var terms = n.q.map(function(q){ return stringifyNode(q); }).join(' ');
            if(n.q.length > 1) terms = "(" + terms + ")";
            str += terms;
        } else if('terms' in n){
            // term group expr
            str += n.terms.map( function(t){ return stringifyNode(t); }).
                    join( (n.op) ? (' '+n.op+' ') : ' ');
            if(n.parens) str = "("+str+")";
        } else {
            // term expr

            str = n.term;
            if(n.quotes) str = '"'+str+'"';
            if(n.proximity||n.similarity) str += ("~"+n.proximity||n.similarity);
            if(n.boost) str += '^'+n.boost;
            if(n.prefix) str = n.prefix + str;
        }
        return str;
    }

    // get all query terms from parsed query, without fields (for term highlighting)
    function getTerms ( node, termlist){
      if(!termlist) termlist = [];
      var n = angular.copy(node);
        if('q' in n){  // field expression.
            var nq = Array.isArray(n.q) ? n.q : [ n.q ];
            nq.forEach( function(q){  getTerms(q, termlist); } );
        } else if('terms' in n){ // term group expr
            n.terms.forEach( function(t){  getTerms(t, termlist); });
        } else {  // term expr
          termlist.push( n.term );
          // may need to get rid of operators ??
        }
        return termlist;
    }

});
