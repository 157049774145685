(function () {
'use strict';

angular.module('kohaacqlists', ['ngResource'])
.controller('UserAcqListsCtrl', ["$scope", "acqListsModel", "acqListControlsAPI", "resolvedAcqLists", "userService", "$uibModal", "$state", "loading", "alertService", function($scope, acqListsModel, acqListControlsAPI, resolvedAcqLists,
            userService, $uibModal, $state, loading, alertService) {

    $scope.user = userService;
    $scope.lists = resolvedAcqLists;

    $scope.$on('sys.signal.ItemListHoldings', function(evnt, args) { 
        // TODO this doesn't actually do anything yet
        angular.forEach($scope.lists, function(elem) {
            if (elem.id == args.item_list_id) {
                $scope.$class = 'row-success';
            }
        });
    });

    $scope.reload = function() {
        loading.add();
        acqListsModel.forUser({user: userService.id}).$promise.then(function(rv) {
            loading.resolve();
            $scope.lists.replaceWith(rv);
        }, function() {
            loading.resolve();
        });
    };

    $scope.details = function(entries) {
        $state.go('me.acqlists-details', {id: entries[0].id});
    };

    $scope.create = function() {
        acqListControlsAPI.createList().then($scope.reload);
    };

    $scope.update = function(entries) {
        acqListControlsAPI.editList(entries[0]).then($scope.reload);
    };

    $scope.copy = function(sels) {
        acqListControlsAPI.copyList(sels[0]).then($scope.reload);
    };

    $scope.delete = function(sels) {
        return acqListControlsAPI.deleteLists(sels).then($scope.reload);
    };

    $scope.purge = function(sels) {
        return acqListControlsAPI.purgeLists(sels).then($scope.reload);
    };
    
    $scope.cancel = function(sels){
       return acqListControlsAPI.cancelLists(sels).then($scope.reload);
    }
    
    $scope.remind = function(sels){
        return acqListControlsAPI.remindLists(sels).then($scope.reload);
    }
    
    $scope.renew = function(sels){
        var needsCheck = false;
        var ids = [];
        angular.forEach(sels, function(sel) { ids.push(sel.id); });
        acqListsModel.getAllEntries({ids: ids}).$promise.then(function(rv) {
            $uibModal.open({
                templateUrl: '/app/static/partials/user/acqlists/renew-selected.html',
                controller: 'UserAcqListsRenewCtrl',
                backdrop: false,
                size: 'lg',
                backdropClick: false,
                resolve: {
                    datum: function() { return {listitems : sels, hash : rv.hash }; },
                    needsCheck: function() { return needsCheck; },
                    renewLists: function() {
                        return function(obj) {
                            loading.add();
                            var ids = [];
                            var names = [];
                            
                            angular.forEach(obj.entries, function(entry) { 
                                
                                if(entry.item && entry.renewable){
                                    ids.push(entry.id);
                                    names.push(entry.name);
                                }

                            });
                            var payload = {ids: ids, names : names, notify : obj.notify};
                            acqListsModel.renewAll(payload).$promise.then(function() {
                                loading.resolve();
                                $scope.reload();
                            }, function(err) {
                                loading.resolve();
                                alertService.addApiError(err,'Merge failed');
                                $scope.reload();
                            });
                        }
                    }
                }
            });
        }, function() {
            loading.resolve();
        });
        

    }
    
    $scope.merge = function(sels){
        var needsCheck = false;
        $uibModal.open({
            templateUrl: '/app/static/partials/user/acqlists/merge-selected.html',
            controller: 'UserAcqListsMergeCtrl',
            backdrop: false,
            size: 'lg',
            backdropClick: false,
            resolve: {
                entries: function() { return sels; },
                needsCheck: function() { return needsCheck; },
                mergeLists: function() {
                    return function(obj) {
                        loading.add();
                        var ids = [];
                        angular.forEach(sels, function(sel) { ids.push(sel.id); });
                        acqListsModel.mergeAll({ids: ids, purge : obj.purge ? 1 : null, default_id : obj.radioModel == "New" ? null : obj.default_id, name : obj.radioModel == "Existing" ? null :obj.name}).$promise.then(function() {
                            loading.resolve();
                            $scope.reload();
                        }, function(err) {
                            loading.resolve();
                            alertService.addApiError(err,'Merge failed');
                            $scope.reload();
                        });
                    }
                }
            }
        });
    }

    $scope.share = function(sels) {
        return acqListControlsAPI.shareList(sels[0]).then($scope.reload);
    };

    $scope.holdings = function(sels) {
        return acqListControlsAPI.getListHoldings(sels[0]);
    };

    $scope.discounts = function(sels) {
        return acqListControlsAPI.getListDiscounts(sels[0]);
    };

    $scope.checkout = function(sels) {
        return acqListControlsAPI.checkout(sels[0]).then($scope.reload);
    };

    $scope.setStatus = function(sels) {
        return acqListControlsAPI.setStatus(sels);
    };

    $scope.export = function(exportMethod) {
        return function(sels) {
            return acqListControlsAPI.export(sels, exportMethod);
        };
    };

    $scope.acqlists_simple_view_pref = userService.prefs.acqlists_simple_view;

    $scope.single_list_mode = function () {
        var filtered = {};
        filtered['acqlists_simple_view'] = true;

        userService.setAllPrefs(filtered)
            .then( function () {
                var list_id, openList, deferred_promise;

                while ( openList = $scope.lists.shift() ) { // eslint-disable-line no-cond-assign
                    if ( openList.status === 'open' ) {
                        break;
                    }
                    else {
                        openList = undefined;
                    }
                }

                if ( !openList ) {
                    deferred_promise = acqListControlsAPI.createList();
                    deferred_promise.then( function (list) {
                        $state.go('me.acqlists-details', { id: list.id });
                    }, function (reason) {
                        console.error('New list creation failed: ' + reason);
                    });
                }
                else {
                    list_id = openList.id;
                    $state.go('me.acqlists-details', { id: list_id });
                }
            }, function (error) {
                console.error("Preference not saved: acqlists_simple_view: " + error);
            });
    }

}])

.controller('UserAcqListsButtonCtrl', ["$scope", "acqListControlsAPI", "userService", "$state", "resolvedAcqLists", function($scope, acqListControlsAPI, userService, $state, resolvedAcqLists) {
    $scope.lists = resolvedAcqLists;

    if ( !userService.prefs.acqlists_simple_view ) {
        $state.go('me.acqlists');
    }
    else {
        var list_id, openList;

        while ( openList = $scope.lists.shift() ) { // eslint-disable-line no-cond-assign
            if ( openList.status === 'open' ) {
                break;
            }
            else {
                openList = undefined;
            }
        }

        if ( !openList ) {
            var deferred_promise = acqListControlsAPI.createList();
            deferred_promise.then( function (e) {
                $state.go('me.acqlists-details', { id: e.id });
            }, function(reason) {
                console.error('New list creation failed: ' + reason);
            });
        }
        else {
            list_id = openList.id;
            $state.go('me.acqlists-details', { id: list_id });
        }
    }
}])

.controller('UserAcqListDetailsCtrl', ["$scope", "$state", "$stateParams", "acqListsModel", "resolvedAcqList", "resolvedAcqListEntries", "userService", "loading", "alertService", "$uibModal", "acqListControlsAPI", "acqListUtilAPI", function($scope, $state, $stateParams, acqListsModel, resolvedAcqList, resolvedAcqListEntries, userService, loading, alertService, $uibModal, acqListControlsAPI, acqListUtilAPI) {

    $scope.acqlists_simple_view_pref = userService.prefs.acqlists_simple_view;

    $scope.user = userService;
    $scope.list = resolvedAcqList;
    $scope.list.badSubscription = null;
    $scope.months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    $scope.loadCustomData = function(entry){
        if(!entry.sub_start_month || !entry.sub_start_year || !entry.sub_renewal_month || !entry.sub_end_year){
            $scope.list.badSubscription = true;
        }
        entry.subscriptionDate = (entry.sub_start_month && entry.sub_start_year) ? $scope.months[entry.sub_start_month-1] + "/" + entry.sub_start_year : "" ;
        entry.renewalMonth = entry.sub_renewal_month ? $scope.months[entry.sub_renewal_month-1] : "";
    }
    $scope.entries = resolvedAcqListEntries;
    if($scope.list.type == "UPAK UPR"){
        $scope.list.badSubscription = null;
        for(var i = 0; i < $scope.entries.length ; i++){
            var currEntry = $scope.entries[i];
            $scope.loadCustomData($scope.entries[i]);
                
        }
    }
    $scope.queryScope = 'all';
    $scope.$watch('queryScope', function(newVal, oldVal) {
        if (oldVal && (newVal !== oldVal)) {
            $scope.reload();
        }
    });


    $scope.reload = function() {
        loading.add();
        acqListsModel.entries({id: $stateParams.id, scope: $scope.queryScope}).$promise.then(function(rv) {
            loading.resolve();
            $scope.list.badSubscription = null;
            if($scope.list.type == "UPAK UPR"){
                for(var i = 0; i < rv.length; i++){
                    $scope.loadCustomData(rv[i]);
                }
            }
            $scope.entries.replaceWith(rv);
        }, function() {
            loading.resolve();
        });
    };


    $scope.$on('sys.signal.ItemListHoldings', function(evnt, args) { 
        if (args.item_list_id == $scope.list.id) {
            //console.log("Reloading");
            $scope.reload();
        }
    });

    $scope.reloadList = function() {
        loading.add();
        acqListsModel.details({id: $stateParams.id}).$promise.then(function(rv) {
            loading.resolve();
            $scope.list.name = rv.name;
            $scope.list.type = rv.type;
        }, function() {
            loading.resolve();
        });
    };

    $scope.listUpdate = function() {
        acqListControlsAPI.editList($scope.list).then($scope.reloadList);
    };

    $scope.listCopy = function() {
        acqListControlsAPI.copyList($scope.list).then($scope.reloadList);
    };

    $scope.listShare = function() {
        return acqListControlsAPI.shareList($scope.list).then($scope.reloadList);
    };

    $scope.listHoldings = function() {
        return acqListControlsAPI.getListHoldings($scope.list);
    };

    $scope.listDiscounts = function() {
        return acqListControlsAPI.getListDiscounts($scope.list);
    };

    $scope.listCheckout = function() {
        return acqListControlsAPI.checkout($scope.list);
    };

    $scope.listExport = function(exportMethod) {
        return acqListControlsAPI.export([$scope.list], exportMethod);
    };

    $scope.delete = function(sels) {
        loading.add();
        var ids = [];
        angular.forEach(sels, function(sel) { ids.push(sel.id); });
        acqListsModel.deleteAllEntries({id: $scope.list.id},{ids: ids}).$promise.then(function() {
            loading.resolve();
            $scope.reload();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Delete failed');
            $scope.reload();
        });
    };
    
 
    
    $scope.subupdate = function(sels){
        $uibModal.open({
            templateUrl: '/app/static/partials/user/acqlists/update-subscriptions.html',
            controller: 'UserAcqListsUpdateSubscriptionsCtrl',
            backdrop: false,
            size: 'lg',
            backdropClick: false,
            resolve: {
                entries: function() { return sels; },
                updateSubscriptions: function() {
                    return function(obj) {
                        loading.add();
                        var ids = [];
                        angular.forEach(sels, function(sel) { ids.push(sel.id); });
                        acqListsModel.updateAllEntries({id: $scope.list.id},{ids: ids, data : obj}).$promise.then(function() {
                            loading.resolve();
                            $scope.reload();
                        }, function(err) {
                            loading.resolve();
                            alertService.addApiError(err,'Update failed');
                            $scope.reload();
                        });
                    }
                }
            }
        });
    }
    $scope.isSub = $scope.list.type == "UPAK UPR" ? true : false ; 
    $scope.holdings = function(sels) {
        var needsCheck = false;
        angular.forEach(sels, function(sel) {
            if (sel.holdings_json) {
                sel.$holdings = acqListUtilAPI.transformHoldingsEntry(sel.holdings_json);
            }
            else {
                needsCheck = true;
            }
        });

        $uibModal.open({
            templateUrl: '/app/static/partials/user/acqlists/holdings-results.html',
            controller: 'UserAcqListsHoldingsCtrl',
            backdrop: false,
            size: 'lg',
            backdropClick: false,
            resolve: {
                entries: function() { return sels; },
                needsCheck: function() { return needsCheck; },
                updateRows: function() {
                    return function(recs) {
                        var update = {};
                        angular.forEach(recs, function(r) {
                            update[r.id] = r;
                        });
                        angular.forEach($scope.entries, function(r) {
                            if (update[r.id]) {
                                r.holdings_json = update[r.id]._embed.holdings_json;
                                r.holdings_check_date = update[r.id]._embed.holdings_check_date;
                            }
                        });
                    }
                }
            }
        });
    };

    $scope.multi_list_mode = function () {
        var filtered = {};
        filtered['acqlists_simple_view'] = false;

        userService.setAllPrefs(filtered)
            .then( function () {
                $state.go('me.acqlists');
            }, function (e) {
                console.error("Preference not saved: acqlists_simple_view: " + e);
            });
    }
}])

.controller('UserAcqSubsCtrl', ["$scope", "resolvedOrders", "configService", function($scope, resolvedOrders, configService) {

    $scope.title = configService.AcqList.wording.activeSubs || 'current subscriptions';

    $scope.orders = resolvedOrders.map(function(o){
        var tags = angular.copy(o.order_tags);
        var order = tags.shift();
        order.tags_history = tags;  // For now, just use most recent tag.
        return order;
    });

}])

.controller('UserAcqListsHoldingsCtrl', ["$scope", "$uibModalInstance", "entries", "needsCheck", "acqListsModel", "acqListUtilAPI", "alertService", "updateRows", function($scope, $uibModalInstance, entries, needsCheck, acqListsModel, acqListUtilAPI, alertService, updateRows) {
    $scope.entries = entries;
    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.refresh = function(getAll) {
        //console.log("Refresh " + getAll);
        var params = {
            async: false
        };
        params.ids = [];
        angular.forEach(entries, function(e) {
            if (getAll || !e.$holdings)
                params.ids.push(e.id);
        });

        $scope.loading = true;
        acqListsModel.checkHoldingsFor({id: entries[0].item_list_id}, params).$promise.then(function(recs) {
            var newHoldings = {};
            angular.forEach(recs, function(rec) {
                if (rec._embed.holdings_json)
                    newHoldings[rec.id] = acqListUtilAPI.transformHoldingsEntry(rec._embed.holdings_json);
            });
            angular.forEach($scope.entries, function(e) {
                e.checking = false;
                e.$holdings = newHoldings[e.id];
            });
            $scope.loading = false;
            updateRows(recs);
        }, function(err) {
            alertService.addApiError(err,'Holdings check failed');
            $scope.loading = false;
        });
    };

    if (needsCheck) {
        //console.log("Needs refreshing");
        $scope.refresh(false);
    }
}])

.controller('UserAcqListsUpdateSubscriptionsCtrl', ["$scope", "$uibModalInstance", "entries", "updateSubscriptions", function($scope, $uibModalInstance, entries, updateSubscriptions) {
    var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    $scope.months = [];
    for (var i=0; i<months.length; i++) {
        $scope.months.push({value: "" + (i+1), display: months[i]});
    }

    $scope.startYears = [];
    $scope.endYears = [];
    for(i = new Date().getFullYear(); i < new Date().getFullYear() + 2; i++){
        $scope.startYears.push("" + i);
    }
    for(i = new Date().getFullYear(); i < new Date().getFullYear() + 3; i++){
        $scope.endYears.push("" + i);
    }

    $scope.entries = entries;
    $scope.sel = {num:0};
    $scope.errors = [];

    $scope.$watch('entries', function(newVal, oldVal) {
        $scope.errors.length = 0;
        var bad_dates = 0;
        var missing = 0;
        for (var i=0; i<newVal.length && i<1; i++) {
            var v = newVal[i];
            if (v.sub_start_year > v.sub_end_year) {
                bad_dates++;
            }
            if (!(v.sub_start_year && v.sub_end_year && v.sub_start_month && v.sub_renewal_month)) {
                missing++;
            }
        }
        if (missing) $scope.errors.push("All dates must be filled in");
        if (bad_dates) $scope.errors.push("Subscription end year must be after subscription start year");
    }, true);

    $scope.cancel = function() {
        $uibModalInstance.close();
    };

    $scope.close = function() {
        updateSubscriptions($scope.entries[0]);
        $uibModalInstance.close();
    };
    

    //$scope.formdata = {startMonth :entry.sub_start_month  , startYear :entry.sub_start_year , renewalMonth :  entry.sub_renewal_month  , endYear :  entry.sub_end_year};
    $scope.formdata = {};
    
    $scope.calendar = {dateFormat: 'MM/dd/yyyy', opened : false};
    $scope.title = "Update Subscription Information"
    
/*    setTimeout(function () {
        $scope.$apply(function () {
            
            $scope.setVals(entries[0],true);
        });
    }, 500);*/
   
}])
.controller('UserAcqListsMergeCtrl', ["$scope", "$uibModalInstance", "entries", "alertService", "mergeLists", function($scope, $uibModalInstance, entries, alertService, mergeLists) {
    $scope.entries = entries;
 
    
    $scope.close = function(obj) {
        if(obj){
            
            if(obj.radioModel == "New" &&(obj.name == null || obj.name == "")){
                alertService.add({msg: "New List Must Have a Name", type: 'error'});
                return;
            }
            
            var result = true;
            if(obj.purge){
                result = window.confirm("Are You sure you want to permanently delete all the lists that do not have entries?");
            }
            if(result)
                mergeLists(obj);
            else
                return;
        }
        $uibModalInstance.close();
    };
    
    $scope.formdata =        {
                newName: '',
                default_id: entries[0].id, 
                purge : false,
                radioModel : 'Existing',
 
            }
  
    
 
   
}])
.controller('UserAcqListsRenewCtrl', ["$scope", "$uibModalInstance", "datum", "alertService", "renewLists", function($scope, $uibModalInstance, datum, alertService, renewLists) {
    $scope.entries = [];
    $scope.months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var listHash = {};
    var index = 1;
    for(var i = 0; i < datum.listitems.length; i++){
        var listItem = datum.listitems[i];
        var entryListItem = {item : true, "class" : "active", renewable : (listItem.type == "SUB SUB" || listItem.type == "UPAK UPR") , name : listItem.name, id: listItem.id, type : listItem.type, branch_code : listItem.branch_code, index : index};
        $scope.entries.push(entryListItem);
        var hashVals = datum.hash[listItem.id];
        if(listItem.type != "SUB SUB" && listItem.type != "UPAK UPR"){
            hashVals = []; 
             $scope.entries.push({item : false, "class" :  "danger" , renewed : false, calculation : "Only Subscription Item Lists can Be renewed", title : "Not A Subscription Item List", name : "", id: 0})
        }
        var hasRenew = false;
        for(var x = 0; x < hashVals.length; x++){
            var entry = hashVals[x];
            var calc = "";
            var renewed = false;
            var start_year = entry.sub_start_year;
            var start_month = entry.sub_start_month;
            var end_year = entry.sub_end_year;
            var renewal_month = entry.sub_renewal_month;
            
            if(start_year && start_month && end_year && renewal_month){
                if((start_year + 1) < end_year){
                    var month = $scope.months[renewal_month];
                    calc = "New renewal date will be " + month + "/" + ((start_year * 1) +  1);
                    renewed = true;
                    hasRenew = true;
                    entryListItem.renew = true;
                }else{
                    calc = "Renewal would put it passed the ending year"
                }
            }else{
                calc = "Can't Renew - Missing subscription field(s)"
            }
            $scope.entries.push({item : false, "class" :   renewed ? "success" :   "danger" , renewed : renewed, calculation : calc, title : entry.title, name : entry.name, id: entry.id})
        }
        
        entryListItem.renewable = hasRenew;
        index++;
    }
 
    
    $scope.close = function(obj) {
        if(obj){
        
            var oneRenewable = false;
            var oneBadName = false;
            for(var i = 0; i < obj.entries.length; i++){
                if(obj.entries[i].item && (obj.entries[i].renewable))
                    oneRenewable = true;
                if(obj.entries[i].item && (obj.entries[i].name == null || obj.entries[i].name == "")){
                    oneBadName = true;
                }
            }
            if(!oneRenewable){
                alertService.add({msg: "Merge failed: No Renewable Item Lists Selected", type : "error"});
                $uibModalInstance.close();
                return;                    
            }
            else{
                if(oneBadName){
                    alertService.add({msg: "Merge failed: Name cannot be empty", type : "error"});
                    return;
                }
                renewLists(obj);
            
            }
        }
        $uibModalInstance.close();
    };
    
    $scope.formdata =        {
        notify : false, 
        entries : $scope.entries
    }
  
    
 
   
}])





/*
    $scope.canPurge = function() {
        return $scope.userdetails &&
                $scope.userdetails.permit.staffaccess &&
                $scope.userdetails.permit.staffaccess.all;
    };

    $scope.pbStates = [
        {icon: '',           display: '', value: 0},
        {icon: 'icon-minus', display: '', value: 1},
        {icon: 'icon-plus',  display: '', value: 2},
    ];


    $scope.actions = angular.extend({}, $scope.actions, {
        status: function(model, sels) {
            form.open({
                title:          'List Disposition',
                templateUrl:    '/app/static/partials/ebs/status.html',
                formdata:       {
                    requestLists: sels,
                    listStatus: sels[0].status
                }
            }).then(function(formdata) {
                angular.forEach(formdata.requestLists, function(sel) {
                    loading.add();
                    sel.status = formdata.listStatus;
                    sel.setStatus(undefined, {
                        comments: formdata.comments,
                    }).then(function(resp) {
                        loading.resolve();
                        alertService.add({msg: resp.content});
                    }, function() {
                        loading.resolve();
                    });
                });
            });
        },


        export: function(how) {
            return function(model, sels) {
                var ids = [];
                angular.forEach(sels, function(sel) {
                    ids.push(sel.id);
                });

                form.open({
                    title:          'Export (' + how + ')',
                    templateUrl:    '/app/static/partials/ebs/export.html',
                    header:         '',
                    footer:         '',
                    formdata: {
                        download:   (how == 'download' ? true : false),
                        format:     'xlsx',
                        id:         ids[0],
                        ids:        ids.join(','),
                        email:      $scope.userdetails.email,
                    }
                }).then(function(formdata) {
                    // note - promise can only be resolved if we're emailing
                    return sels[0].export({
                        ids:    ids.join(','),
                        email:  formdata.email,
                        format: formdata.format,
                        view:   'download'
                    });
                }).then(function(resp) {
                    alertService.add({msg: "Email sent"});
                });
            };
        },

        commitment: function(model, sels) {
            var sel = sels[0];
            sel.calculateDiscounts().then(function(resp) {
                return form.open({
                    title:          'Check Out and Commit',
                    templateUrl:    '/app/static/partials/ebs/commitment.html',
                    header:         '',
                    footer:         '',
                    formdata:       { 
                        vendors:    resp,
                        email:      configService.CommitmentFormAddress,
                        cc:         $scope.userdetails.email
                    }
                });
            }).then(function(formdata) {
                loading.add();
                return sel.sendCommitmentForm(undefined,{
                    email:          formdata.email,
                    cc:             formdata.cc,
                    format:         'xlsx',
                    view:           'download',
                    check_holdings: formdata.checkHoldings,
                    drop_held:      formdata.dropHeld,
                    ids:            sel.id
                });
            }).then(function(resp) {
                loading.resolve();
                alertService.add({msg: "Commitment Form request processed"});
            }, function() {
                loading.resolve();
            });
        },

        discounts: function(model, sels) {
            loading.add();
            sels[0].calculateDiscounts().then(function(resp) {
                loading.resolve();
                form.open({
                    title:          'Discounts',
                    templateUrl:    '/app/static/partials/ebs/list-discounts.html',
                    header:         '',
                    footer:         '',
                    formdata:       {vendors: resp}
                });
            }, function() {
                loading.resolve();
            });
        },


        },
    });*/


// Service to encapsulate list functions injected into multiple controllers
.factory('acqListControlsAPI', ["acqListsModel", "kwApi", "$q", "modalForm", "loading", "configService", "alertService", "userService", "messageBox", function(acqListsModel, kwApi, $q, modalForm, loading, configService, alertService, userService, messageBox) {
    var api = {};

    // View API functions return a promise which is resolved if reload is required
    // rejected otherwise (unless otherwise specified)
    
    api.createList = function() {
        var deferred = $q.defer();
        modalForm.open({
            title:          'New ' + configService.AcqList.wording.title,
            templateUrl:    '/app/static/partials/user/acqlists/update.html',
            formdata:       {branch_code: userService.login_branch, type: 'OTAC PnM'},
            header:         '',
            footer:         '',
        }).then(function(data) {
            loading.add();
            acqListsModel.save(data).$promise.then(function(e) {
                loading.resolve();
                //alertService.add({msg: 'List saved'});
                deferred.resolve(e);
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Save failed');
                deferred.reject();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    api.editList = function(list) {
        var deferred = $q.defer();
        modalForm.open({
            title:          'Edit ' + configService.AcqList.wording.title,
            templateUrl:    '/app/static/partials/user/acqlists/update.html',
            formdata:       list,
            header:         '',
            footer:         '',
        }).then(function(list) {
            loading.add();
            acqListsModel.update(list).$promise.then(function() {
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                alertService.addApiError(err,'Save failed');
                loading.resolve();
                deferred.resolve();
            });
        }, function() {
            deferred.resolve();
        });
        return deferred.promise;
    };

    api.copyList = function(list) {
        var deferred = $q.defer();
        modalForm.open({
            title:          'Copy ' + configService.AcqList.wording.title,
            templateUrl:    '/app/static/partials/user/acqlists/copy.html',
            formdata:       {
                //name: 'Copy of ' + list.name,
                name: '',
                branch_code: userService.login_branch,
                type:  list.type,
            }
        }).then(function(formdata) {
            loading.add();
            formdata.id = list.id;
            acqListsModel.clone(formdata,{}).$promise.then(function() {
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Save failed');
                deferred.reject();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    api.deleteLists = function(sels) {
        var deferred = $q.defer();
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to delete " + (sels.length>1 ? 'these lists' : 'this list') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            acqListsModel.deleteAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Delete  failed');
                deferred.resolve();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    api.purgeLists = function(sels) {
        var deferred = $q.defer();
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to purge " + (sels.length>1 ? 'these lists' : 'this list') + '? This CANNOT be undone'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            acqListsModel.purgeAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Purge failed');
                deferred.resolve();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };
    
    
    api.remindLists = function(sels) {
        var deferred = $q.defer();
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to send reminds " + (sels.length>1 ? 'the owners of these lists' : 'the owner of this list') + '?  '
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            acqListsModel.remindAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Reminder failed');
                deferred.resolve();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    api.setStatus = function(sels) {
        var deferred = $q.defer();
        var oldStatus = undefined;
        angular.forEach(sels, function(sel) {
            if (typeof(oldStatus) == 'undefined') {
                oldStatus = sel.status;
            }
            else if (oldStatus !== sels.status) {
                oldStatus = null;
            }
        });
        //console.log("Status = " + oldStatus);

        modalForm.open({
            title:          'Set List Status',
            templateUrl:    '/app/static/partials/user/acqlists/status.html',
            formdata:       {
                status: oldStatus
            }
        }).then(function(formdata) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            acqListsModel.setStatusAll({ids: ids, status: formdata.status, comments: formdata.comments}).$promise.then(function() {
                angular.forEach(sels, function(sel) { sel.status = formdata.status; });
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Status change failed');
                deferred.resolve();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };
    
    api.cancelLists = function(sels) {
        var deferred = $q.defer();
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to cancel " + (sels.length>1 ? 'these lists' : 'this list') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            acqListsModel.setStatusAll({ids: ids, status: 'cancelled'}).$promise.then(function() {
                loading.resolve();
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Cancellation failed');
                deferred.resolve();
            });
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
        
    };



    api.shareList = function(rlist) {
        var deferred = $q.defer();
        loading.add();
        $q.all([
            kwApi.Patron.getBranchList({permit:'editcatalogue.item_list.update'}).$promise,
            acqListsModel.accessors({id: rlist.id})
        ]).then(function(rv) {
            var candidates = rv[0];
            var selectors = rv[1];
            loading.resolve();

            var has = {};
            if (selectors) {
                angular.forEach(selectors, function(s) {
                    has[s.member_id] = s;
                });
            }

            angular.forEach(candidates, function(candidate) {
                if (!has[candidate.id]) {
                    candidate.enabled = 0;
                }
                else if (has[candidate.id].can_update == 0) {
                    //console.log("Can update = 0");
                    candidate.enabled = 1;
                    candidate.accessor_id = has[candidate.id].id;
                }
                else {
                    //console.log("Can update = 1");
                    candidate.enabled = 2;
                    candidate.accessor_id = has[candidate.id].id;
                }
                candidate.previous = candidate.enabled;
            });

            return modalForm.open({
                title:          'Share ' + configService.AcqList.wording.title,
                templateUrl:    '/app/static/partials/user/acqlists/sharing.html',
                formdata:       {selectors: candidates, requestList: rlist},
                header:         '',
                footer:         '',
            });
        }).then(function(formdata) {
            var shared = {};
            angular.forEach(formdata.selectors, function(s) {
                if (s.enabled == 2) {
                    shared[s.id] = 1;
                }
                else if (s.enabled == 1) {
                    shared[s.id] = 0;
                }
            });
            loading.add();
            acqListsModel.shareWith({id: rlist.id}, {list_accessors: shared}).$promise.then(function() {
                loading.resolve();
                //alertService.add({msg: "List sharing updated"});
                deferred.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Update failed');
                deferred.resolve();
            });
        }, function() {
            deferred.reject();
        });

        return deferred.promise;
    };

    api.getListHoldings = function(list) {
        modalForm.open({
            title:          'Holdings Check',
            templateUrl:    '/app/static/partials/user/acqlists/holdings.html',
            header:         '',
            footer:         '',
            formdata:       {}
        }).then(function(formdata) {
            acqListsModel.checkHoldings({id: list.id}, formdata).$promise.then(function() {
                alertService.add({msg: "Holdings check started"});
            }, function(err) {
                alertService.addApiError(err,'Holdings check failed');
            });
        });
    };

    api.getListDiscounts = function(list) {
        loading.add();
        acqListsModel.discounts({id: list.id}).$promise.then(function(rv) {
            //console.dir(rv);
            loading.resolve();
            modalForm.open({
                title:          'Volume Discounts',
                templateUrl:    '/app/static/partials/user/acqlists/discounts.html',
                header:         '',
                footer:         '',
                formdata:       rv,
                size:           'lg'
            });
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Discount calculation failed');
        });
    };

    api.checkout = function(list) {
        loading.add();
        return acqListsModel.discounts({id: list.id}).$promise.then(function(rv) {
            //console.dir(rv);
            loading.resolve();
           
            var workform = {}
            var formfields = [];
            var json = configService.AcqListsCommitmentFormFields;
            if (json) {                           
                angular.forEach(json, function(value) {
                    formfields.push(value);
                });
                workform.formfields = formfields;
            }
                      
            return modalForm.open({
                title:          'Checkout',
                templateUrl:    '/app/static/partials/user/acqlists/checkout.html',
                header:         '',
                footer:         '',
                formdata:       {list: rv, work_form: workform},
                size:           'lg'
            }).then(function(formdata) {
                formdata.work_form = angular.toJson(formdata.work_form);                
                delete formdata.list;
                return acqListsModel.commit({id: list.id}, formdata).$promise.then(function(rv) {

// RELOAD LIST SUMMARY .

                    loading.resolve();
                    alertService.add({msg: "List committed"});
                    return true;
                }, function(err) {
                    loading.resolve();
                    alertService.addApiError(err,'Commit failed');
                });
            });
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Discount calculation failed');
        });
    };

    api.export = function(sels, how) {
        var list = sels[0];

        modalForm.open({
            title:          'Export (' + how + ')',
            templateUrl:    '/app/static/partials/user/acqlists/export.html',
            header:         '',
            footer:         '',
            formdata: {
                id: list.id,
                download:   (how == 'download' ? true : false),
                format:     'xlsx',
                email:      userService.email,
            }
        }).then(function(formdata) { // Promise is only resolved if we're emailing
            loading.add();
            acqListsModel.export({id: list.id},formdata).$promise.then(function() {
                loading.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Export failed');
            });
        });
    };

    return api;
}])

.factory('acqListSearchAPI', ["acqListsModel", "userService", "bibService", "loading", "$q", "modalForm", "alertService", "kohaSearchSvc", "$http", "kwApi", "acqListsCartSvc", function(acqListsModel, userService, bibService, loading, $q, modalForm, alertService, kohaSearchSvc, $http, kwApi, acqListsCartSvc) {
    var api = {};

    api.getReturnUrl = function(scope) {
    if (!scope.search)
        return window.location.origin + '/';
    var terms = [];
    var return_url = window.location.origin + scope.search.asUrl() + '#kohabib-' + scope.bib.id;
    console.log(return_url);
    return return_url;
    };


    api.addItemsToList = function(itemids, type, scope) {
        if (itemids.length !== 1) return;
    loading.add();
    var return_url = api.getReturnUrl(scope);
        acqListsModel.addToPortalCart({id: itemids[0], return_url: return_url}).$promise.then(function(rv) {
        loading.resolve();
            if (rv.redirect) {
                window.location.href = rv.redirect;
            }
        else if (rv.error) {
                alertService.add({msg: rv.error, type: 'error'});
        }
            else {
                alertService.add({msg: "Product added to cart"});
        acqListsCartSvc.updateCartCounts();
            }
        }, function(err) {
        loading.resolve();
        var s = (typeof(err) === 'object' ? err.statusText : err);
            alertService.add({msg: "Could not add to cart: " + s, type: 'error'});
    });
    };

    api.addItemsToListOld = function(itemids, bibids, types) {
        var typeList = Object.keys(types);
        var formdata = {
            itemids: itemids,
            bibs: [],
            id: (userService.prefs.currentAcqListId || ''),
            limitedTo: false,
            totalHits: false,
            types: typeList,
            type: typeList[0],
            typeMap: {
                'OTAC PnM': 'One Time - Pick and Mix',
                'OTAC DDA': 'One Time - Demand Driven Acq',
                'SUB SUB': 'Subscription',
                'UPAK UPOT': 'User Package - One Time',
                'UPAK UPR': 'User Package - Recurring',
            },
        };
        
        angular.forEach(bibids, function(bibid) {
            loading.add();
            bibService.get(bibid).then(function(bib) {
                loading.resolve();
                formdata.bibs.push(bib);
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Could not retrieve title information');
            });
        });

        loading.add();
        acqListsModel.forUser({user: userService.id, open: 1}).$promise.then(function(rv) {
            formdata.lists = [];
            var listFound = false;
            angular.forEach(rv, function(lst) {
                if (types && types[lst.type]) {
                    formdata.lists.push(lst);
                    if (lst.id === formdata.id)
                        listFound = true;
                }
            });
            if (!listFound)
                formdata.id = '';

            formdata.acqlists_simple_view_pref = userService.prefs.acqlists_simple_view;

            if (userService.prefs.acqlists_simple_view) {
                var list_id, openList;

                while ( openList = rv.shift() ) { // eslint-disable-line no-cond-assign
                    if ( openList.status === 'open' ) {
                        break;
                    }
                    else {
                        openList = undefined;
                    }
                }

                if ( !openList ) {
                    formdata.name = undefined;
                    var newTypes = {'OTAC PnM':1, 'OTAC DDA':1, 'SUB SUB':1, 'UPAK UPOT':1, 'UPAK UPR':1};
                    formdata.types = Object.keys(newTypes);
                    formdata.type = formdata.types[0];
                }
                else {
                    formdata.id = openList.id;
                    formdata.name = openList.name;
                }
            }

            loading.resolve();
            return modalForm.open({
                templateUrl:    '/app/static/partials/user/acqlists/add-selected.html',
                header:         '',
                footer:         '',
                formdata:       formdata,
            });
        }).then(function(formdata) {
            var deferred = $q.defer();

            if (formdata.id) {
                deferred.resolve(1);
            }
            else {
                loading.add();
                acqListsModel.save({name: formdata.name, type: formdata.type}).$promise.then(function(rv) {
                    formdata.id = rv.id;
                    loading.resolve();
                    deferred.resolve();
                }, function(err) {
                    loading.resolve();
                    deferred.reject(err);
                })
            }

            deferred.promise.then(function() {
                userService.addPrefs({currentAcqListId: formdata.id});
                loading.add();
                return acqListsModel.append({id: formdata.id}, {items: itemids}).$promise;
            }).then(function(rv) {
                var stats = rv.content;
                var s = 'Added ' + stats.added + ' items to <a href="/app/me/acqlists/' + formdata.id + '">list (click to view)</a>';
                if (stats.duplicate && stats.bad_type)
                    s = s + ' (skipped ' + stats.duplicate + ' duplicates and ' + stats.bad_type + ' items which did not match list type)';
                else if (stats.duplicate)
                    s = s + ' (skipped ' + stats.duplicate + ' duplicates)';
                else if (stats.bad_type) 
                    s = s + ' (skipped ' + stats.bad_type + ' items which did not match list type)';

                loading.resolve();
                alertService.add({msg: s});
            }, function(err) {
                alertService.addApiError(err,'Could not add to list');
                loading.resolve();
            });
        });
    };


    api.addBibsToList = function(bibids, types) {
        var curSearch = kohaSearchSvc.currentSearch();
        var qp = (curSearch) ? curSearch.solrParams() : {};

        if (qp.params && qp.params.fq) {
            var program;
            var order_type;
            angular.forEach(qp.params.fq, function(facet) {
                console.dir(facet);
                var args = facet.match(/program:\(\"(.+)\"\)/);
                if (args && args.length > 1) {
                    program = args[1];
                }
                args = facet.match(/order_type:\(\"(.+)\"\)/);
                if (args && args.length > 1) {
                    order_type = args[1];
                }
            });
            angular.forEach(types, function(val, key) {
                var ts = key.split(' ');
                console.dir(ts);
                if (order_type && (order_type != ts[0])) {
                    delete types[key];
                }
                else if (program && (program != ts[1])) {
                    delete types[key];
                }
            });
        }

        var typeList = Object.keys(types);
        var formdata = {
            bibids: bibids,
            bibs: [],
            queryParams: qp.params,
            id: userService.prefs.currentAcqListId,
            limitedTo: false,
            totalHits: false,
            types: typeList,
            type: typeList[0],
            typeMap: {
                'OTAC PnM': 'One Time - Pick and Mix',
                'OTAC DDA': 'One Time - Demand Driven Acq',
                'SUB SUB': 'Subscription',
                'UPAK UPOT': 'User Package - One Time',
                'UPAK UPR': 'User Package - Recurring',
            },
        };
        
        angular.forEach(bibids, function(bibid) {
            loading.add();
            bibService.get(bibid).then(function(bib) {
                loading.resolve();
                formdata.bibs.push(bib);
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Could not retrieve title information');
            });
        });

        loading.add();
        acqListsModel.forUser({user: userService.id, open: 1}).$promise.then(function(rv) {
            formdata.lists = [];
            var listFound = false;
            angular.forEach(rv, function(lst) {
                if (types && types[lst.type]) {
                    formdata.lists.push(lst);
                    if (lst.id === formdata.id)
                        listFound = true;
                }
            });
            if (!listFound)
                formdata.id = '';

            formdata.acqlists_simple_view_pref = userService.prefs.acqlists_simple_view;

            if (userService.prefs.acqlists_simple_view) {
                var list_id, openList;

                while ( openList = rv.shift() ) { // eslint-disable-line no-cond-assign
                    if ( openList.status === 'open' ) {
                        break;
                    }
                    else {
                        openList = undefined;
                    }
                }

                if ( !openList ) {
                    formdata.name = undefined;
                    var newTypes = {'OTAC PnM':1, 'OTAC DDA':1, 'SUB SUB':1, 'UPAK UPOT':1, 'UPAK UPR':1};
                    formdata.types = Object.keys(newTypes);
                    formdata.type = formdata.types[0];
                }
                else {
                    formdata.id = openList.id;
                    formdata.name = openList.name;
                }
            }

            loading.resolve();
            return modalForm.open({
                templateUrl:    '/app/static/partials/user/acqlists/add-selected.html',
                header:         '',
                footer:         '',
                formdata:       formdata,
            });
        }).then(function(formdata) {
            var deferred = $q.defer();

            if (formdata.id) {
                deferred.resolve(1);
            }
            else {
                loading.add();
                acqListsModel.save({name: formdata.name, type: formdata.type}).$promise.then(function(rv) {
                    formdata.id = rv.id;
                    loading.resolve();
                    deferred.resolve();
                }, function(err) {
                    loading.resolve();
                    deferred.reject(err);
                })
            }

            deferred.promise.then(function() {
                userService.addPrefs({currentAcqListId: formdata.id});
                loading.add();
                return acqListsModel.append({id: formdata.id}, {bibs: bibids, params: JSON.stringify(formdata.queryParams)}).$promise;
            }).then(function(rv) {
                var stats = rv.content;
                var s = 'Added ' + stats.added + ' items to <a href="/app/me/acqlists/' + formdata.id + '">list (click to view)</a>';
                if (stats.duplicate && stats.bad_type)
                    s = s + ' (skipped ' + stats.duplicate + ' duplicates and ' + stats.bad_type + ' items which did not match list type)';
                else if (stats.duplicate)
                    s = s + ' (skipped ' + stats.duplicate + ' duplicates)';
                else if (stats.bad_type) 
                    s = s + ' (skipped ' + stats.bad_type + ' items which did not match list type)';

                loading.resolve();
                alertService.add({msg: s});
            }, function(err) {
                alertService.addApiError(err,'Could not add to list');
                loading.resolve();
            });
        });
    };

    api.exportSearch = function(formdata) {
        modalForm.open({
            templateUrl:    '/app/static/partials/user/acqlists/export-search.html',
            header:         '',
            footer:         '',
            formdata:       formdata,
        }).then(function(formdata) {
            loading.add();
            kwApi.Catalog.exportSearch(formdata).$promise.then(function(rv) {
                loading.resolve();
                if (rv.response_code == '302') {
                    alertService.add({msg: "This search has already been exported"});
                }
                else {
                    alertService.add({msg: "Search exported"});
                }
            }, function(err) {
                alertService.addApiError(err,'Could not add to list');
                loading.resolve();
            });
        });
    };


    api.addQueryHitsToList = function(query) {
        //console.log(query.q);
        //console.dir(kohaSearchSvc.query.solrParams()); //.queryGetParams());

        var types = {'OTAC PnM':1, 'OTAC DDA':1, 'SUB SUB':1, 'UPAK UPOT':1, 'UPAK UPR':1};
        // Filter against current query params
        var curSearch = kohaSearchSvc.currentSearch();
        var qp = (curSearch) ? curSearch.solrParams() : {};
        if (qp.params && qp.params.fq) {
            var program;
            var order_type;
            angular.forEach(qp.params.fq, function(facet) {
                var args = facet.match(/program:\(\"(.+)\"\)/);
                if (args && args.length > 1) {
                    program = args[1];
                }
                args = facet.match(/order_type:\(\"(.+)\"\)/);
                if (args && args.length > 1) {
                    order_type = args[1];
                }
            });
            angular.forEach(types, function(val, key) {
                var ts = key.split(' ');
                if (order_type && (order_type != ts[0])) {
                    delete types[key];
                }
                else if (program && (program != ts[1])) {
                    delete types[key];
                }
            });
        }

        var formdata = {
            query: query.q,
            queryParams: qp.params,
            bibs: [],
            types: [],
            type: '',
            typeMap: {
                'OTAC PnM': 'One Time - Pick and Mix',
                'OTAC DDA': 'One Time - Demand Driven Acq',
                'SUB SUB': 'Subscription',
                'UPAK UPOT': 'User Package - One Time',
                'UPAK UPR': 'User Package - Recurring',
            },
            id: (userService.prefs.currentAcqListId || ''),
        };
        

        loading.add('aqhtl-1');
        var searchDeferred = $q.defer();
        $http.get("/api/opac/"+encodeURIComponent(query.q), {params: qp.params}).then(function(rsp) {
            searchDeferred.resolve(rsp.data);
        }, function(err) {
            searchDeferred.reject(err);
        });

        searchDeferred.promise.then(function(data) {
            var program_values, order_type_values;
            angular.forEach(data.facets, function(facet) {
                if (facet.field == 'program') {
                    program_values = {};
                    angular.forEach(facet.values, function(v) {
                        var args = v.value.match(/\"(.+)\"/);
                        if (args && args[1]) {
                            program_values[args[1]] = 1;
                        }
                        else {
                            program_values[v.value] = 1;
                        }
                    });
                }
                if (facet.field == 'order_type') {
                    order_type_values = {};
                    angular.forEach(facet.values, function(v) {
                        var args = v.value.match(/\"(.+)\"/);
                        if (args && args[1]) {
                            order_type_values[args[1]] = 1;
                        }
                        else {
                            order_type_values[v.value] = 1;
                        }
                    });
                }
            });

            angular.forEach(types, function(val, key) {
                var ts = key.split(' ');
                if (order_type_values && !order_type_values[ts[0]]) {
                    delete types[key];
                }
                if (program_values && !program_values[ts[1]]) {
                    delete types[key];
                }
            });

            formdata.types = Object.keys(types);
            formdata.type = formdata.types[0];

            loading.resolve('aqhtl-1');
            formdata.totalHits = data.total_hits;
            formdata.limitedTo = data.hits.length;
            angular.forEach(data.hits, function(hit) {
                // FIXME - this is cheesy af
                var id = hit.work.replace(/^.+\//,'');
                //console.dir(id);
                if (id) {
                    loading.add();
                    bibService.get(id).then(function(bib) {
                        loading.resolve();
                        formdata.bibs.push(bib);
                    }, function(err) {
                        loading.resolve();
                        alertService.addApiError(err,'Could not retrieve title information');
                    });
                }
            });

            loading.add('aqhtl-2');
            return acqListsModel.forUser({user: userService.id, open: 1}).$promise;
        }, function(err) {
            loading.resolve('aqhtl-1');
            alertService.addApiError(err,'Could not retrieve title information');
            return $q.reject();
        }).then(function(rv) {
            //console.dir(rv);
            loading.resolve('aqhtl-2');

            formdata.lists = [];
            var listFound = false;
            angular.forEach(rv, function(lst) {
                if (types && types[lst.type]) {
                    formdata.lists.push(lst);
                    if (lst.id === formdata.id)
                        listFound = true;
                }
            });
            if (!listFound)
                formdata.id = '';

            formdata.acqlists_simple_view_pref = userService.prefs.acqlists_simple_view;

            if (userService.prefs.acqlists_simple_view) {
                var list_id, openList;

                while ( openList = rv.shift() ) { // eslint-disable-line no-cond-assign
                    if ( openList.status === 'open' ) {
                        break;
                    }
                    else {
                        openList = undefined;
                    }
                }

                if ( !openList ) {
                    formdata.name = undefined;
                    var newTypes = {'OTAC PnM':1, 'OTAC DDA':1, 'SUB SUB':1, 'UPAK UPOT':1, 'UPAK UPR':1};
                    formdata.types = Object.keys(newTypes);
                    formdata.type = formdata.types[0];
                }
                else {
                    formdata.id = openList.id;
                    formdata.name = openList.name;
                }
            }

            return modalForm.open({
                templateUrl:    '/app/static/partials/user/acqlists/add-selected.html',
                header:         '',
                footer:         '',
                formdata:       formdata,
            });
        }, function(err) {
            loading.resolve('aqhtl-2');
            alertService.addApiError(err,'Could not retrieve lists');
            return $q.reject();
        }).then(function(formdata) {
            var deferred = $q.defer();

            if (formdata.id) {
                deferred.resolve(1);
            }
            else {
                loading.add();
                acqListsModel.save({name: formdata.name, type: formdata.type}).$promise.then(function(rv) {
                    formdata.id = rv.id;
                    loading.resolve();
                    deferred.resolve();
                }, function(err) {
                    loading.resolve();
                    deferred.reject(err);
                })
            }

            deferred.promise.then(function() {
                userService.addPrefs({currentAcqListId: formdata.id});
                loading.add();
                return acqListsModel.appendSearchResults({id: formdata.id}, {query: formdata.query, params: JSON.stringify(formdata.queryParams)}).$promise;
            }).then(function(rv) {
                var stats = rv.content;
                var s = 'Added ' + stats.added + ' items to <a href="/app/me/acqlists/' + formdata.id + '">list (click to view)</a>';
                if (stats.duplicate && stats.bad_type)
                    s = s + ' (skipped ' + stats.duplicate + ' duplicates and ' + stats.bad_type + ' items which did not match list type)';
                else if (stats.duplicate)
                    s = s + ' (skipped ' + stats.duplicate + ' duplicates)';
                else if (stats.bad_type) 
                    s = s + ' (skipped ' + stats.bad_type + ' items which did not match list type)';

                loading.resolve();
                alertService.add({msg: s});
            }, function(err) {
                alertService.addApiError(err,'Could not add to list');
                loading.resolve();
            });
        });
    };

    return api;
}])

.factory('acqListUtilAPI', ["acqListsModel", function(acqListsModel) {
    var api = {};

    api.routesReady = acqListsModel.getPortalRoutes().$promise;
    api.routesReady.then(function(routes) {
    api.routes = routes;
    });

    api.transformHoldingsEntry = function(sel) {
        var holdings = {};
        var parsed;
        try {
            parsed = JSON.parse(sel);
            if (parsed.error)
                holdings.globalError = parsed.error;
        }
        catch (e) {
            //console.log(e);
            holdings.globalError = "Corrupted holdings data: ";
        }
        if (holdings.globalError)
            return(holdings);

        var hits = [];
        var errors = [];
        var noneAt = [];
        var totalTargets = 0;
        
        angular.forEach(parsed, function(details, targetName) {
            totalTargets++;
            if (typeof(details) == 'object') {
                if (details.length > 0) {
                    angular.forEach(details, function(rec) {
                        hits.push(angular.extend({target: targetName}, rec));
                    });
                }
                else {
                    noneAt.push(targetName);
                }
            }
            else {
                errors.push({target: targetName, error: details});
            }
        });
        holdings.hits = (hits.length ? hits : false);
        holdings.errors = (errors.length ? errors : false);
        holdings.noneAt = (noneAt.length ? noneAt.join(', ') : false);
        return holdings;
    };
    return api;
}])

.filter('holdingsFilter', function() {
    return function(json) {
        if (!json)
            return json;
        var holdings = JSON.parse(json);
        if (holdings.error) {
            return 'ERR: ' + holdings.error;
        }
        var rv = [];
        angular.forEach(holdings, function(hits, target) {
            if (typeof(hits) == 'object') {
                if (hits.length > 0) {
                    rv.push("" + hits.length + " found (" + target + ")");
                }
            }
            else {
                rv.push("query failed (" + target + ")");
            }
        });
        return rv.join('; ');
    };
})

.factory('acqListsCartSvc', ["acqListsModel", "userService", "acqListUtilAPI", "configService", function(acqListsModel, userService, acqListUtilAPI, configService) {
    var svc = {
    count: 0
    };

    svc.goToCart = function() {
    acqListUtilAPI.routesReady.then(function(p) {
        var redirectUrl = acqListUtilAPI.routes.cart_url + '?return_url=' + encodeURIComponent(window.location.href);
        window.location.href = redirectUrl;

    });
    };

    svc.updateCartCounts = function() {
        if (!configService.AcqLists) return;
    acqListsModel.getPortalCart().$promise.then(function(rv) {
        console.dir(rv);
        svc.count = 0;
        if (rv && rv.orders && rv.orders.entries) {
        rv.orders.entries.forEach(function(e) {
            svc.count += e.count_products;
        });
        }
    });
    svc.count = 0;
    };

    svc.getCount = function() {
    return svc.count ? ('(' + svc.count + ')') : '';
    };

    userService.whenAnyUserDetails().then(function() {
    svc.updateCartCounts();
    });

    return svc;
}])

.controller('acqListsCartCtrl', ["$scope", "acqListsCartSvc", "configService", function($scope, acqListsCartSvc, configService) {
    if (configService.AcqLists) {
        $scope.goToPortalCart = acqListsCartSvc.goToCart;
        $scope.getPortalCartCount = acqListsCartSvc.getCount;
    }
    else {
        $scope.goToPortalCart = function() {  };
        $scope.getPortalCartCount = function() {  };
    }
}])

// ================================= Data models ================================================

// TODO - move to kwApi
.factory('acqListsModel', ["$resource", function($resource) {
    return $resource('/api/item-list/:id', {}, {
        details: {
            method: 'GET',
            url: '/api/item-list/:id?view=details&scope=shared',
        },
        discounts: {
            method: 'GET',
            url: '/api/item-list/:id?view=discounts',
        },
        entries: {
            method: 'GET',
            url: '/api/item-list/:id/entries?view=details&scope=:scope',
            isArray: true,
        },
        forUser: {
            method: 'GET',
            url: '/api/patron/:user/item-lists?view=details',
            isArray: true,
        },
        accessors: {
            method: 'GET',
            url: '/api/item-list/:id/accessors',
            isArray: true,
            //params: {id: '@id'}
        },
        branchOrders: {
            method: 'GET',
            url: '/api/branch/:branch_code/order_tags',
            isArray: true
        },
        update: {
            method: 'PUT',
            params: {id: '@id'}
        },

        append: {
            method: 'POST',
            url: '/api/item-list/:id?op=append'
        },
        appendSearchResults: {
            method: 'POST',
            url: '/api/item-list/:id?op=append-search'
        },
        clone: {
            method: 'POST',
            url: '/api/item-list/:id?op=clone&branch_code=:branch_code&name=:name',
            //params: {id: '@id'}
        },
        shareWith: {
            method: 'POST',
            url: '/api/item-list/:id?op=share',
            //params: {id: '@id'}
        },
        getAllEntries: {
            method: 'POST',
            url: '/api/item-list/?op=get_all_entries'
        },
        deleteAll: {
            method: 'POST',
            url: '/api/item-list/?op=delete_all'
        },
        deleteAllEntries: {
            method: 'POST',
            url: '/api/item-list/:id/entries?op=delete_all'
        },
        updateAllEntries: {
            method: 'POST',
            url: '/api/item-list/:id/entries?op=update_all'
        },
        purgeAll: {
            method: 'POST',
            url: '/api/item-list/?op=purge_all'
        },
        mergeAll: {
            method: 'POST',
            url: '/api/item-list/?op=merge_all'
        },
        renewAll: {
            method: 'POST',
            url: '/api/item-list/?op=renew_all'
        },
        remindAll: {
            method: 'POST',
            url: '/api/item-list/?op=remind_all'
        },
        setStatusAll: {
            method: 'POST',
            url: '/api/item-list/?op=set_status_all'
        },
        export: {
            method: 'POST',
            url: '/api/item-list/:id/?op=export',
        },
        checkHoldings: {
            method: 'POST',
            url: '/api/item-list/:id?op=holdings'
        },
        checkHoldingsFor: {
            method: 'POST',
            url: '/api/item-list/:id?op=holdings',
            isArray: true,
        },
        commit: {
            method: 'POST',
            url: '/api/item-list/:id?op=commit'
        },
        addToPortalCart: {
            method: 'POST',
            url: '/api/item-list/?op=add-to-portal-cart'
        },
        goToPortalCart: {
            method: 'POST',
            url: '/api/item-list/?op=go-to-portal-cart'
        },
        getPortalCart: {
            method: 'GET',
            url: '/api/item-list/?view=portal-cart'
        },
    getPortalRoutes: {
        method: 'GET',
            url: '/api/item-list/?view=portal-routes'
        },
    });
}])

/*
// TODO - This should be limited to only applicable selectors
// maybe add a permit=X function and make sure we also have X?
.factory('patronsModel', function($resource) {
    return $resource('/api/patron/:id', {}, {
        getBranchPatrons: {
            method: 'GET',
            url: '/api/patron/?view=picker&scope=branch&permit=:permit',
            isArray: true
        },
        getSuEligiblePatrons: {
            method: 'GET',
            url: '/api/patron/?view=picker-extended&subpermissions=1',
            isArray: true
        },
        generateSuTicket: {
            method: 'POST',
            url: '/api/patron/:id?op=generate-su-ticket',
        },
    });
})
*/

;

})();
